const rgbStartColor = [242, 247, 253]; // primary blue 5
const rgbEndColour = [0, 101, 211]; // primary blue 100

export const getSliceColorByValue = (value: number) => {
  if (!value) return "var(--colors-black-3)";

  const result = [...rgbStartColor];

  // https://jsfiddle.net/002v98LL/
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(
      result[i] + (value / 100) * (rgbEndColour[i] - rgbStartColor[i])
    );
  }

  return `rgb(${result})`;
};
