import React from "react";
import {
  SelectLegacy,
  DropdownButton,
  SelectLegacyDropdown,
  Box,
  SelectLegacyDropdownOptions,
  SelectableOptionT,
  SelectLegacyDropdownOption,
  Loader,
} from "@compono/ui";
import { useQuery } from "@apollo/client";
import { sortBy } from "lodash";
import { setAutoFreeze } from "immer";

import { GetCampaigns } from "./queries";
import { headerText, selectDropdown } from "./styles";
import { CampaignSelectProps } from "./types";
import { Status, Campaign } from "../../../types";
import { getOptionDecor } from "./helpers";
import { getCompletionRate } from "../../../shared/helpers";
/**
 * @fix is to stop Cannot assign to read only property 'current' of object '#<Object>'
 * @ref https://compono-team.slack.com/archives/C2R51H90U/p1610950711034500 */
setAutoFreeze(false);

const CampaignSelect = ({ onSelectCampaign }: CampaignSelectProps) => {
  // data
  const { data, loading } = useQuery(GetCampaigns, {
    variables: { status: Status.Closed },
    fetchPolicy: "network-only",
  });
  const campaigns = data?.campaigns ?? [];
  let _options = campaigns.map((campaign: Campaign) => ({
    ...campaign,
    label: campaign.name,
    value: campaign.sk,
    caption: `${campaign.purpose}, ${getCompletionRate(
      campaign
    )}% completed (${+(campaign.responses ?? "0")}/${+(
      campaign.respondents?.length ?? "0"
    )})`,
    dueDate: campaign.dueDate,
  }));
  _options = sortBy(_options, "dueDate");

  if (_options.length === 0) {
    _options = [{ label: "There are no closed campaigns.", value: null }];
  }

  // onchange to handle reloading options or selecting one.
  const handleSelectOnchange = (
    option: SelectableOptionT | SelectableOptionT[]
  ) => {
    if (Array.isArray(option)) return;
    const selectedCampaign = option;
    if (!selectedCampaign?.value) return;
    onSelectCampaign(selectedCampaign);
  };

  return (
    <>
      <Box>
        {loading ? (
          <Loader label="loading" hideLabel />
        ) : (
          <SelectLegacy
            placeholder="Choose an item"
            options={_options ?? []}
            onChange={handleSelectOnchange}
          >
            <DropdownButton data-testid="selectCampaignButton" look="tertiary">
              Select Campaign
            </DropdownButton>
            <SelectLegacyDropdown sx={selectDropdown}>
              <SelectLegacyDropdownOptions
                sx={{
                  maxHeight: "14rem",
                  overflow: "auto",
                }}
              >
                {(option: SelectableOptionT) => {
                  const { decor } = getOptionDecor(option);
                  return (
                    <SelectLegacyDropdownOption
                      data-testid={`campaign${option.label}`}
                      sx={{
                        "[data-icon]": { color: "black.60" },
                      }}
                      option={option}
                      decor={decor}
                    />
                  );
                }}
              </SelectLegacyDropdownOptions>
            </SelectLegacyDropdown>
          </SelectLegacy>
        )}
      </Box>
    </>
  );
};

export default CampaignSelect;
