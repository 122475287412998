import React, { useState } from "react";

type Context = {
  isOpen: (id: string) => boolean;
  toggleTooltip: (id: string | null) => void;
};

export const VisxTooltipContext = React.createContext<Context>({
  isOpen: (): boolean => false,
  toggleTooltip: (): void => undefined,
});

export const VisxTooltipProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [currentTooltip, setCurrentTooltip] = useState<string | null>(null);
  const isOpen = (id: string) => currentTooltip === id;
  const toggleTooltip = (id: string | null) => {
    if (currentTooltip === id) {
      setCurrentTooltip(null);
    } else {
      setCurrentTooltip(id);
    }
  };

  return (
    <VisxTooltipContext.Provider value={{ isOpen, toggleTooltip }}>
      {children}
    </VisxTooltipContext.Provider>
  );
};
