import React from "react";

interface OrganisationContextInterface {
  sk: string | null;
  name: string | null;
}

const OrganisationContext = React.createContext([
  {
    sk: null,
    name: null,
  } as OrganisationContextInterface,
  () => {
    // Initialises empty function
  },
]);
export default OrganisationContext;
