import {
  highlightedHeadingStyle,
  normalHeadingStyle,
} from "../../../../components/CultureInfoTip/styles";
import { getInteger, isVowel } from "../../../../shared/helpers";
import React from "react";
import { Text } from "@compono/ui";
import { primaryHeadingStyle } from "../../../../components/CultureReportInfoTip/styles";

export const getPrimaryHeadingDiffInPercentage = (
  compareFromResults: number,
  compareToResults: number
) => getInteger(compareFromResults) - getInteger(compareToResults);

export const getSecondaryHeadingDiffInPercentage = (
  compareFromResults: number,
  compareToResults: number
) =>
  Math.abs(100 - getInteger(compareFromResults)) -
  Math.abs(100 - getInteger(compareToResults));

export const getEndOfSentence = (
  difference: number,
  heading: string,
  type: string
) => {
  if (type === "no-ref") {
    return `${difference}% shift towards a more ${heading}`;
  }
  return `${difference}% shift towards ${
    isVowel(heading.charAt(0)) ? "an" : "a"
  } ${heading}`;
};

export interface InfoTipItemProps {
  primaryHeading: string;
  secondaryHeading: string;
  cultureResult: number;
}

export const PercentSentence = ({
  primaryHeading,
  secondaryHeading,
  cultureResult,
}: InfoTipItemProps) => {
  const primaryHeadingValue = getInteger(cultureResult);
  const secondaryHeadingValue = Math.abs(100 - primaryHeadingValue);
  const primaryHeadingSentence = `${primaryHeadingValue}% ${primaryHeading}`;
  const secondaryHeadingSentence = `${secondaryHeadingValue}% ${secondaryHeading}`;
  return (
    <>
      <span
        style={
          primaryHeadingValue > secondaryHeadingValue
            ? highlightedHeadingStyle
            : normalHeadingStyle
        }
      >
        {" "}
        {primaryHeadingSentence}
      </span>
      <span style={normalHeadingStyle}>{" and "}</span>
      <span
        style={
          secondaryHeadingValue > primaryHeadingValue
            ? highlightedHeadingStyle
            : normalHeadingStyle
        }
      >
        {secondaryHeadingSentence}.
      </span>
    </>
  );
};

export const PercentSentenceText = ({
  primaryHeading,
  secondaryHeading,
  cultureResult,
}: InfoTipItemProps) => {
  return (
    <Text sx={{ fontSize: "sm", px: 4 }}>
      <PercentSentence
        primaryHeading={primaryHeading}
        secondaryHeading={secondaryHeading}
        cultureResult={cultureResult}
      />
    </Text>
  );
};

export const ShiftSentence = ({
  primaryHeadingDiffInPercentage,
  secondaryHeadingDiffInPercentage,
  primaryHeading,
  secondaryHeading,
  sentence,
  type,
}: {
  primaryHeadingDiffInPercentage: number;
  secondaryHeadingDiffInPercentage: number;
  primaryHeading: string;
  secondaryHeading: string;
  sentence: string;
  type: string;
}) => {
  if (
    primaryHeadingDiffInPercentage === 0 ||
    secondaryHeadingDiffInPercentage === 0
  ) {
    return (
      <>
        {type === "no-ref"
          ? " There is strong alignment between current and desired culture for this dimension."
          : " In this period there has been no change to this culture dimension."}
      </>
    );
  }
  if (
    primaryHeadingDiffInPercentage > 0 ||
    secondaryHeadingDiffInPercentage > 0
  ) {
    const difference =
      primaryHeadingDiffInPercentage > 0
        ? primaryHeadingDiffInPercentage
        : secondaryHeadingDiffInPercentage;
    const heading =
      primaryHeadingDiffInPercentage > 0 ? primaryHeading : secondaryHeading;
    return (
      <>
        <span>{`${sentence}${" "}`}</span>
        <span style={primaryHeadingStyle}>
          {getEndOfSentence(difference, heading, type)}
        </span>
        <span> culture.</span>
      </>
    );
  }
  return null;
};

export const CultureSentence = ({ label }: { label: InsightsCampaignType }) => {
  return (
    <>
      <span style={circleStylesWrapper}>
        <Circle sx={circleStyles[label as "A" | "B"]} />
      </span>
      <span>
        {label === InsightsCampaignType.A ? "current" : "desired"} culture is{" "}
      </span>
    </>
  );
};
