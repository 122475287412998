export const observeContainerStyle = {
  mb: 6,
};
export const observeHeaderContainerStyle = {
  display: "flex",
  alignItems: "flex-start",
};
export const bulletPointStyle = {
  display: "flex",
  alignItems: "flex-start",
  gap: 2,
};
const iconContainerStyle = {
  alignItems: "center",
  display: "flex",
  mt: 1,
};
export const proIconContainerSx = {
  ...iconContainerStyle,
  color: "success.30",
};
export const conIconContainerSx = {
  ...iconContainerStyle,
  color: "warning.30",
};
export const tipIconContainerSx = {
  ...iconContainerStyle,
  color: "brandPurple.80",
};
