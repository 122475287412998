import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { apolloSpy } from "@shortlyster/analytics-kit";
import { getRequestId } from "@compono/sentry-browser";

import { useAuthentication } from "~/hooks/useAuthentication";
import { env } from "~/env";

// import { watcher } from "../mixpanel";

export const apolloClientWithAuth0 = () => {
  const { getAccessTokenSilently, isAuthenticated, orgId } =
    useAuthentication();
  const uri = `${env.FEDERATION_GATEWAY_URL}/graphql`;

  const authLink = setContext(async (_, { headers }) => {
    const accessToken = isAuthenticated ? await getAccessTokenSilently() : null;
    return {
      headers: {
        ...headers,
        ...(orgId && { "x-organisation-id": orgId }),
        ...(accessToken && { authorization: `Bearer ${accessToken}` }),
        "x-request-id": getRequestId(),
      },
    };
  });
  const cache = new InMemoryCache();
  const urlLink = new HttpLink({ uri });

  const baseClient = new ApolloClient({
    link: ApolloLink.from([authLink, urlLink]),
    cache,
  });

  // apolloSpy.configure({ watcher });
  const client = apolloSpy.patch(baseClient);
  return client;
};

export const getPublicFederationClient = () => {
  const uri = `${env.FEDERATION_GATEWAY_URL}/publicGraphql`;

  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        "x-request-id": getRequestId(),
      },
    };
  });
  const cache = new InMemoryCache();
  const urlLink = new HttpLink({ uri });

  const baseClient = new ApolloClient({
    link: ApolloLink.from([authLink, urlLink]),
    cache,
  });

  // apolloSpy.configure({ watcher });
  const client = apolloSpy.patch(baseClient);
  return client;
};
