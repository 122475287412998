export const lineBoxWidth = 24;
export const cardMarginTop = 8;
export const horizontalLineWidth = 2;
export const borderWidth = 1;
export const topLineHeight = 28;

export const sizePaddingMap: { [key: string]: number } = {
  sm: 4,
  md: 6,
  lg: 8,
};
// export const calcPartialTopLine = (isParentLastNode?: boolean) => ({
//   top: `${0 - cardMarginTop - borderWidth}px`,
//   left: `${(-1 * lineBoxWidth) / 2}px`,
//   width: `${lineBoxWidth / 2}px`,
//   height: `${topLineHeight}px`,
//   borderBottomLeftRadius: isParentLastNode ? 3 : 0,
// });

// export const calcPartialBottomLine = (
//   cardHeight: number,
//   cardPadding: number
// ) => ({
//   bottom: `-3px`,
//   left: `${(-1 * lineBoxWidth) / 2}px`,
//   width: `${lineBoxWidth / 2}px`,
//   height: `${
//     cardHeight -
//     (topLineHeight - cardPadding - horizontalLineWidth - borderWidth * 2)
//   }px`,
// });

// export const calcFullLine = (cardHeight: number) => ({
//   bottom: `${0 - borderWidth}px`,
//   left: `${(-1 * lineBoxWidth) / 2}px`,
//   width: `${lineBoxWidth / 2}px`,
//   height: `${cardHeight + cardMarginTop + borderWidth * 2}px`,
// });

export const calcPartialTopLine = (isParentLastNode?: boolean) => ({
  top: `${7 - cardMarginTop - borderWidth}px`,
  left: `${(-1 * lineBoxWidth) / 2}px`,
  width: `${lineBoxWidth / 2}px`,
  height: `${topLineHeight}px`,
  borderBottomLeftRadius: isParentLastNode ? 3 : 0,
});

export const calcPartialBottomLine = (
  cardHeight: number,
  cardPadding: number
) => ({
  bottom: `-1px`,
  left: `${(-1 * lineBoxWidth) / 2}px`,
  width: `${lineBoxWidth / 2}px`,
  height: `${
    cardHeight -
    (topLineHeight - cardPadding - horizontalLineWidth - borderWidth * 2)
  }px`,
});

export const calcFullLine = (cardHeight: number) => ({
  bottom: `${0 - borderWidth}px`,
  left: `${(-1 * lineBoxWidth) / 2}px`,
  width: `${lineBoxWidth / 2}px`,
  height: `${cardHeight + cardMarginTop + borderWidth * 2}px`,
});
