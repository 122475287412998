import React from "react";
import ViolinCard from "./ViolinCard";
import { CultureResult } from "./types";

// constants
export const dimensions = [
  { engagekey: "riskTolerance", key: "innovative|cautious" },
  { engagekey: "driver", key: "process|outcome" },
  { engagekey: "focus", key: "task centred|employee centred" },
  { engagekey: "teamWork", key: "collective|individual" },
  { engagekey: "control", key: "centralised control|delegated control" },
  {
    engagekey: "structure",
    key: "hierarchical structure|horizontal structure",
  },
  { engagekey: "responsivePlanned", key: "ad-hoc|planned" },
  { engagekey: "conformity", key: "conformity|individuality" },
  { engagekey: "accepting", key: "accepting|questioning" },
  { engagekey: "cooperation", key: "cooperation|competition" },
  { engagekey: "internalExternal", key: "external|internal" },
  { engagekey: "formal", key: "formalised|informal" },
];

export interface ExplorerGraphProps {
  seriesA?: CultureResult;
  seriesB?: CultureResult;
  reference?: CultureResult;
  showObservation?: boolean;
}

const ExplorerGraph = ({ seriesA, seriesB, reference }: ExplorerGraphProps) => {
  return (
    <>
      {dimensions.map((dimension, index) => {
        const { engagekey, key } = dimension;
        return (
          <ViolinCard
            dimension={key}
            markers={
              seriesA
                ? [
                    {
                      position: seriesA.average[engagekey],
                      type: "primary",
                    },
                    ...(reference
                      ? [
                          {
                            position: reference.average[engagekey],
                            type: "line",
                            color: "#AAAAAA",
                          },
                        ]
                      : []),
                  ]
                : undefined
            }
            violinData={seriesA ? seriesA.results[engagekey] : undefined}
            markersB={
              seriesA && seriesB
                ? [
                    {
                      position: seriesB.average[engagekey],
                      type: "secondary",
                    },
                    ...(reference
                      ? [
                          {
                            position: reference.average[engagekey],
                            type: "line",
                            color: "#AAAAAA",
                            lineOnly: true,
                          },
                        ]
                      : []),
                  ]
                : undefined
            }
            violinBData={seriesB ? seriesB.results[engagekey] : undefined}
            toggleTipDirection={index === 0 ? "down" : "up"}
          ></ViolinCard>
        );
      })}
    </>
  );
};

export default ExplorerGraph;
