import {
  Heading,
  Box,
  Button,
  Dialog,
  DialogHeading,
  DialogBody,
  DialogPrimaryActions,
  DialogCloseButton,
  Card,
  Text,
  Inline,
} from "@compono/ui";
import React from "react";
import BarStackChart from "../../../components/BarStackChart/BarStackChart";
const leftElement: ThemeUIStyleObject = {
  p: 2,
  display: "flex",
  justifyContent: "left",
  whiteSpace: "normal",
  textAlign: "left",
  fontWeight: "normal",
};

const rightElement: ThemeUIStyleObject = {
  ...leftElement,
  justifyContent: "right",
  textAlign: "right",
};

export const HowToDialog = ({ howToOpen, setHowToOpen }) => (
  <Dialog
    isOpen={howToOpen}
    aria-describedby="dialog-description"
    aria-labelledby="dialog-title"
  >
    <DialogHeading>How does this work?</DialogHeading>
    <DialogBody>
      <Heading level="6">Engagement</Heading>
      <p>
        The Compono engagement survey captures employee perception of company
        performance and work atmosphere across 9 different dimensions.
      </p>
      <p>
        For both company performance and work atmosphere, we have calculated an
        average overall score and satisfaction rating as well as providing a
        stacked bar chart showing the distribution of scores.
      </p>
      <p>
        The bar shows you how the results are spread across strongly disagree
        (in red) to strongly agree (dark green). A marker shows the average
        score.
      </p>

      <Card border elevation="0" sx={{ mb: 5 }}>
        <Text>
          <b>Job Engagement</b>
        </Text>
        <Text>I am engaged in my job.</Text>
        <Box sx={{ mt: 5 }}>
          <BarStackChart
            items={[
              { value: 2, color: "#FF2E2E" },
              { value: 2, color: "#F97316" },
              { value: 1, color: "#FFE29B" },
              { value: 0, color: "#93E0D2" },
              { value: 5, color: "#14B8A6" },
            ]}
            markerValue={3.4}
          ></BarStackChart>
        </Box>
      </Card>

      <p>
        Below the overall score, there is a breakdown by each individual
        engagement dimension.
      </p>
      <p>
        Hover over the bar to see the number of respondents (and percentage)
        contributing to each response.
      </p>
    </DialogBody>
    <DialogPrimaryActions>
      <Button
        onClick={() => {
          setHowToOpen(false);
        }}
      >
        OK
      </Button>
    </DialogPrimaryActions>
    <DialogCloseButton
      onClick={() => {
        setHowToOpen(false);
      }}
    />
  </Dialog>
);
