import { gql } from "@apollo/client";

export const GetCampaigns = gql`
  query Campaigns {
    campaigns {
      sk
      name
      dueDate
      createdDate
      status
      completionRate
      lastSaved
      responses
      respondents
      purpose
      note
      filters {
        tenure
        jobTitle
        jobLevel
        officeLocation
        divisions
        groups
      }
      milestones {
        description
        startDate
        endDate
      }
      assessments {
        type
        sk
      }
      publishDate
      closedDate
      superCharged
      hasPlaceholders
    }
  }
`;

export const CloseCampaign = gql`
  mutation CloseCampaign($sk: String) {
    closeCampaign(sk: $sk) {
      sk
      name
      dueDate
      createdDate
      status
      completionRate
      lastSaved
      responses
      respondents
      purpose
      milestones {
        description
        startDate
        endDate
      }
      assessments {
        type
        sk
      }
      publishDate
      closedDate
    }
  }
`;

export const RemoveDraftCampaign = gql`
  mutation RemoveDraftCampaign($sk: String) {
    removeDraftCampaign(sk: $sk)
  }
`;

export const CreateCampaign = gql`
  mutation CreateCampaign($timezone: String) {
    createCampaign(timezone: $timezone) {
      sk
      name
      dueDate
      createdDate
      status
      completionRate
      lastSaved
      responses
      respondents
      purpose
      note
      milestones {
        description
        startDate
        endDate
      }
      assessments {
        type
        sk
      }
      publishDate
      closedDate
    }
  }
`;

export const CampaignSuperchargeSeen = gql`
  mutation CampaignSuperchargeSeen($sk: String) {
    campaignSuperchargeSeen(sk: $sk)
  }
`;
