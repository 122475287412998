import React, { Dispatch, useEffect, useState } from "react";
import {
  Text,
  CoachTip,
  Button,
  Dialog,
  DialogBody,
  DialogCloseButton,
  DialogHeading,
  DialogPrimaryActions,
  Heading,
} from "@compono/ui";
import { infoCardStyle } from "./styles";
import { ChevronRightSolidIcon } from "@compono/ui-icons";
import withImage from "../../../assets/images/With_supercharge.png";
import withoutImage from "../../../assets/images/Without_supercharge.png";

const SuperChargeInfo = ({
  superChargeInfoOpen,
  setSuperChargeInfoOpen,
  updateCampaignSuperchargeInfo,
}: {
  superChargeInfoOpen: boolean;
  setSuperChargeInfoOpen: Dispatch<SetStateAction<boolean>>;
  updateCampaignSuperchargeInfo: () => void;
}) => {
  return (
    <>
      <Dialog
        aria-describedby="dialog-description"
        aria-labelledby="dialog-title"
        isOpen={superChargeInfoOpen}
        size="lg"
      >
        <DialogHeading id="dialog-title">
          Add demographics to supercharge your reports
        </DialogHeading>
        <DialogBody id="dialog-description">
          <Heading level="5">Without demographics</Heading>
          <Text>
            Without demographics (employee information), your data is less
            specific. You will only see the average for your whole organisation,
            which is sometimes misleading. Demographics let you break down data
            by division, tenure, job level etc, which allows you to make
            better-informed decisions. In Compono Hire, you will match
            candidates against culture data that's been averaged across
            organisation, and miss out on the power of matching them to a
            divisions specific culture.
          </Text>
          <img src={withoutImage} width="750"></img>
          <Heading level="5">With demographics</Heading>
          <img src={withImage} width="750"></img>
        </DialogBody>
        <DialogPrimaryActions>
          <Button look="tertiary" onClick={() => setSuperChargeInfoOpen(false)}>
            Not now
          </Button>
          <Button
            onClick={() => {
              updateCampaignSuperchargeInfo();
            }}
          >
            Let's do it
          </Button>
        </DialogPrimaryActions>
        <DialogCloseButton onClick={() => setSuperChargeInfoOpen(false)} />
      </Dialog>
      <CoachTip
        title="Want to supercharge your culture reports?"
        sx={infoCardStyle}
        data-testid="superchargeInfo"
      >
        <Text>
          Set up your user demographics (divisions, locations and job levels) to
          unlock more powerful insights about your organisation’s culture.
        </Text>
        <Button
          iconRight={<ChevronRightSolidIcon />}
          look="link"
          onClick={() => setSuperChargeInfoOpen(true)}
        >
          Learn more
        </Button>
      </CoachTip>
    </>
  );
};

export default SuperChargeInfo;
