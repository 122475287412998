import { SelectableOptionT } from "@compono/engage-backend-stack/src/types";
import { Box, Button, Card, Inline, Loader } from "@compono/ui";
import { CloseSolidIcon } from "@compono/ui-icons";
import React, { useEffect, useState } from "react";
import { useApolloClients } from "~/components/ApolloClients";
import { FilterPill } from "~/components/FilterPill";
import {
  CulturePerceptionResult,
  useCulturePerceptionLazyQuery,
} from "~/lib/federationGateway/graphql";
import { Filter, FilterType } from "~/types/filter";
import { EngagementContent } from ".";
import NotFoundImage from "../../../assets/images/not-found-image";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import { AssessmentType, Campaign } from "../../../types";
import { FiltersSelect } from "../Explorer";
import { InsightsFilterInput, InsightsFilterType } from "../Explorer/types";

const Engagement = ({
  selectedCampaign,
  closedCampaigns,
}: {
  selectedCampaign?: Campaign;
  closedCampaigns: Campaign[];
}) => {
  const [filters, setFilters] = useState<Filter[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [culturePerception, setCulturePerception] =
    useState<CulturePerceptionResult>();
  const perceptionAssessment = selectedCampaign?.assessments?.find(
    (assessment) => assessment.type === AssessmentType.Perception
  );
  const { federationGatewayClient } = useApolloClients();
  const [GetCulturePerceptionQuery] = useCulturePerceptionLazyQuery({
    client: federationGatewayClient,
    fetchPolicy: "cache-and-network",
  });

  useEffect(async () => {
    if (selectedCampaign) {
      setLoading(true);
      const { data } = await GetCulturePerceptionQuery({
        variables: { assessmentSk: perceptionAssessment?.sk },
      });
      setCulturePerception(data?.culturePerception);
      setFilters([]);
      setLoading(false);
    }
  }, [selectedCampaign]);

  useEffect(async () => {
    if (filters) {
      const divisions = filters.filter(
        (item) => item.type === FilterType.Division
      );
      const officeLocation = filters.filter(
        (item) => item.type === FilterType.OfficeLocation
      );
      const jobLevel = filters.filter(
        (item) => item.type === FilterType.JobLevel
      );

      const queryFilters: InsightsFilterInput[] = [
        {
          filterType: InsightsFilterType.DIVISIONS,
          options: divisions.map((division) => division.value),
        },
        {
          filterType: InsightsFilterType.OFFICE_LOCATION,
          options: officeLocation.map((location) => location.value),
        },
        {
          filterType: InsightsFilterType.JOB_LEVEL,
          options: jobLevel.map((job) => job.value),
        },
      ].filter((item) => item.options.length);
      setLoading(true);
      const { data } = await GetCulturePerceptionQuery({
        variables: {
          assessmentSk: perceptionAssessment?.sk,
          filters: queryFilters,
        },
      });
      setCulturePerception(data?.culturePerception);
      setLoading(false);
    }
  }, [filters]);

  if (loading) {
    return (
      <Box sx={{ margin: "20px" }}>
        <Inline alignX="center">
          <Loader size="lg" label="loading" hideLabel />
        </Inline>
      </Box>
    );
  }

  const topLevelFilterValues = {
    ...selectedCampaign,
    filters: {
      divisions: selectedCampaign?.filters?.divisions,
      officeLocation: selectedCampaign?.filters?.officeLocation,
      jobLevel: selectedCampaign?.filters?.jobLevel,
    },
  };

  const addFilter = async (filter: Filter) => {
    const exists = filters.find(
      (f) => f.type === filter.type && f.value === filter.value
    );
    if (!exists) setFilters([...filters, filter]);
  };
  const removeFilter = async (filter: Filter) => {
    const removed = filters.filter((item) => {
      return item.type !== filter.type || item.value !== filter.value;
    });
    setFilters(removed);
  };

  const onAddFilter = (
    option: SelectableOptionT,
    level: SelectableOptionT,
    demographic: string
  ) => {
    let type: FilterType = "";

    if (demographic === "Divisions") {
      type = FilterType.Division;
    } else if (demographic === "Office location") {
      type = FilterType.OfficeLocation;
    } else if (demographic === "Job level") {
      type = FilterType.JobLevel;
    }
    if (type === FilterType.Division) {
      const filterLabel = option ? option.label : level.label;
      let filterOption = (option ? option.path : level.path) as string;
      if (filterOption.startsWith("$"))
        filterOption = filterOption.substring(1);

      addFilter({
        name: filterLabel,
        type,
        value: filterOption + "$",
      });
    } else {
      addFilter({
        name: option.label,
        type,
        value: option.label,
      });
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "black.3",
      }}
    >
      <Box
        sx={{
          marginLeft: "20px",
          maxWidth: "1375px",
          pl: 3,
        }}
      >
        {!!closedCampaigns?.length && (
          <Inline>
            <FiltersSelect
              onAddFilter={onAddFilter}
              selectedCampaign={topLevelFilterValues || {}}
              showIcon={true}
              buttonDisabled={
                selectedCampaign &&
                !culturePerception?.completedUsersCount &&
                !filters.length
              }
            />
            <Inline>
              {filters.map((filter: Filter) => {
                return (
                  <FilterPill filter={filter} removeFilter={removeFilter} />
                );
              })}
              {filters.length > 0 && (
                <Button
                  look="naked"
                  iconLeft={CloseSolidIcon}
                  onClick={() => {
                    setFilters([]);
                  }}
                >
                  Clear all
                </Button>
              )}
            </Inline>
          </Inline>
        )}
      </Box>
      {selectedCampaign && !!culturePerception?.completedUsersCount && (
        <EngagementContent
          loading={loading}
          culturePerception={culturePerception}
        ></EngagementContent>
      )}
      {selectedCampaign &&
        !culturePerception?.completedUsersCount &&
        !!filters.length && (
          <Card
            sx={{
              textAlign: "center",
              width: "100%",
              backgroundColor: "#FFFFFF",
            }}
          >
            <ErrorMessage
              title="Nothing to see here!"
              icon={<NotFoundImage />}
              description="Looks like there is no survey data for the filter selected."
            />
          </Card>
        )}
      {selectedCampaign &&
        !culturePerception?.completedUsersCount &&
        !filters.length && (
          <Card
            sx={{
              textAlign: "center",
              width: "100%",
              backgroundColor: "#FFFFFF",
            }}
          >
            <ErrorMessage
              title="Nothing to see here!"
              icon={<NotFoundImage />}
              description="Looks like there is no survey data for this campaign."
            />
          </Card>
        )}
      {!closedCampaigns?.length && (
        <Card
          sx={{
            textAlign: "center",
            width: "100%",
            backgroundColor: "#FFFFFF",
          }}
        >
          <ErrorMessage
            title="Nothing to see here!"
            icon={<NotFoundImage />}
            description="There are no closed campaigns to report on."
          />
        </Card>
      )}
    </Box>
  );
};

export default Engagement;
