import { primaryHeadingStyle } from "../../../components/CultureReportInfoTip/styles";
import { headings } from "../Explorer/InsightsGraph";
import {
  getEndOfSentence,
  getPrimaryHeadingDiffInPercentage,
  getSecondaryHeadingDiffInPercentage,
} from "../Report/ReportGraph/utils";
export const getHeading = (dimension: string) => {
  return headings.find((h) => {
    return h.key == dimension;
  });
};

export const mapDimensions = (index: number) => {
  switch (index) {
    case 0:
      return "riskTolerance";
    case 1:
      return "driver";
    case 2:
      return "focus";
    case 3:
      return "teamWork";
    case 4:
      return "control";
    case 5:
      return "structure";
    case 6:
      return "responsivePlanned";
    case 7:
      return "conformity";
    case 8:
      return "accepting";
    case 9:
      return "cooperation";
    case 10:
      return "internalExternal";
    case 11:
      return "formal";
    default:
      break;
  }
};

export const transformDimensions = (dimensions: {
  "centralised control|delegated control": string;
  "ad-hoc|planned": string;
  "innovative|cautious": string;
  "conformity|individuality": string;
  "formalised|informal": string;
  "hierarchical structure|horizontal structure": string;
  "accepting|questioning": string;
  "external|internal": string;
  "collective|individual": string;
  "task centred|employee centred": string;
  "cooperation|competition": string;
  "process|outcome": string;
}): number[] => {
  const dimensionsArray = [
    dimensions["innovative|cautious"],
    dimensions["process|outcome"],
    dimensions["task centred|employee centred"],
    dimensions["collective|individual"],
    dimensions["centralised control|delegated control"],
    dimensions["hierarchical structure|horizontal structure"],
    dimensions["ad-hoc|planned"],
    dimensions["conformity|individuality"],
    dimensions["accepting|questioning"],
    dimensions["cooperation|competition"],
    dimensions["external|internal"],
    dimensions["formalised|informal"],
  ];
  return dimensionsArray.map((value) => differenceToPercent(value));
};

export const valuesToPercent = (value: number): number => {
  return Number((100 - ((value - 1) / 4) * 100).toFixed(0));
};

export const differenceToPercent = (value: number): number => {
  const exp = value > 50 ? exponentialise(50) : exponentialise(value);

  return { percentage: value, colorValue: exp };
};

export const exponentialise = (percentage: number) => {
  const exp = (percentage / 8) * percentage + 5;
  return exp;
};

export const calculateHotspots = (hp: CultureHotspot) => {
  const dimensions = {
    "centralised control|delegated control": 0,
    "ad-hoc|planned": 0,
    "innovative|cautious": 0,
    "conformity|individuality": 0,
    "formalised|informal": 0,
    "hierarchical structure|horizontal structure": 0,
    "accepting|questioning": 0,
    "external|internal": 0,
    "collective|individual": 0,
    "task centred|employee centred": 0,
    "cooperation|competition": 0,
    "process|outcome": 0,
  };

  for (const propt in hp.current) {
    dimensions[propt] = Math.abs(
      valuesToPercent(hp.current[propt]) - valuesToPercent(hp.desired[propt])
    );
  }
  return {
    aggregation: hp.aggregation,
    aggregateFilter: hp.aggregateFilter,
    dimensions,
    responseCount: hp.responseCount,
    hasParent: hp.hasParent,
  };
};

export const getChangeSentence = (
  currentAverage,
  desiredAverage,
  right,
  left
) => {
  let difference;
  let heading;
  let primaryDiffARef;
  let secondaryDiffARef;
  const primaryHeadingDiffInPercentage = getPrimaryHeadingDiffInPercentage(
    currentAverage,
    desiredAverage
  );
  const secondaryHeadingDiffInPercentage = getSecondaryHeadingDiffInPercentage(
    currentAverage,
    desiredAverage
  );
  difference =
    primaryHeadingDiffInPercentage > 0
      ? primaryHeadingDiffInPercentage
      : secondaryHeadingDiffInPercentage;
  heading = primaryHeadingDiffInPercentage > 0 ? right : left;
  return difference == 0 ? (
    <span>
      There is strong alignment between current and desired culture for this
      dimension.
    </span>
  ) : (
    <>
      <span>Employees desire a </span>
      <span style={primaryHeadingStyle}>
        {getEndOfSentence(difference, heading, "no-ref")}
      </span>
      <span> culture.</span>
    </>
  );
};
export const getObservation = (observations, key, average) => {
  return parseKeyToJson(observations, key, average);
};

export const lineBoxWidth = 24;
export const cardMarginTop = 8;
export const horizontalLineWidth = 2;
export const borderWidth = 1;
export const topLineHeight = 28;

export const sizePaddingMap: { [key: string]: number } = {
  sm: 4,
  md: 6,
  lg: 8,
};

export const calcPartialTopLine = (isParentLastNode?: boolean) => ({
  top: `${7 - cardMarginTop - borderWidth}px`,
  left: `${(-1 * lineBoxWidth) / 2}px`,
  width: `${lineBoxWidth / 2}px`,
  height: `${topLineHeight}px`,
  borderBottomLeftRadius: isParentLastNode ? 3 : 0,
});

export const calcPartialBottomLine = (
  cardHeight: number,
  cardPadding: number
) => ({
  bottom: `-1px`,
  left: `${(-1 * lineBoxWidth) / 2}px`,
  width: `${lineBoxWidth / 2}px`,
  height: `${
    cardHeight -
    (topLineHeight - cardPadding - horizontalLineWidth - borderWidth * 2)
  }px`,
});

export const calcFullLine = (cardHeight: number) => ({
  bottom: `${0 - borderWidth}px`,
  left: `${(-1 * lineBoxWidth) / 2}px`,
  width: `${lineBoxWidth / 2}px`,
  height: `${cardHeight + cardMarginTop + borderWidth * 2}px`,
});

export const getName = (hotspot) => {
  const { aggregation, hasParent, aggregateFilter } = hotspot;
  if (!hasParent) return aggregation;
  //Warning dodgy code
  const splitArray = aggregateFilter.split("$");
  return aggregation.substring(splitArray[0].length + 1);
};
