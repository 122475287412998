import React from "react";
import { scaleLinear } from "@visx/scale";
import { Group } from "@visx/group";
import { ViolinPlot as VisxViolin } from "@visx/stats";
import { Axis, Orientation } from "@visx/axis";
import PrimaryMarker from "./markers/PrimaryMarker";
import SecondaryMarker from "./markers/SecondaryMarker";
import LineMarker from "./markers/LineMarker";
import OverlapMarker from "./markers/OverlapMarker";
import { ParentSize } from "@visx/responsive";
import { Box } from "@compono/ui";

const MIN = 1;
const MAX = 5;
const TICK_LENGTH = 6;
const px = 40;
const py = 7.5;
const VIOLIN_PLOT_PRIMARY_COLOR = "#3E1F98";
const VIOLIN_PLOT_SECONDARY_COLOR = "#808080";
const AXIS_COLOR = "#AAAAAA";
const COMP_STROKE_COLOR = "#555555";
export const PRIMARY_COLOR = "#741994";
const SECONDARY_COLOR = "#484769";
const WHITE_COLOR = "#FFFFFF";
const REFERENCE_ICON_COLOR = "#AAAAAA";

export const mean = (values: any, valueof: any) => {
  let count = 0;
  let sum = 0;
  if (valueof === undefined) {
    for (let value of values) {
      if (value != null && (value = +value) >= value) {
        ++count, (sum += value);
      }
    }
  } else {
    let index = -1;
    for (let value of values) {
      if (
        (value = valueof(value, ++index, values)) != null &&
        (value = +value) >= value
      ) {
        ++count, (sum += value);
      }
    }
  }
  if (count) return sum / count;
};
const mapResultsToBinData = (nums: number[]) => {
  const mappedDataset = nums.map((num) => (num - 1) / 4);
  const epanechnikov =
    (bandwidth = 7) =>
    (x: number) =>
      Math.abs(x / bandwidth) <= 1 ? (0.75 * (1 - x * x)) / bandwidth : 0;
  const getThresholds = (divisions = 20) =>
    Array.from({ length: divisions + 1 }, (_, i) =>
      Number.parseFloat((i * (1 / divisions)).toFixed(2))
    );
  const thresholds = getThresholds(30);
  const kde = (kernel: any, data: number[]) =>
    thresholds.map((t) => [t, mean(data, (d: any) => kernel(t - d))]);
  const density = kde(epanechnikov(0.15), mappedDataset);
  return density.map(([x, y]) => ({
    value: x! * 4 + 1,
    count: Math.round(1000 * y!),
  }));
};

export interface Marker {
  type: "primary" | "secondary" | "line" | string;
  color?: string;
  position: number;
  lineOnly?: boolean;
}
export interface ViolinPlotProps {
  violinData?: number[];
  showAxis?: boolean;
  height?: number;
  violinPlotColor?: string;
  markers?: Marker[];
}
const ViolinPlot = ({
  violinData,
  showAxis = true,
  height = 40,
  violinPlotColor = PRIMARY_COLOR,
  markers = [],
}: ViolinPlotProps) => {
  return (
    <ParentSize ignoreDimensions={["height"]}>
      {({ width: w, height: h }) => {
        // bounds
        const xMax = w - px;
        // scales
        const xScale = scaleLinear<number>({
          range: [0, xMax],
          round: true,
          domain: [MIN, MAX],
        });
        return (
          <Box sx={{ height: `${height - 6}px` }}>
            <svg width={w} height={height}>
              <Group left={px / 2}>
                <Group>
                  {violinData && (
                    <VisxViolin
                      horizontal={true}
                      data={mapResultsToBinData(violinData)}
                      left={0}
                      top={py}
                      width={height - py * 2}
                      valueScale={xScale}
                      fill={violinPlotColor}
                      fillOpacity={0.25}
                    />
                  )}
                  {showAxis && (
                    <Axis
                      orientation={Orientation.bottom}
                      top={height / 2}
                      scale={xScale}
                      stroke={AXIS_COLOR}
                      tickStroke={AXIS_COLOR}
                      tickLength={TICK_LENGTH}
                      tickTransform={`translate(0 -${TICK_LENGTH / 2})`}
                      tickFormat={() => ""}
                      tickValues={[1, 2, 3, 4, 5]}
                    />
                  )}
                  {markers.map((marker) => {
                    switch (marker.type) {
                      case "primary":
                        return (
                          <PrimaryMarker
                            color={marker.color}
                            position={xScale(marker.position)}
                          ></PrimaryMarker>
                        );
                      case "secondary":
                        return (
                          <SecondaryMarker
                            color={marker.color}
                            position={xScale(marker.position)}
                          ></SecondaryMarker>
                        );
                      case "line":
                        return (
                          <LineMarker
                            color={marker.color}
                            position={xScale(marker.position)}
                            lineOnly={marker.lineOnly}
                          ></LineMarker>
                        );

                      case "overlap":
                        return (
                          <OverlapMarker
                            color={marker.color}
                            position={xScale(marker.position)}
                          ></OverlapMarker>
                        );

                      default:
                        break;
                    }
                  })}
                </Group>
              </Group>
            </svg>
          </Box>
        );
      }}
    </ParentSize>
  );
};

export default ViolinPlot;
