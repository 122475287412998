export const getFilterLabel = (filterType: string) => {
  switch (filterType) {
    case "divisions":
      return "Divisions";
    case "officeLocation":
      return "Locations";
    case "jobLevel":
      return "Job Levels";

    default:
      return "Divisions";
  }
};
export const getName = (filter) => {
  const { filterString, indented, name } = filter;
  if (!indented) return name;
  //Warning dodgy code
  const splitArray = filterString.split("$");
  return filterString.substring(splitArray[0].length + 1);
};
