import React, { Dispatch, SetStateAction, useState } from "react";

type Context = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

export const MutationLoadingContext = React.createContext<Context>({
  isLoading: false,
  setIsLoading: () => undefined,
});

export const MutationLoadingProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <MutationLoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </MutationLoadingContext.Provider>
  );
};
