import { ThemeUIStyleObject } from "@compono/ui-theme";

export const REPORT_CARD_WIDTH = "1440px";

export const reportCardStyle = {
  maxWidth: REPORT_CARD_WIDTH,
  ml: 0,
  p: 6,
};

export const infoCardStyle = {
  maxWidth: REPORT_CARD_WIDTH,
  ml: 6,
  mt: 5,
  p: 6,
};
export const titleContainerStyle: ThemeUIStyleObject = {
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  mb: 6,
};

export const titleStyle: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  gap: 5,
  p: 3,
};

export const subTitleStyle = {
  font: "title",
  color: "black.60",
};

export const completionStyle = {
  flex: 1,
  pr: 6,
  alignSelf: "flex-start",
  maxWidth: "50%",
};

export const milestonesStyle = {
  flex: 1,
  pl: 6,
};

export const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  gap: 5,
};

export const headerSelectContainerStyle = {
  display: "flex",
  alignItems: "center",
  gap: 6,
  flex: 1,
};

export const dividerStyle = {
  height: 72,
  borderLeft: "1px solid var(--colors-black-20)",
};

export const ViolinPlotExplainerStyle = {
  ...reportCardStyle,
  ml: 6,
  p: 1,
};

export const cultureDimensionsInsights = {
  justifyContent: "space-between",
  height: "78px",
  backgroundColor: "black.60",
  alignItems: "center",
  padding: "0 20px",
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  marginTop: "20px",
};
