import { Box, Card, ToggleTip, Text } from "@compono/ui";
import ViolinPlot from "../../../components/PlotGraph/Violinplot";
import React from "react";
import { ThemeUIStyleObject } from "@compono/ui-theme";
import { QuestionMarkCircleOutlineIcon } from "@compono/ui-icons";
import { CULTURE_FIT_QUESTIONS } from "@compono/assessments-kit";
const leftElement: ThemeUIStyleObject = {
  p: 1,
  display: "flex",
  justifyContent: "left",
  whiteSpace: "normal",
  textAlign: "left",
  fontWeight: "normal",
  pt: 2,
};

const rightElement: ThemeUIStyleObject = {
  ...leftElement,
  justifyContent: "right",
  textAlign: "right",
  pr: 4,
};
const ViolinCard = ({ dimension, markers }) => {
  const [left, right] = CULTURE_FIT_QUESTIONS[dimension];
  const [leftHeading, leftText] = left;
  const [rightHeading, rightText] = right;
  return (
    <Card key={dimension} border elevation={"2"} sx={{ mb: 5 }}>
      <Box
        sx={{
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "23% 55% 23%",
          }}
        >
          <Box sx={leftElement}>
            <Box>
              <ToggleTip
                label="InformationCircleOutlineIcon"
                icon={<QuestionMarkCircleOutlineIcon />}
              >
                {leftText}
              </ToggleTip>
            </Box>
            <Text>{leftHeading}</Text>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <ViolinPlot markers={markers} />
          </Box>
          <Box sx={rightElement}>
            {rightHeading}
            <Box>
              <ToggleTip
                label="InformationCircleOutlineIcon"
                icon={<QuestionMarkCircleOutlineIcon />}
              >
                {rightText}
              </ToggleTip>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
export default ViolinCard;
