import { useAuthentication } from "./useAuthentication";

const ORG_ID_REGEXP = new RegExp(
  `^/organisation/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}`
);

const prependOrgIdToPath = (orgId: string | null, path = "") => {
  if (!orgId) return path;
  const barePath = path.replace(ORG_ID_REGEXP, "");
  if (barePath === "/shared/cultureReport") return barePath;
  const pathWithOrgId = `/organisation/${orgId}${barePath}`;
  return pathWithOrgId.replace(/\/$/, "");
};

export const useEnsureOrgIdPath = (path?: string) => {
  return useEnsureOrgIdPathFn()(path);
};

export const useEnsureOrgIdPathFn = () => {
  const { orgId } = useAuthentication();

  return (path?: string) => {
    if (path && !path.startsWith("/")) {
      return "";
    }
    if (!orgId) return path || "";
    return prependOrgIdToPath(orgId, path);
  };
};

// deprecated - use named export
export default useEnsureOrgIdPath;
