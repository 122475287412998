import React, { FC, useState } from "react";
import { createContext } from "../lib/createContext";

export type AlertInput = {
  dismissable?: boolean;
  title?: string;
  message?: string;
  type?:
    | "disabled"
    | "ok"
    | "warning"
    | "critical"
    | "unknown"
    | "invalid"
    | "info";
};

export type Alert = AlertInput & {
  key: keyof Alerts;
};

type InternalAlert = Alert & { createdAt: number };

// add new alert types here
export type Alerts = {
  interviewSaved?: InternalAlert;
  interviewSaveError?: InternalAlert;
  interviewCancelled?: InternalAlert;
  // myNewAlertType?: Alert;
};

export type ValidAlertTypes = keyof Alerts;

type AlertsContextType = {
  clearAlert: (key: ValidAlertTypes) => void;
  clearAlerts: (alerts: Alerts) => void;
  getAlert: (key: ValidAlertTypes) => Alert | undefined;
  setAlert: (key: ValidAlertTypes, alert: Omit<Alert, "key">) => void;
};

const [useAlerts, Provider] = createContext<AlertsContextType>({
  hookName: "useAlerts",
  providerName: "AlertsProvider",
});

export { useAlerts };

const alertExpiresInSeconds = 2;

export const AlertsProvider: FC = ({ children }) => {
  const [alerts, setAlerts] = useState<Alerts>({});

  const clearAlert = (key: ValidAlertTypes) => {
    if (alerts[key]) {
      const { [key]: removed, ...remainingAlerts } = alerts;
      setAlerts(remainingAlerts);
    }
  };

  const clearAlerts = () => {
    setAlerts({});
  };

  const getAlert = (key: ValidAlertTypes): Alert | undefined => {
    const alert = alerts[key];
    if (
      !alert ||
      alert?.createdAt < new Date().getTime() - alertExpiresInSeconds * 1000
    ) {
      return undefined;
    }

    const { createdAt, ...rest } = alert;
    return rest;
  };

  const setAlert = (key: ValidAlertTypes, alert: AlertInput) => {
    return setAlerts((currentAlerts) => ({
      ...currentAlerts,
      [key]: { type: "ok", ...alert, createdAt: new Date().getTime(), key },
    }));
  };

  return (
    <Provider value={{ clearAlert, clearAlerts, getAlert, setAlert }}>
      {children}
    </Provider>
  );
};
