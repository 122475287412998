import { useContext } from "react";
import { PermissionsContext } from "../contexts";
import { Permission } from "./types";

export const usePermissions = () => {
  // context
  const [permissions, setPermissions] = useContext<any>(PermissionsContext);

  const setPerms = (perms: Permission) => {
    setPermissions(perms);
  };

  return { permissions, setPerms };
};
