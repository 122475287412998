import React from "react";
import { Box, ProgressCircle, Text } from "@compono/ui";
import { ThemeUIStyleObject } from "@compono/ui-theme";
import { ExclamationTriangleSolidIcon } from "@compono/ui-icons";

import { Campaign } from "../../types";
import { getCompletionRate, hasEnoughResponses } from "../../shared/helpers";

const CampaignCompletionCard = ({
  campaign,
  sx,
}: {
  campaign: Campaign;
  sx?: ThemeUIStyleObject;
}) => {
  return (
    <Box sx={{ gap: 5, display: "flex", pt: "20px", pl: "20px", ...sx }}>
      <ProgressCircle
        size="sm"
        value={getCompletionRate(campaign, 0)}
        sx={{ flexShrink: 0 }}
      />
      <Box sx={{ marginTop: 2 }}>
        <Text>
          {(campaign.responses ? +campaign.responses : 0).toLocaleString()} out
          of{" "}
          {(campaign.respondents
            ? +campaign.respondents?.length
            : 0
          ).toLocaleString()}{" "}
          people responded to this assessment.
        </Text>
        {!hasEnoughResponses(campaign) && (
          <Box sx={{ mt: 1, display: "flex" }}>
            <Text sx={{ color: "warning.60" }}>
              <ExclamationTriangleSolidIcon />
            </Text>
            <Text size="xs" sx={{ fontStyle: "italic", color: "black.80" }}>
              This may not be a representative sample.
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CampaignCompletionCard;
