import { Card, Inline, Box } from "@compono/ui";
import { DotSolidIcon } from "@compono/ui-icons";
import React from "react";
import { desiredCultureIconStyle } from "../Report/ReportGraph/styles";
import { getHeading, valuesToPercent } from "./utils";
import ViolinPlot from "../../../components/PlotGraph/Violinplot";
const bolded = (percentage: number, dimension: string) => {
  const x = `${percentage}% ${dimension}`;
  return percentage > 50 ? <strong>{x}</strong> : <>{x}</>;
};
const GraphCard = ({ dimension, average, results, totalResults, current }) => {
  const { left, right } = getHeading(dimension);
  return (
    <Card border={true} sx={{ backgroundColor: "var(--colors-black-3)" }}>
      <Inline alignX="center">
        <strong>{left}</strong>
        <Box sx={{ width: "410px" }}>
          <ViolinPlot
            violinData={results.length >= 5 ? results : null}
            markers={[
              { type: current ? "primary" : "secondary", position: average },
            ]}
          ></ViolinPlot>
        </Box>
        <strong>{right}</strong>
      </Inline>
      <Inline alignX="center">
        <Box sx={{ fontSize: "1.8rem", mt: 1 }}>
          {current ? (
            <DotSolidIcon color="var(--colors-brandPurple-80)" />
          ) : (
            <Box sx={desiredCultureIconStyle} />
          )}
        </Box>
        <Box>
          {current ? "Current" : "Desired"} culture is{" "}
          {bolded(valuesToPercent(average), left)} and{" "}
          {bolded(100 - valuesToPercent(average), right)}
        </Box>
      </Inline>
    </Card>
  );
};
export default GraphCard;
