export const ViolinPlotExplainerStyle = {
  display: "flex",
  borderRadius: 2,
  justifyContent: "center",
};

export const exampleTextStyle = {
  color: "black.60",
  mt: 4,
};
