import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = ({ width = 337 }) => (
  <svg width={width} height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.5"
      y="1"
      width={width}
      height="14"
      rx="5"
      fill="url(#paint0_linear_9091_9432)"
      stroke="white"
      stroke-width="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9091_9432"
        x1="2.5"
        y1="7.99999"
        x2="843.5"
        y2="7.99998"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F7FAFE" />
        <stop offset="0.5" stop-color="#3384DC" />
        <stop offset="1" stop-color="#000078" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgComponent;
