import {
  Box,
  Button,
  Card,
  CoachTip,
  Divider,
  Flex,
  Heading,
  Inline,
  Loader,
  Stack,
  Text,
} from "@compono/ui";
import {
  QuestionMarkCircleOutlineIcon,
  TriangleDotSolidIcon,
} from "@compono/ui-icons";
import React, { useState } from "react";
import { CulturePerceptionResult } from "~/lib/federationGateway/graphql";
import BarStackChart, {
  color1,
  color2,
  color3,
  color4,
  color5,
} from "../../../components/BarStackChart/BarStackChart";
import EngagementItem from "./EngagementItem";
import { HowToDialog } from "./HowToDialog";
import { buildHoverContent, buildItems } from "./utils";

const getRating = (average: number) => {
  if (average < 1.9) return "Very Dissatisfied";
  if (average < 2.7) return "Dissatisfied";
  if (average < 3.5) return "Neutral";
  if (average < 4.3) return "Satisfied";
  return "Very Satisfied";
};

const HeadingBox = ({
  title,
  average,
  items = [
    {
      value: 1,
      color: color1,
      label: "Strongly disagree",
    },
    {
      value: 1,
      color: color2,
      label: "Disagree",
    },
    {
      value: 1,
      color: color3,
      label: "Neutral",
    },
    {
      value: 1,
      color: color4,
      label: "Agree",
    },
    {
      value: 1,
      color: color5,
      label: "Strongly agree",
    },
  ],
}: {
  title: string;
  average: number;
  items: any;
}) => {
  return (
    <>
      <Text size="lg" sx={{ textAlign: "center", width: "100%", pt: 5, mb: 5 }}>
        <b>{title}</b>
      </Text>
      <Inline alignX="center" gap="1">
        <Text size="xxl">
          <b>{average} </b>
        </Text>
        <Text size="md" sx={{ color: "black.50" }}>
          <b> / 5</b>
        </Text>
      </Inline>
      <Box sx={{ p: 5 }}>
        <Card
          sx={{
            backgroundColor: "black.5",
            width: "100%",
            p: 5,
          }}
        >
          <Stack>
            <Text size="sm" sx={{ color: "black.50", position: "absolute" }}>
              Overall
            </Text>
            <Text size="xl" sx={{ textAlign: "center", width: "100%", pb: 3 }}>
              {getRating(average)}
            </Text>

            <Box sx={{ width: "100%" }}>
              <BarStackChart
                items={items}
                markerValue={average}
              ></BarStackChart>
            </Box>
          </Stack>
        </Card>
      </Box>
    </>
  );
};

const EngagementContent = ({
  loading,
  culturePerception,
}: {
  loading: boolean;
  culturePerception: CulturePerceptionResult;
}) => {
  const [howToOpen, setHowToOpen] = useState<boolean>(false);

  if (loading) {
    return (
      <Box sx={{ margin: "20px" }}>
        <Inline alignX="center">
          <Loader size="lg" label="loading" hideLabel />
        </Inline>
      </Box>
    );
  }

  const header = `Showing ${culturePerception?.completedUsersCount} of
    ${culturePerception?.totalUsersCompleted} responses (based on filters applied)`;

  const {
    financial,
    operational,
    learningGrowth,
    customerQuality,
    jobEngagement,
    jobSatisfaction,
    turnoverIntention,
    workMotivation,
    overallClimate,
  } = culturePerception?.perceptionAverage || {};

  let companyAverage = 0;
  let atmosphereAverage = 0;
  let companyItems = undefined;
  let atmosphereItems = undefined;
  if (culturePerception?.perceptionList) {
    const {
      companySum,
      companyItems: accCompanyItems,
      atmosphereSum,
      atmosphereItems: accAtmosphereItems,
    } = culturePerception?.perceptionList?.reduce(
      (accumulator, currentValue) => {
        accumulator.companySum =
          accumulator.companySum +
          currentValue?.financial +
          currentValue?.operational +
          currentValue?.learningGrowth +
          currentValue?.customerQuality;
        accumulator.atmosphereSum =
          accumulator.atmosphereSum +
          currentValue?.jobEngagement +
          currentValue?.jobSatisfaction +
          currentValue?.turnoverIntention +
          currentValue?.workMotivation +
          currentValue?.overallClimate;

        accumulator.companyItems = buildItems(
          accumulator.companyItems,
          currentValue?.financial
        );
        accumulator.companyItems = buildItems(
          accumulator.companyItems,
          currentValue?.operational
        );
        accumulator.companyItems = buildItems(
          accumulator.companyItems,
          currentValue?.learningGrowth
        );
        accumulator.companyItems = buildItems(
          accumulator.companyItems,
          currentValue?.customerQuality
        );

        accumulator.atmosphereItems = buildItems(
          accumulator.atmosphereItems,
          currentValue?.jobEngagement
        );

        accumulator.atmosphereItems = buildItems(
          accumulator.atmosphereItems,
          currentValue?.jobSatisfaction
        );
        accumulator.atmosphereItems = buildItems(
          accumulator.atmosphereItems,
          currentValue?.turnoverIntention
        );
        accumulator.atmosphereItems = buildItems(
          accumulator.atmosphereItems,
          currentValue?.workMotivation
        );
        accumulator.atmosphereItems = buildItems(
          accumulator.atmosphereItems,
          currentValue?.overallClimate
        );
        return accumulator;
      },
      {
        companySum: 0,
        companyItems: [
          {
            value: 0,
            color: color1,
            label: "Strongly disagree",
          },
          {
            value: 0,
            color: color2,
            label: "Disagree",
          },
          {
            value: 0,
            color: color3,
            label: "Neutral",
          },
          {
            value: 0,
            color: color4,
            label: "Agree",
          },
          {
            value: 0,
            color: color5,
            label: "Strongly agree",
          },
        ],
        atmosphereSum: 0,
        atmosphereItems: [
          {
            value: 0,
            color: color1,
            label: "Strongly disagree",
          },
          {
            value: 0,
            color: color2,
            label: "Disagree",
          },
          {
            value: 0,
            color: color3,
            label: "Neutral",
          },
          {
            value: 0,
            color: color4,
            label: "Agree",
          },
          {
            value: 0,
            color: color5,
            label: "Strongly agree",
          },
        ],
      }
    );
    companyAverage =
      Math.round(
        (companySum / (4 * culturePerception?.perceptionList.length)) * 10
      ) / 10;
    atmosphereAverage =
      Math.round(
        (atmosphereSum / (5 * culturePerception?.perceptionList.length)) * 10
      ) / 10;
    companyItems = accCompanyItems.map((item) => {
      const content = buildHoverContent(
        item,
        culturePerception?.perceptionList.length * 4,
        false
      );
      return {
        ...item,
        content,
      };
    });
    atmosphereItems = accAtmosphereItems.map((item) => {
      const content = buildHoverContent(
        item,
        culturePerception?.perceptionList.length * 5,
        false
      );
      return {
        ...item,
        content,
      };
    });
  }

  return (
    <>
      <Box
        sx={{
          ml: "10px",
          mr: "10px",
        }}
      >
        <Card border sx={{ m: 3, p: 0, maxWidth: "1440px" }}>
          <Box sx={{ m: 5 }}>
            <Inline alignX="apart">
              <Stack gap={1}>
                <Heading level="5">Overview</Heading>
                <Text size="sm">{header}</Text>
              </Stack>
              <Button
                look="naked"
                iconLeft={QuestionMarkCircleOutlineIcon}
                onClick={() => {
                  setHowToOpen(true);
                }}
              >
                How does this work?
              </Button>
            </Inline>
          </Box>
          <Stack>
            <Divider orientation={"horizontal"} />
            <Box sx={{ pt: 5, pb: 5, minHeight: "55px" }}>
              <Box sx={{ ml: 5, position: "absolute" }}>
                <Inline>
                  <TriangleDotSolidIcon />
                  <Text>Average score</Text>
                </Inline>
              </Box>
              {/* <Inline alignX="center">
                    <Box>
                      <ButtonGroup
                        rounded
                        defaultSelected="default-button"
                        name="rounded-group"
                      >
                        <Button id="default-button">Distribution</Button>
                        <Button>Radar chart</Button>
                      </ButtonGroup>
                    </Box>
                  </Inline> */}
            </Box>
            <Divider orientation={"horizontal"} />
            <Flex>
              <Stack
                gap={2}
                sx={{
                  borderRight: "1px solid",
                  borderColor: "black.10",
                }}
              >
                <HeadingBox
                  title={"Company performance"}
                  average={companyAverage}
                  items={companyItems}
                />
                <Box
                  sx={{
                    height: "100%",
                    padding: "20px",
                  }}
                >
                  <Divider orientation={"horizontal"} />
                  <Stack gap={4}>
                    <EngagementItem
                      title={`Financial`}
                      description="Our company is performing well financially."
                      distribution={culturePerception?.perceptionList.map(
                        (perception) => perception.financial
                      )}
                      average={financial}
                    />
                    <Divider orientation={"horizontal"} />
                    <EngagementItem
                      title={`Operational`}
                      description="Our company runs effectively and efficiently."
                      distribution={culturePerception?.perceptionList.map(
                        (perception) => perception.operational
                      )}
                      average={operational}
                    />
                    <Divider orientation={"horizontal"} />
                    <EngagementItem
                      title={`Learning / Growth`}
                      description="Our company learns and improves from past
                            experiences."
                      distribution={culturePerception?.perceptionList.map(
                        (perception) => perception.learningGrowth
                      )}
                      average={learningGrowth}
                    />
                    <Divider orientation={"horizontal"} />
                    <EngagementItem
                      title={`Customer Quality`}
                      description="Our company produces quality products or services
                            that meet customer expectations."
                      distribution={culturePerception?.perceptionList.map(
                        (perception) => perception.customerQuality
                      )}
                      average={customerQuality}
                    />
                  </Stack>
                </Box>
              </Stack>
              <Stack gap={2}>
                {jobEngagement ? (
                  <>
                    <HeadingBox
                      title={"Work atmosphere"}
                      average={atmosphereAverage}
                      items={atmosphereItems}
                    />

                    <Card sx={{ padding: "20px" }}>
                      <Divider orientation={"horizontal"} />
                      <Stack gap={4}>
                        <EngagementItem
                          title={`Job Engagement`}
                          description="I am engaged in my job."
                          distribution={culturePerception?.perceptionList.map(
                            (perception) => perception.jobEngagement
                          )}
                          average={jobEngagement}
                        />
                        <Divider orientation={"horizontal"} />
                        <EngagementItem
                          title={`Job Satisfaction`}
                          description="I am satisfied with the tasks and responsibilities of my job."
                          distribution={culturePerception?.perceptionList.map(
                            (perception) => perception.jobSatisfaction
                          )}
                          average={jobSatisfaction}
                        />
                        <Divider orientation={"horizontal"} />
                        <EngagementItem
                          title={`Turnover Intention`}
                          description="I am unlikely to leave the company in the next year."
                          distribution={culturePerception?.perceptionList.map(
                            (perception) => perception.turnoverIntention
                          )}
                          average={turnoverIntention}
                        />
                        <Divider orientation={"horizontal"} />
                        <EngagementItem
                          title={`Work Motivation`}
                          description="I am motivated to achieve work goals and objectives."
                          distribution={culturePerception?.perceptionList.map(
                            (perception) => perception.workMotivation
                          )}
                          average={workMotivation}
                        />
                        <Divider orientation={"horizontal"} />
                        <EngagementItem
                          title={`Overall Climate`}
                          description="My work environment is positive and supportive."
                          distribution={culturePerception?.perceptionList.map(
                            (perception) => perception.overallClimate
                          )}
                          average={overallClimate}
                        />
                      </Stack>
                    </Card>
                  </>
                ) : (
                  <Box sx={{ pl: 5, pr: 5 }}>
                    <Text
                      size="lg"
                      sx={{
                        textAlign: "center",
                        width: "100%",
                        pt: 5,
                        pb: 5,
                      }}
                    >
                      <b>Work atmosphere</b>
                    </Text>
                    <CoachTip
                      look="warning"
                      title="Not enough responses to show"
                      sx={{ mt: 9 }}
                    >
                      <Text>
                        Work atmosphere results are sensitive. Results will only
                        be displayed for groups of 3 or more responses.
                      </Text>
                    </CoachTip>
                  </Box>
                )}
              </Stack>
            </Flex>
          </Stack>
        </Card>
      </Box>

      <HowToDialog
        howToOpen={howToOpen}
        setHowToOpen={setHowToOpen}
      ></HowToDialog>
    </>
  );
};
export default EngagementContent;
