import {
  Box,
  Button,
  Card,
  Disclosure,
  DisclosureContent,
  DisclosureRenderProp,
  Divider,
  Heading,
  Inline,
  Loader,
  Stack,
} from "@compono/ui";
import {
  ChevronDownOutlineIcon,
  ChevronRightOutlineIcon,
  QuestionMarkCircleOutlineIcon,
} from "@compono/ui-icons";
import React, { useState } from "react";
import ColorGradient from "./color-gradient";

import { observations } from "@compono/assessments-kit";
import NotFoundImage from "../../../assets/images/not-found-image";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import { TreeLines } from "../components/TreeLines";
import { DimensionExplainerDialog } from "./DimensionExplainerDialog";
import { HowToDialog } from "./HowToDialog";
import { InsightsDialog } from "./InsightsDialog";
import { BoxRow, GreyBox, VerticalHeading } from "./SpareParts";
import { getName, mapDimensions, transformDimensions } from "./utils";

const getFilterLabel = (filterType) => {
  switch (filterType) {
    case "divisions":
      return "Division";
    case "officeLocation":
      return "Location";
    case "jobLevel":
      return "Job Level";

    default:
      break;
  }
};
export const Heatmap = ({
  orgData,
  aggregates,
  loading = false,
  showValues,
  filterType,
  currentResponses,
  desiredResponses,
  setDialogContent,
  dialogHeader,
  dialogOpen,
  setDialogOpen,
}) => {
  const [howToOpen, setHowToOpen] = useState(false);
  const [dimensionOpen, setDimensionOpen] = useState(false);
  const [dimension, setDimension] = useState("driver");
  const [explainerType, setExplainerType] = useState("formalised|informal");

  const openExplainer = (type: string) => {
    setExplainerType(type);
    setDimensionOpen(true);
  };

  return (
    <Box sx={{ background: "var(--colors-black-2)" }}>
      {loading ? (
        <Card sx={{ p: 0 }}>
          <Inline alignX="center">
            <Loader label={"Loading"} size="lg"></Loader>
          </Inline>
        </Card>
      ) : orgData ? (
        <Card border sx={{ m: "20px", p: 0, maxWidth: "1440px" }}>
          <Box sx={{ m: 5 }}>
            <Inline alignX="apart">
              <Heading level="5">Overview</Heading>
              <Button
                look="naked"
                iconLeft={QuestionMarkCircleOutlineIcon}
                onClick={() => {
                  setHowToOpen(true);
                }}
              >
                How does this work?
              </Button>
            </Inline>
          </Box>
          <Divider orientation={"horizontal"} />
          <Inline alignX="center">
            <Stack
              sx={{
                alignItems: "center",
                marginBottom: "16px",
                mt: 5,
                maxWidth: "1175px",
              }}
              minWidth="1000px"
            >
              <Inline>
                <GreyBox sx={{ height: "410px", width: "500px" }}></GreyBox>
                <Box>
                  <GreyBox sx={{ height: "50px" }}>
                    <Inline alignX="center">
                      <strong>Current vs desired culture</strong>
                    </Inline>
                  </GreyBox>
                  <Inline>
                    <VerticalHeading
                      type="innovative|cautious"
                      onClick={openExplainer}
                    ></VerticalHeading>
                    <VerticalHeading
                      type="process|outcome"
                      onClick={openExplainer}
                    ></VerticalHeading>
                    <VerticalHeading
                      type="task centred|employee centred"
                      onClick={openExplainer}
                    ></VerticalHeading>
                    <VerticalHeading
                      type="collective|individual"
                      onClick={openExplainer}
                    ></VerticalHeading>
                    <VerticalHeading
                      type="centralised control|delegated control"
                      onClick={openExplainer}
                    ></VerticalHeading>
                    <VerticalHeading
                      type="hierarchical structure|horizontal structure"
                      onClick={openExplainer}
                    ></VerticalHeading>
                    <VerticalHeading
                      type="ad-hoc|planned"
                      onClick={openExplainer}
                    ></VerticalHeading>
                    <VerticalHeading
                      type="conformity|individuality"
                      onClick={openExplainer}
                    ></VerticalHeading>
                    <VerticalHeading
                      type="accepting|questioning"
                      onClick={openExplainer}
                    ></VerticalHeading>
                    <VerticalHeading
                      type="cooperation|competition"
                      onClick={openExplainer}
                    ></VerticalHeading>
                    <VerticalHeading
                      type="external|internal"
                      onClick={openExplainer}
                    ></VerticalHeading>
                    <VerticalHeading
                      type="formalised|informal"
                      onClick={openExplainer}
                    ></VerticalHeading>
                  </Inline>
                </Box>
              </Inline>
              <Inline>
                <GreyBox
                  sx={{ width: "500px", height: "50px", pr: 5 }}
                  shade="20"
                >
                  <Inline gap="5" alignX="right">
                    <strong>
                      Whole organisation ({orgData.responseCount})
                    </strong>
                  </Inline>
                </GreyBox>
                <BoxRow
                  colors={transformDimensions(orgData.dimensions)}
                  numbers={orgData.dimensions}
                  onClick={(index: number) => {
                    setDimension(mapDimensions(index));
                    setDialogContent("", "");
                  }}
                  showValues={showValues}
                ></BoxRow>
              </Inline>
              {aggregates.length > 0 && (
                <>
                  <Inline>
                    <GreyBox sx={{ width: "500px", height: "50px", pr: 5 }}>
                      <Inline gap="5" alignX="right">
                        <strong>By {getFilterLabel(filterType)}</strong>
                      </Inline>
                    </GreyBox>
                    <GreyBox sx={{ width: "622px", height: "50px" }}></GreyBox>
                  </Inline>
                  {aggregates.map((aggregate, index) => {
                    const isParent =
                      index != aggregates.length - 1 &&
                      aggregates[index + 1].hasParent;
                    if (!aggregate.hasParent)
                      return (
                        <Disclosure>
                          <DisclosureRenderProp>
                            {({ isOpen, toggleIsOpen, id }) => (
                              <>
                                <Inline>
                                  <Box sx={{ ml: 0 }}>
                                    <Inline>
                                      <GreyBox
                                        sx={{
                                          width: "500px",
                                          height: "50px",
                                          pr: 5,
                                          pl: 2,
                                        }}
                                        shade={"10"}
                                      >
                                        <Inline
                                          gap="5"
                                          alignX="right"
                                          onClick={toggleIsOpen}
                                        >
                                          {isParent && (
                                            <Box
                                              sx={{
                                                width: "10px",
                                                cursor: "pointer",
                                                pt: "4px",
                                              }}
                                            >
                                              {isOpen ? (
                                                <ChevronDownOutlineIcon />
                                              ) : (
                                                <ChevronRightOutlineIcon />
                                              )}
                                            </Box>
                                          )}
                                          <Box
                                            sx={{
                                              maxWidth: "450px",
                                            }}
                                          >
                                            {getName(aggregate)} (
                                            {aggregate.responseCount}){" "}
                                          </Box>
                                        </Inline>
                                      </GreyBox>
                                    </Inline>
                                  </Box>
                                  <BoxRow
                                    colors={transformDimensions(
                                      aggregate.dimensions
                                    )}
                                    onClick={(index) => {
                                      setDimension(mapDimensions(index));
                                      setDialogContent(
                                        aggregate.aggregation,
                                        aggregate.aggregateFilter
                                      );
                                    }}
                                    showValues={showValues}
                                  ></BoxRow>
                                </Inline>
                                {isParent && (
                                  <ChildRows
                                    aggregates={aggregates}
                                    startIndex={index + 1}
                                    showValues={showValues}
                                    setDimension={setDimension}
                                    setDialogContent={setDialogContent}
                                  ></ChildRows>
                                )}
                              </>
                            )}
                          </DisclosureRenderProp>
                        </Disclosure>
                      );
                  })}
                </>
              )}
              <Box sx={{ mt: 8 }}>
                <GreyBox sx={{ width: "1120px" }}>
                  <Box sx={{ pl: 2, pb: 2 }}>
                    <strong>
                      Alignment between desired culture and current culture
                    </strong>
                  </Box>
                  <ColorGradient width={1100} />
                  <Inline alignX="apart">
                    <Box sx={{ pl: 2, pb: 2 }}>Aligned</Box>
                    <Box sx={{ pr: 2, pb: 2 }}>Misaligned</Box>
                  </Inline>
                </GreyBox>
              </Box>
            </Stack>
          </Inline>
        </Card>
      ) : (
        <Card sx={{ p: 0 }}>
          <ErrorMessage
            title="Nothing to see here yet!"
            icon={<NotFoundImage />}
            description="Looks like there is no survey data for this campaign."
          />
        </Card>
      )}

      {
        <InsightsDialog
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          dialogHeader={dialogHeader}
          currentResponses={currentResponses}
          desiredResponses={desiredResponses}
          dimension={dimension}
          observations={observations}
        ></InsightsDialog>
      }
      <HowToDialog
        howToOpen={howToOpen}
        setHowToOpen={setHowToOpen}
      ></HowToDialog>
      <DimensionExplainerDialog
        dimensionOpen={dimensionOpen}
        setDimensionOpen={setDimensionOpen}
        type={explainerType}
      ></DimensionExplainerDialog>
    </Box>
  );
};

const ChildRows = ({
  aggregates,
  startIndex,
  showValues,
  setDimension,
  setDialogContent,
}) => {
  const childrenAggregates = [];
  let i = startIndex;
  while (aggregates[i]?.hasParent) {
    childrenAggregates.push(aggregates[i]);
    i++;
  }
  return (
    <DisclosureContent>
      {childrenAggregates.map((aggregate, index) => {
        return (
          <Inline>
            <Box sx={{ ml: 7 }}>
              <Inline>
                <TreeLines
                  height={50}
                  isLast={index == childrenAggregates.length - 1}
                ></TreeLines>
                <GreyBox
                  sx={{
                    width: "468px",
                    height: "50px",
                    pr: 5,
                  }}
                  shade={"5"}
                >
                  <Inline alignX="right">
                    <Box>
                      {getName(aggregate)} ({aggregate.responseCount})
                    </Box>
                  </Inline>
                </GreyBox>
              </Inline>
            </Box>
            <BoxRow
              colors={transformDimensions(aggregate.dimensions)}
              onClick={(index) => {
                setDimension(mapDimensions(index));
                setDialogContent(
                  aggregate.aggregation,
                  aggregate.aggregateFilter
                );
              }}
              showValues={showValues}
            ></BoxRow>
          </Inline>
        );
      })}
    </DisclosureContent>
  );
};
