/** @jsxImportSource @emotion/react */
import React, { FC } from "react";

import { calcPartialTopLine, calcPartialBottomLine } from "./utils";
import { Box } from "@compono/ui";
// import { NodeHistory } from "./TreeNodes";
// import { useTreeCardContext } from "./TreeProvider";

export type TreeLinesProps = {
  height: number;
  isLast: boolean;
};

export const TreeLines: FC<TreeLinesProps> = (props) => {
  const { height: cardHeight, isLast } = props;

  const cardPadding = 6;
  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          height: `${cardHeight}px`,
          marginLeft: `var(--line-box-width)`,
        }}
      >
        <>
          <Box
            data-node-line-top
            sx={{
              "&::before": {
                content: '""',
                position: "absolute",
                borderLeft: "2px solid",
                borderBottom: "2px solid",
                borderColor: "black.20",
                ...calcPartialTopLine(isLast),
              },
              flex: 1,
            }}
          />
          {!isLast && (
            <Box
              data-node-line-bottom={isLast}
              sx={{
                "&::before": {
                  content: '""',
                  position: "absolute",
                  borderLeft: "2px solid",
                  borderColor: "black.20",
                  ...calcPartialBottomLine(cardHeight, cardPadding),
                },
                flex: 1,
              }}
            />
          )}
        </>
      </Box>
    </>
  );
};

TreeLines.displayName = "TreeLines";
