import React, { FC, ReactElement } from "react";
import { GetCampaigns } from "./queries";
import {
  SelectableOptionT,
  SelectLegacyDropdownOptionsProps,
  UIProvider,
} from "@compono/ui";
import { ThemeProvider } from "@shortlyster/ui-kit";
import { render, RenderOptions } from "@testing-library/react";
import { MockedProvider } from "@apollo/client/testing";
import { BrowserRouter } from "react-router-dom";
import _ from "lodash";

import { AlertsProvider } from "../../../hooks/useAlerts";
import { themeOverrides } from "../../../theme-overrides";
import { ChevronRightSolidIcon } from "@compono/ui-icons";
import { CampaignPurposeEnum, Status } from "../../../types";

export const matchUrl = "/insights";

export const noop = () => {
  // this should be empty
};

// Dynamic icons based on option properties
export const getOptionDecor = (option: SelectableOptionT) => {
  let decor: SelectLegacyDropdownOptionsProps["decor"];
  if (option.hasChildOptions) {
    decor = <ChevronRightSolidIcon />;
  }
  return { decor };
};

// Validate culture result and return if it has result
export const hasResultValue = (obj?: { [key: string]: any }): boolean => {
  if (!obj) return false;
  return _.values(obj).filter((val) => !!val).length > 0;
};

export const campaigns = [
  {
    sk: "CAM$5a61d4d0-425b-4a8e-b913-2431738a2c68",
    name: "CampaignTestOne",
    dueDate: "2022-08-09T12:00:00.000Z",
    createdDate: "2021-12-15T00:00:00.000Z",
    status: Status.Closed,
    completionRate: "100",
    lastSaved: "2021-12-15T00:00:00.000Z",
    responses: "2",
    publishDate: "2022-04-09T00:00:00.000Z",
    purpose: CampaignPurposeEnum.UNDERSTAND,
    milestones: [],
    respondents: ["$USR1", "$USR2"],
    filters: {
      tenure: ["Under 1 year", "1-2 years", "2-4 years", "5-10 years"],
      jobTitle: [
        "Fire Detective Captain",
        "Firefighter",
        "Captain",
        "Firefighter EMT",
        "Fire Coordinator Lead",
        "Payroll Officer",
      ],
      jobLevel: ["Senior Manager", "Team Leader", "Team Member"],
      officeLocation: ["Sydney", "Melbourne", "Brisbane", "Adelaide"],
      divisions: {
        fireMarshallDept: {
          label: "Fire Marshall Dept",
          children: {
            fireInvestigation: {
              label: "Fire Investigation",
              children: {},
            },
            firePrevention: {
              label: "Fire Prevention",
              children: {},
            },
            fireResponse: {
              label: "Fire Response",
              children: {
                operations: {
                  label: "Operations",
                  children: {},
                },
                ems: {
                  label: "EMS",
                  children: {},
                },
              },
            },
          },
        },
        homelandSecurity: {
          label: "Homeland Security",
          children: {
            support: {
              label: "Support",
              children: {
                communications: {
                  label: "Communications",
                  children: {},
                },
                training: {
                  label: "Training",
                  children: {},
                },
              },
            },
          },
        },
      },
    },
    assessments: [
      {
        type: "current",
        sk: "ASM$CUR1",
      },
    ],
    timezone: "",
    steps: {
      setup: "Done",
      closingDate: "Done",
      messages: "Done",
      invitation: "Done",
      note: "Done",
      respondents: "Done",
      milestones: "Done",
    },
    closedDate: "",
  },
  {
    sk: "CAM$f6341164-c438-49bc-943c-57d570c66b1e",
    name: "CampaignTestTwo",
    dueDate: "2022-11-28T00:00:00.000Z",
    createdDate: "2021-12-15T00:00:00.000Z",
    status: Status.Closed,
    completionRate: "25",
    lastSaved: "2021-12-15T00:00:00.000Z",
    responses: "1",
    publishDate: "2022-04-09T00:00:00.000Z",
    milestones: [],
    respondents: ["USR$1", "USR$2", "USR$3", "USR$4"],
    purpose: CampaignPurposeEnum.UNDERSTAND,
    filters: {
      tenure: [],
      jobTitle: [],
      jobLevel: [],
      officeLocation: [],
      divisions: {},
    },
    assessments: [
      {
        type: "current",
        sk: "ASM$CUR2",
      },
      {
        type: "desired",
        sk: "ASM$DES1",
      },
    ],
    timezone: "",
    steps: {
      setup: "Done",
      closingDate: "Done",
      messages: "Done",
      invitation: "Done",
      note: "Done",
      respondents: "Done",
      milestones: "Done",
    },
    closedDate: "",
  },
];

export const mocks = [
  {
    request: {
      query: GetCampaigns,
      variables: {
        status: Status.Closed,
      },
    },
    result: {
      data: {
        campaigns: campaigns,
      },
    },
  },
];

export const AllTheProviders: FC = ({ children }) => {
  return (
    <UIProvider>
      <ThemeProvider theme={themeOverrides}>
        <MockedProvider mocks={mocks} addTypename={false}>
          <BrowserRouter>
            <AlertsProvider>{children}</AlertsProvider>
          </BrowserRouter>
        </MockedProvider>
      </ThemeProvider>
    </UIProvider>
  );
};

export const customRender = (
  ui: ReactElement,
  options?: Omit<RenderOptions, "wrapper">
) => render(ui, { wrapper: AllTheProviders, ...options });
