import { Location } from "history";
import { useRouteMatch } from "react-router";
import useEnsureOrgIdPath from "./useEnsureOrgIdPath";

export const usePath = () => {
  const { path: parentPath } = useRouteMatch();
  const prefixPath = useEnsureOrgIdPath();

  // prefixed path
  const prefixedPath = (path = "") => `${prefixPath}${path ?? ""}`;

  // prefixed parent path
  const path = (path = "") => `${prefixPath}${parentPath}${path ?? ""}`;

  /**
   * @description This function returns the string that navigates to the sibling url
   * @ref https://v5.reactrouter.com/web/api/Link/to-func
   * */
  const navigateToSibling = (location: Location, siblingPath: string) => {
    const pathname = location.pathname;
    const splitPathname = pathname.split("/");
    splitPathname.pop();
    const abovePath = splitPathname.join("/");

    return abovePath + siblingPath;
  };

  return { prefixedPath, path, navigateToSibling };
};
