import { Stack, Box } from "@compono/ui";
import { calculatePercentage } from "../../../components/BarStackChart/utils";

export const buildHoverContent = (
  item: { value: any; color?: string; label: any },
  total: number,
  includeResponses = true
) => {
  return (
    <Stack>
      <Box>
        {Math.round(calculatePercentage(item.value, total) * 10) / 10}%{" "}
        {item.label.toLowerCase()}
      </Box>
      {includeResponses && (
        <Box>
          {item.value} {item.value > 1 ? "responses" : "response"}
        </Box>
      )}
    </Stack>
  );
};

export const buildItems = (
  items: { value: number; color: string; label: string }[],
  currentValue: never
) => {
  const before = items[currentValue - 1].value;
  items[currentValue - 1].value = before + 1;
  return items;
};
