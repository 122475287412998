import React from "react";
import { Box, Text } from "@compono/ui";
import { TriangleDotSolidIcon } from "@compono/ui-icons";
import styled from "@emotion/styled";

import { InfoTipItemProps } from "./types";
import {
  circleStyles,
  circleStylesWrapper,
  highlightedHeadingStyle,
  normalHeadingStyle,
  sentenceTextStyle,
} from "./styles";
import { formatDateFromDate } from "../../lib/formattingHelpers";
import { getInteger } from "../../shared/helpers";
import PlotGraph from "../PlotGraph";
import { InsightsCampaignType } from "../../features/Culture/Explorer/types";

const Circle = styled(Box)`
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;
const CultureSentence = ({ label }: { label: InsightsCampaignType }) => {
  return (
    <>
      <span style={circleStylesWrapper}>
        <Circle sx={circleStyles[label as "A" | "B"]} />
      </span>
      <span>
        {label === InsightsCampaignType.A ? "current" : "desired"} culture is{" "}
      </span>
    </>
  );
};
const CultureInfoTip = ({
  primaryHeading,
  secondaryHeading,
  cultureResult,
  cultureResults,
  label,
  selectedRefCampaignDueDate,
  color = "primary",
  showPlotGraph = true,
}: InfoTipItemProps) => {
  const primaryHeadingValue = getInteger(cultureResult);
  const secondaryHeadingValue = Math.abs(100 - primaryHeadingValue);
  const primaryHeadingSentence = `${primaryHeadingValue}% ${primaryHeading}`;
  const secondaryHeadingSentence = `${secondaryHeadingValue}% ${secondaryHeading}`;

  return (
    <>
      {showPlotGraph && (
        <PlotGraph
          average={cultureResult}
          results={cultureResults}
          circleFillColor={color}
          circleStrokeColor={color}
          violinPlotColor={color}
          showViolinPlot={
            typeof cultureResults?.length === "number" &&
            cultureResults.length >= 5
          }
        />
      )}
      <Text sx={{ fontSize: "sm", px: 4 }}>
        <span style={sentenceTextStyle}>The</span>
        {label !== InsightsCampaignType.Ref ? (
          <CultureSentence label={label} />
        ) : (
          selectedRefCampaignDueDate && (
            <>
              <span style={circleStylesWrapper}>
                <Text sx={circleStyles.Ref} component="span">
                  <TriangleDotSolidIcon />
                </Text>
              </span>
              <span style={sentenceTextStyle}>
                culture at{" "}
                {formatDateFromDate(new Date(selectedRefCampaignDueDate))} is
              </span>
            </>
          )
        )}
        <span
          style={
            primaryHeadingValue > secondaryHeadingValue
              ? highlightedHeadingStyle
              : normalHeadingStyle
          }
        >
          {" "}
          {primaryHeadingSentence}
        </span>
        <span style={normalHeadingStyle}>{" and "}</span>
        <span
          style={
            secondaryHeadingValue > primaryHeadingValue
              ? highlightedHeadingStyle
              : normalHeadingStyle
          }
        >
          {secondaryHeadingSentence}.
        </span>
      </Text>
    </>
  );
};

export default CultureInfoTip;
