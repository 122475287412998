export enum Status {
  Draft = "Draft",
  Active = "Active",
  Closed = "Closed",
}

export enum StepsStatusEnum {
  TODO = "TODO",
  Done = "Done",
  Warning = "Warning",
}

export enum ResponseStatus {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Complete = "Complete",
}

export enum AssessmentType {
  Current = "current",
  Desired = "desired",
  Personality = "personality",
  Feedback = "feedback",
  Perception = "perception",
}

export enum CampaignPurposeEnum {
  HIRING = "Hiring",
  UNDERSTAND = "Understand/Change",
}

export enum FeedbackType {
  YOUR_FEEDBACK = "Your feedback",
  HOW_ARE_WE_DOING = "How are we doing",
}

export type CampaignPurpose = "Hiring" | "Understand/Change";

export type StepsStatus = "TODO" | "Done" | "Warning";
export interface Campaign {
  pk?: string;
  sk?: string;
  name?: string;
  dueDate?: Date | string;
  createdDate?: Date | string;
  publishDate?: Date | string;
  emailText?: string;
  assessments: Assessment[];
  status: Status;
  allRespondents?: boolean;
  excludeEmployees?: string[];
  respondents?: string[];
  lastSaved?: Date | string;
  completionRate?: string;
  recipients?: string;
  responses?: string;
  note?: string;
  purpose?: CampaignPurposeEnum;
  timezone: string;
  steps: Steps;
  milestones: Milestone[];
  closedDate: string;
  filters?: Filters;
  selectedAssessments?: AssessmentType[];
  superCharged?: boolean;
  hasPlaceholders?: boolean;
  __typename?: string;
}

export interface CampaignResponse {
  pk: string;
  sk: string;
  status: ResponseStatus;
  campaignSk: string;
  campaignName: string;
  campaignDueDate: string;
  userId: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  lastSaved: string;
  demographics: Demographics;
}

export interface Demographics {
  divisions: string;
  tenure: string;
  team: string[];
  jobTitle: string;
  jobLevel: string;
}

export interface Steps {
  setup: string;
  closingDate: string;
  messages: string;
  respondents: string;
  invitation: string;
  note: string;
  milestones: string;
}
export type StepsEnum =
  | "setup"
  | "closingDate"
  | "respondents"
  | "invitation"
  | "note"
  | "publish"
  | "messages"
  | "milestones";

export type ResponseStepsEnum =
  | "start"
  | "demographics"
  | "current"
  | "desired"
  | "finish";

export interface Assessment {
  sk?: string;
  type: AssessmentType;
  intro?: string;
  assessmentResponse?: AssessmentResponse[];
}

export interface AssessmentResponse {
  sk: string;
  assessmentSk: string;
  campaignResponseSk: string;
  values: AssessmentResponseValues;
  status: ResponseStatus;
  startDate?: string;
  endDate?: string;
}

export interface AssessmentResponseValues {
  riskTolerance: number;
  driver: number;
  focus: number;
  teamWork: number;
  control: number;
  structure: number;
  responsivePlanned: number;
  conformity: number;
  accepting: number;
  cooperation: number;
  internalExternal: number;
  formal: number;
}

// TODO: Move this to src/components/types.d.ts
export interface IconProps {
  color?: string;
  style?: any;
}

export interface MissingStepsProps {
  steps: (string | undefined)[];
}

export type Response = {
  permissions: {
    permissions: string[];
    orgId: string;
    name: string;
  };
};

export type Milestone = {
  description: string;
  startDate: string;
  endDate: string | null;
};

export enum SectionsEnum {
  SETUP = "setup",
  CLOSING_DATE = "closingDate",
  MESSAGES = "messages",
  INVITATION = "invitation",
  NOTE = "note",
  RESPONDENTS = "respondents",
  PUBLISH = "publish",
  MILESTONES = "milestones",
}
export type Sections =
  | "setup"
  | "invitation"
  | "closingDate"
  | "respondents"
  | "publish"
  | "milestones"
  | "messages"
  | "note";

export enum MessageTypeEnum {
  CLOSING_MESSAGE = "closingMessage",
  INTRODUCTION = "introduction",
}

export interface Filters {
  tenure: string[];
  jobTitle: string[];
  jobLevel: string[];
  officeLocation: string[];
  team?: string[];
  divisions: any;
}
