import React, { useContext, useRef, useState } from "react";
import {
  Stack,
  Text,
  Box,
  Button,
  IconBox,
  Divider,
  DisclosureContent,
  Inline,
} from "@compono/ui";
import {
  ChevronCircleDownSolidIcon,
  ChevronCircleUpSolidIcon,
  DotSolidIcon,
  LightbulbSolidIcon,
} from "@compono/ui-icons";
import {
  bulletPointStyle,
  observeContainerStyle,
  observeHeaderContainerStyle,
} from "../../Explorer/styles";
import {
  PercentSentence,
  ShiftSentence,
  getEndOfSentence,
  getPrimaryHeadingDiffInPercentage,
  getSecondaryHeadingDiffInPercentage,
} from "./utils";
import { DisclosureObservations } from "../../Explorer/DisclosureObservations";
import { desiredCultureIconStyle, legendElementStyle } from "./styles";
import { sentenceTextStyle } from "../../../../components/CultureInfoTip/styles";
import { primaryHeadingStyle } from "../../../../components/CultureReportInfoTip/styles";
import { tipIconContainerSx } from "../../../../components/ObservationLearnMoreDialog/styles";
import { formatDateFromDate } from "../../../../lib/formattingHelpers";
import { difference } from "lodash";
import { element } from "prop-types";
import { CULTURE_FIT_QUESTIONS } from "@compono/assessments-kit";

const CultureInsights = ({
  isOpen,
  toggleIsOpen,
  value,
  valueB,
  reference,
  left,
  right,
  observation,
  observationB,
  selectedRefCampaignDueDate,
}) => {
  const [leftHeading, leftText] = left;
  const [rightHeading, rightText] = right;

  const primaryHeadingDiffInPercentage = getPrimaryHeadingDiffInPercentage(
    value,
    valueB
  );

  const secondaryHeadingDiffInPercentage = getSecondaryHeadingDiffInPercentage(
    value,
    valueB
  );
  let primaryDiffARef;
  let secondaryDiffARef;
  if (reference) {
    primaryDiffARef = getPrimaryHeadingDiffInPercentage(value, reference);
    secondaryDiffARef = getSecondaryHeadingDiffInPercentage(value, reference);
  }

  const difference =
    primaryHeadingDiffInPercentage > 0
      ? primaryHeadingDiffInPercentage
      : secondaryHeadingDiffInPercentage;

  return (
    <>
      <Divider orientation={"horizontal"} />
      <Box sx={{ m: 5 }}>
        <Stack flat gap="4">
          <Box sx={observeContainerStyle}>
            <Box sx={{ ...legendElementStyle, ml: -3 }}>
              <DotSolidIcon color="var(--colors-brandPurple-80)" />
              <Text>Current culture</Text>
            </Box>
            <Text font="title" sx={observeHeaderContainerStyle}>
              {observation?.heading}
            </Text>
            <PercentSentence
              primaryHeading={leftHeading}
              secondaryHeading={rightHeading}
              cultureResult={value}
            />
          </Box>
          <Box sx={observeContainerStyle}>
            <Box sx={{ ...legendElementStyle, ml: -3 }}>
              <Box sx={desiredCultureIconStyle} />
              <Text>Desired culture</Text>
            </Box>
            <Text font="title" sx={observeHeaderContainerStyle}>
              {observationB?.heading}
            </Text>

            <PercentSentence
              primaryHeading={leftHeading}
              secondaryHeading={rightHeading}
              cultureResult={valueB}
            />
          </Box>
        </Stack>
        <Divider orientation={"horizontal"} />
        <Text as="p" sx={{ m: 5 }}>
          {difference == 0 ? (
            <span>
              There is strong alignment between current and desired culture for
              this dimension.
            </span>
          ) : (
            <>
              <span>Employees desire a </span>
              <span style={primaryHeadingStyle}>
                {getEndOfSentence(
                  difference,
                  primaryHeadingDiffInPercentage > 0
                    ? rightHeading
                    : leftHeading,
                  "no-ref"
                )}
              </span>
              <span> culture.</span>
            </>
          )}
          {reference >= 1 && (
            <>
              <span style={sentenceTextStyle}>
                {" "}
                The reference culture at {selectedRefCampaignDueDate} is
                <PercentSentence
                  primaryHeading={leftHeading}
                  secondaryHeading={rightHeading}
                  cultureResult={reference}
                />
              </span>
              <ShiftSentence
                primaryHeadingDiffInPercentage={primaryDiffARef}
                secondaryHeadingDiffInPercentage={secondaryDiffARef}
                primaryHeading={leftHeading}
                secondaryHeading={rightHeading}
                sentence=" In this period there has been a"
                type="ref"
              />
            </>
          )}
        </Text>
        <DisclosureContent>
          <Divider orientation={"horizontal"} />
          <Box sx={{ ml: 5, mr: 5, mb: 5 }}>
            <Stack flat gap="4">
              <DisclosureObservations
                observation={observation}
                observationB={observationB}
              />
            </Stack>
            <Box sx={{ mt: 7 }}>
              <Text font="title" sx={{ mb: 5 }}>
                {observation.tipsTitle}
              </Text>
              <Stack gap={4}>
                {observation?.tips?.map((point: string, index: number) => (
                  <Box sx={bulletPointStyle} key={index}>
                    <Box sx={tipIconContainerSx}>
                      <LightbulbSolidIcon />
                    </Box>
                    <Text sx={{ flex: 1, alignSelf: "center" }}>{point}</Text>
                  </Box>
                ))}
              </Stack>
            </Box>
          </Box>
        </DisclosureContent>
        <Inline alignX="center">
          <span onClick={toggleIsOpen}>
            {isOpen ? (
              <Button look="link">
                Read less{" "}
                <IconBox
                  sx={{ marginTop: "-1px" }}
                  icon={ChevronCircleUpSolidIcon}
                />
              </Button>
            ) : (
              <Button look="link">
                Read more{" "}
                <IconBox
                  sx={{ marginTop: "-1px" }}
                  icon={ChevronCircleDownSolidIcon}
                />
              </Button>
            )}
          </span>
        </Inline>
      </Box>
    </>
  );
};

export default CultureInsights;
