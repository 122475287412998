import {
  Heading,
  Box,
  Button,
  Dialog,
  DialogHeading,
  DialogBody,
  DialogPrimaryActions,
  DialogCloseButton,
} from "@compono/ui";
import React from "react";
import HotspotExampleSVG from "./assets/images/example-hotspot.tsx";

export const HowToDialog = ({ howToOpen, setHowToOpen }) => (
  <Dialog
    isOpen={howToOpen}
    aria-describedby="dialog-description"
    aria-labelledby="dialog-title"
  >
    <DialogHeading>How does this work?</DialogHeading>
    <DialogBody>
      <Heading level="6">Alignment</Heading>
      <p>
        The hotspots chart lets you see how <b>aligned</b> your organisation is
        between current and desired culture, across all 12 dimensions of
        culture.
      </p>
      <p>
        The <b>darker</b> the shade of blue in a spot, the more misalignment
        there is. Conversely, the <b>lighter</b> the shade of blue in a spot,
        the stronger the alignment.
      </p>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <HotspotExampleSVG />
      </Box>
      <p>
        In the example above, the <b>darkest blue</b> spot within the product
        division is the <i>‘Process Driven vs. Outcomes Driven’</i> dimension.
        This means there is a stronger misalignment between the current and
        desired culture in this dimension than others, where the spots are a
        lighter blue.
      </p>
      <p>
        Where the hotspots are similar in colour or you want to compare results
        in more detail, select the “Show gap (% difference)" toggle. Switching
        this on will display the difference (as a percentage) between current
        and desired culture.
      </p>
      <p>
        To learn more, click on the spot to reveal information about which
        direction that misalignment is and read tips on how you can bring
        balance to the culture dimension in question.
      </p>
    </DialogBody>
    <DialogPrimaryActions>
      <Button
        onClick={() => {
          setHowToOpen(false);
        }}
      >
        OK
      </Button>
    </DialogPrimaryActions>
    <DialogCloseButton
      onClick={() => {
        setHowToOpen(false);
      }}
    />
  </Dialog>
);
