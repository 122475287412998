import React, { useRef } from "react";
import { Box, Pill, Select, SelectableOptionT, Text } from "@compono/ui";
import { CloseCircleSolidIcon, TriangleDotSolidIcon } from "@compono/ui-icons";

import {
  campaignDataCard,
  campaignDataCardInner,
  campaignDataCardText,
  campaignDataCardTextLowValue,
  campaignDataCardTextValue,
  campaignNamePillText,
  campaignTitleText,
  containerStyle,
  cultureTitleText,
  discardCampaignButton,
  filterLevelText,
  insightCardHeader,
  responsesTitleText,
} from "./styles";
import {
  CampaignDataCard,
  campaignsSelection,
  CircleA,
  CircleB,
  circleTextA,
  circleTextB,
  insightCardHeaderRef,
  referenceIcon,
  referentText,
} from "../../features/Culture/Explorer/styles";
import { AssessmentType, Campaign, Status } from "../../types";
import {
  ExplorerFilter,
  InsightsCampaignType,
} from "../../features/Culture/Explorer/types";
import { toCapitalCase } from "../../shared/helpers";
import { CampaignSelect, FiltersSelect } from "../../features/Culture/Explorer";
import { find, remove, cloneDeep } from "lodash";

const Divider = () => (
  <Box
    sx={{
      width: "100%",
      "border-top": "1px solid var(--colors-black-10)",
    }}
  />
);
const ExplorerCard = ({
  campaign,
  campaignType,
  selectedAssessmentType,
  explorerFilters,
  series,
  handleSetSelectedAssessmentType,
  handleResetCampaigns,
  handleSetExplorerFilters,
  handleFilters,
  handleSetCampaigns,
}: {
  campaign?: Campaign;
  campaignType: InsightsCampaignType;
  selectedAssessmentType: string;
  explorerFilters?: ExplorerFilter[];
  series?: any;
  handleSetSelectedAssessmentType: (
    type: InsightsCampaignType,
    assessmentType: AssessmentType
  ) => void;
  handleResetCampaigns: (type: InsightsCampaignType) => void;
  handleSetExplorerFilters: (type: InsightsCampaignType, filters: any) => void;
  handleFilters: (
    type: InsightsCampaignType,
    option: SelectableOptionT,
    level: SelectableOptionT,
    demographic: string
  ) => void;
  handleSetCampaigns: (type: InsightsCampaignType, campaign: Campaign) => void;
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  let filterOptions: any = [];
  filterOptions = campaign?.assessments
    ?.filter(
      (assessment) =>
        assessment.type !== AssessmentType.Perception &&
        assessment.type !== AssessmentType.Feedback
    )
    ?.map((assessment: { type: string }) => {
      return {
        value: assessment.type,
        label: toCapitalCase(`${assessment.type} culture`),
      };
    });

  const getPlaceholderValue = () => {
    return filterOptions.find(
      (option: any) => option.value === selectedAssessmentType
    )?.label;
  };
  const handleCompletionRateStyle = (completionRate = "0", status: Status) =>
    status === Status.Closed && +completionRate < 40
      ? campaignDataCardTextLowValue
      : campaignDataCardTextValue;

  const handleRemoveExplorerFilter = (
    type: InsightsCampaignType,
    demographic: string,
    label: string
  ) => {
    const updatedExplorerFilters = cloneDeep(explorerFilters);
    const existingFilter = find(updatedExplorerFilters, (o) => {
      return o.demographic === demographic;
    });
    if (existingFilter) {
      remove(existingFilter.filters, (o: { label: string; option: string }) => {
        return o.label === label;
      });
      remove(updatedExplorerFilters, (o: ExplorerFilter) => {
        return o.filters.length < 1;
      });
      handleSetExplorerFilters(type, updatedExplorerFilters);
    }
  };

  const isDisableFilterButton =
    Number(campaign?.responses ?? "0") === 0 &&
    +series?.cultureAssessmentResult?.totalResults === 0;

  const HeaderIcon = () => {
    if (campaignType === InsightsCampaignType.A) {
      return (
        <Box sx={insightCardHeader}>
          <CircleA>
            <Text sx={circleTextA}>A</Text>
          </CircleA>
        </Box>
      );
    } else if (campaignType === InsightsCampaignType.B) {
      return (
        <Box sx={insightCardHeader}>
          <CircleB>
            <Text sx={circleTextB}>B</Text>
          </CircleB>
        </Box>
      );
    } else if (campaignType === InsightsCampaignType.Ref) {
      return (
        <Box sx={insightCardHeaderRef}>
          <Box sx={referenceIcon}>
            <TriangleDotSolidIcon />
          </Box>
          <Text sx={referentText}>REFERENCE</Text>
        </Box>
      );
    } else {
      return null;
    }
  };

  if (!campaign)
    return (
      <Box sx={containerStyle}>
        <HeaderIcon />
        <Divider />
        <Box sx={campaignsSelection}>
          <CampaignSelect
            onSelectCampaign={(campaign: Campaign) => {
              if (campaign === null) {
                handleResetCampaigns(campaignType);
                return;
              }
              handleSetCampaigns(campaignType, campaign);
            }}
          />
        </Box>
      </Box>
    );
  return (
    <Box sx={containerStyle}>
      <HeaderIcon />
      <Divider />
      <Box sx={{ p: 6 }}>
        <Text sx={campaignTitleText}>Campaign</Text>
        <Pill
          data-testid={`clickedCampaign${campaign.name}Title`}
          size="lg"
          look="lightSolid"
          sx={campaignNamePillText}
          iconRight={
            <Text
              data-testid={`clickedCampaign${campaign.name}CancelIcon`}
              sx={discardCampaignButton}
              onClick={() => handleResetCampaigns(campaignType)}
            >
              <CloseCircleSolidIcon />
            </Text>
          }
          tone="secondary"
        >
          {campaign.name}
        </Pill>
        <CampaignDataCard>
          <Box sx={campaignDataCard}>
            <Box sx={campaignDataCardInner}>
              <Text sx={campaignDataCardText}>Recipients</Text>
              <Text
                data-testid={`clickedCampaign${campaign?.name}Recipients`}
                sx={campaignDataCardTextValue}
              >
                {campaign.respondents?.length}
              </Text>
            </Box>
            <Box sx={campaignDataCardInner}>
              <Text sx={campaignDataCardText}>Responses</Text>
              <Text
                data-testid={`clickedCampaign${campaign?.name}Responses`}
                sx={campaignDataCardTextValue}
              >
                {campaign.responses}
              </Text>
            </Box>
            <Box sx={campaignDataCardInner}>
              <Text sx={campaignDataCardText}>Completed</Text>
              <Text
                data-testid={`clickedCampaign${campaign?.name}CompletionRate`}
                sx={handleCompletionRateStyle(
                  campaign.completionRate,
                  campaign.status
                )}
              >{`${+Number.parseFloat(campaign.completionRate ?? "0").toFixed(
                2
              )} %`}</Text>
            </Box>
          </Box>
        </CampaignDataCard>
        <Box>
          <Text sx={cultureTitleText}>Culture</Text>
          {/* Assessment type selector */}
          {campaign?.assessments && (
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Select
                size="md"
                placeholder={getPlaceholderValue()}
                ref={selectRef}
                onChange={() => {
                  if (selectRef?.current?.value) {
                    handleSetSelectedAssessmentType(
                      campaignType,
                      selectRef.current.value as AssessmentType
                    );
                  }
                }}
              >
                {filterOptions.map((option: any) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </Select>
            </Box>
          )}
          <Text sx={cultureTitleText}>Filters</Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {explorerFilters?.map((filter, index) => {
              return (
                <Box key={`${filter.demographic}-${index}`} sx={{ my: 2 }}>
                  {filter.filters.map(({ label }, filterIndex: number) => (
                    <Box
                      sx={{
                        display: "flex",
                      }}
                      key={filterIndex}
                    >
                      <Pill
                        size="lg"
                        look="lightSolid"
                        sx={filterLevelText}
                        tone="secondary"
                      >
                        {filter.demographic}
                      </Pill>
                      <Pill
                        size="lg"
                        look="lightSolid"
                        sx={filterLevelText}
                        tone="secondary"
                      >
                        =
                      </Pill>
                      <Pill
                        size="lg"
                        look="lightSolid"
                        sx={campaignNamePillText}
                        iconRight={
                          <Text
                            sx={discardCampaignButton}
                            onClick={() =>
                              handleRemoveExplorerFilter(
                                campaignType,
                                filter.demographic,
                                label
                              )
                            }
                          >
                            <CloseCircleSolidIcon />
                          </Text>
                        }
                        tone="secondary"
                      >
                        {label}
                      </Pill>
                    </Box>
                  ))}
                </Box>
              );
            })}
          </Box>
          <FiltersSelect
            onAddFilter={(
              option: SelectableOptionT,
              level: SelectableOptionT,
              demographic: string
            ) => {
              handleFilters(campaignType, option, level, demographic);
            }}
            selectedCampaign={campaign}
            buttonDisabled={isDisableFilterButton}
          />
          <Divider />
          <Text
            data-testid={`clickedCampaign${campaign?.name}FooterText`}
            sx={responsesTitleText}
          >
            {`Showing ${
              series?.cultureAssessmentResult?.totalResults ?? "-"
            } of ${
              campaign?.responses ?? "-"
            } responses (based on filters applied)`}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default ExplorerCard;
