import { Pill, PillProps } from "@compono/ui";
import { CloseCircleSolidIcon } from "@compono/ui-icons";
import { Filter } from "~/types/filter";

export type FilterPillProps = PillProps & {
  filter: Filter;
  removeFilter?: (filter: Filter) => void;
};

export const FilterPill = ({
  filter,
  removeFilter,
  ...rest
}: FilterPillProps) => {
  return (
    <Pill
      {...rest}
      sx={{ ml: 5 }}
      iconRight={
        <CloseCircleSolidIcon
          onClick={() => {
            if (removeFilter) removeFilter(filter);
          }}
        />
      }
      size="lg"
      look="lightSolid"
    >
      {`${filter.type} = ${filter.name}`}
    </Pill>
  );
};
