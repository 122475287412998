import React from "react";
import {
  Box,
  Card,
  Disclosure,
  DisclosureRenderProp,
  Inline,
  Text,
  ToggleTip,
} from "@compono/ui";
import {
  DotSolidIcon,
  QuestionMarkCircleOutlineIcon,
  TriangleDotSolidIcon,
} from "@compono/ui-icons";

import { CULTURE_FIT_QUESTIONS } from "@compono/assessments-kit";
import ViolinPlot from "../../../../components/PlotGraph/Violinplot";
import { ViolinPlotExplainer } from "../../../../components/ViolinPlotExplainer";
import _ from "lodash";
import {
  graphContainerStyle,
  graphGrid,
  leftElement,
  rightElement,
} from "../../Explorer/styles";
import CultureInsights from "./CultureInsights";
import { desiredCultureIconStyle, legendElementStyle } from "./styles";

// constants
export const dimensions = [
  { engagekey: "riskTolerance", key: "innovative|cautious" },
  { engagekey: "driver", key: "process|outcome" },
  { engagekey: "focus", key: "task centred|employee centred" },
  { engagekey: "teamWork", key: "collective|individual" },
  { engagekey: "control", key: "centralised control|delegated control" },
  {
    engagekey: "structure",
    key: "hierarchical structure|horizontal structure",
  },
  { engagekey: "responsivePlanned", key: "ad-hoc|planned" },
  { engagekey: "conformity", key: "conformity|individuality" },
  { engagekey: "accepting", key: "accepting|questioning" },
  { engagekey: "cooperation", key: "cooperation|competition" },
  { engagekey: "internalExternal", key: "external|internal" },
  { engagekey: "formal", key: "formalised|informal" },
];

export const parseKeyToJson = (tmp: any, key: string, position: number) => {
  const observations = _.find(tmp, (obs) => {
    return obs.sk.includes(key);
  })?.messages;

  if (+position >= 1 && +position <= 1.8) {
    return observations.left;
  } else if (+position >= 1.81 && +position <= 2.6) {
    return observations.slightLeft;
  } else if (+position >= 2.61 && +position <= 3.4) {
    return observations.middle;
  } else if (+position >= 3.41 && +position <= 4.2) {
    return observations.slightRight;
  } else if (+position >= 4.21 && +position <= 5) {
    return observations.right;
  } else {
    return null;
  }
};
const ReportGraph = ({
  seriesA,
  seriesB,
  reference,
  observations,
  refDueDate,
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: 7,
          pt: 5,
          pb: 3,
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: 7,
              pt: 5,
              pb: 3,
            }}
          >
            <Box>
              <ViolinPlotExplainer />
            </Box>
            <Box sx={legendElementStyle}>
              <DotSolidIcon color="var(--colors-brandPurple-80)" />
              <Text>Current culture</Text>
            </Box>
            {!!seriesB && (
              <Box sx={legendElementStyle}>
                <Box sx={desiredCultureIconStyle} />
                <Text>Desired culture</Text>
              </Box>
            )}
            {reference && (
              <Box sx={{ ...legendElementStyle, fontSize: "1.2rem" }}>
                <TriangleDotSolidIcon />
                <Text>{`Culture as at ${refDueDate}`}</Text>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {seriesA &&
        seriesB &&
        observations &&
        dimensions.map((dimension, index) => {
          const { engagekey, key } = dimension;
          return (
            <Disclosure>
              <DisclosureRenderProp>
                {({ isOpen, toggleIsOpen, id }) => {
                  const [left, right] = CULTURE_FIT_QUESTIONS[key];
                  const [leftHeading, leftText] = left;
                  const [rightHeading, rightText] = right;

                  const pos = seriesA.average[engagekey];
                  const posB = seriesB.average[engagekey];

                  const ref = reference?.average[engagekey];

                  const observation = parseKeyToJson(observations, key, pos);
                  const observationB = parseKeyToJson(observations, key, posB);

                  const markers =
                    pos === posB
                      ? [
                          {
                            position: pos,
                            type: "overlap",
                          },
                        ]
                      : [
                          {
                            position: pos,
                            type: "primary",
                          },
                          {
                            position: posB,
                            type: "secondary",
                          },
                        ];
                  const referenceClosingDate = reference ? refDueDate : "";
                  return (
                    <Card
                      key="abc"
                      border
                      elevation={isOpen ? "2" : "0"}
                      sx={{ mb: 7, p: 0, borderColor: "black.20" }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "black.5",
                          m: 0,
                          borderTopLeftRadius: 5,
                          borderTopRightRadius: 5,
                        }}
                      >
                        <Box sx={{ ...graphGrid }}>
                          <Box sx={leftElement}>
                            <Inline alignY="center">
                              <Box>
                                <ToggleTip
                                  label="InformationCircleOutlineIcon"
                                  icon={<QuestionMarkCircleOutlineIcon />}
                                >
                                  {leftText}
                                </ToggleTip>
                              </Box>
                              {leftHeading}
                            </Inline>
                          </Box>
                          <Box sx={graphContainerStyle}>
                            <ViolinPlot
                              width={700}
                              violinData={
                                seriesA.results[engagekey].length >= 5
                                  ? seriesA.results[engagekey]
                                  : undefined
                              }
                              markers={
                                reference
                                  ? [
                                      ...markers,
                                      {
                                        position: ref,
                                        type: "line",
                                        color: "#AAAAAA",
                                      },
                                    ]
                                  : markers
                              }
                            ></ViolinPlot>
                          </Box>
                          <Box sx={rightElement}>
                            <Inline alignY="center">
                              {rightHeading}
                              <Box>
                                <ToggleTip
                                  label="InformationCircleOutlineIcon"
                                  icon={<QuestionMarkCircleOutlineIcon />}
                                >
                                  {rightText}
                                </ToggleTip>
                              </Box>
                            </Inline>
                          </Box>
                        </Box>
                      </Box>
                      <CultureInsights
                        isOpen={isOpen}
                        toggleIsOpen={toggleIsOpen}
                        left={left}
                        right={right}
                        value={pos}
                        valueB={posB}
                        reference={reference ? ref : undefined}
                        observation={observation}
                        observationB={observationB}
                        selectedRefCampaignDueDate={referenceClosingDate}
                      ></CultureInsights>
                    </Card>
                  );
                }}
              </DisclosureRenderProp>
            </Disclosure>
          );
        })}
    </Box>
  );
};

export default ReportGraph;
