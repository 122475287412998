import React from "react";
import { Stack, Text, Box, Heading } from "@compono/ui";
import { PlusCircleSolidIcon, MinusCircleSolidIcon } from "@compono/ui-icons";
import { bulletPointStyle } from "./styles";
import {
  proIconContainerSx,
  conIconContainerSx,
} from "../../../components/ObservationLearnMoreDialog/styles";
import { Observation } from "../types";

export const DisclosureObservations = ({
  observation,
  observationB,
}: {
  observation: Observation;
  observationB: Observation;
}) => {
  enum ObservationType {
    PROS = "Pros",
    CONS = "Cons",
  }
  const isDifferent = observation?.text !== observationB?.text;

  const ProConList = ({
    observation,
    type,
  }: {
    observation: Observation;
    type: ObservationType;
  }) => {
    const title =
      type === ObservationType.PROS
        ? observation.prosTitle
        : observation.consTitle;
    const list =
      type === ObservationType.PROS
        ? observation?.fullPros
        : observation?.fullCons;
    return (
      <Box>
        <Text font="title" sx={{ mb: 5 }}>
          {title}
        </Text>
        <Stack gap={4}>
          {list?.map((point: string, index: number) => {
            return (
              <Box sx={bulletPointStyle} key={index}>
                <Box
                  sx={
                    type === ObservationType.PROS
                      ? proIconContainerSx
                      : conIconContainerSx
                  }
                >
                  {type === ObservationType.PROS ? (
                    <PlusCircleSolidIcon />
                  ) : (
                    <MinusCircleSolidIcon />
                  )}
                </Box>
                <Text sx={{ flex: 1, alignSelf: "center" }}>{point}</Text>
              </Box>
            );
          })}
        </Stack>
      </Box>
    );
  };

  return (
    <Box>
      <Stack flat gap="4" sx={{ mt: 3, mb: 2 }}>
        <Heading level="6">
          {isDifferent
            ? "Summary (current culture)"
            : "Summary (current and desired culture)"}
        </Heading>
        {isDifferent && <Heading level="6">Summary (desired culture)</Heading>}
      </Stack>
      <Stack flat gap="4" sx={{ mb: 7 }}>
        <Text>{observation?.text}</Text>
        {isDifferent && <Text>{observationB?.text}</Text>}
      </Stack>
      <Stack flat gap="4">
        <ProConList
          observation={observation}
          type={ObservationType.PROS}
        ></ProConList>
        {isDifferent && (
          <ProConList
            observation={observationB}
            type={ObservationType.PROS}
          ></ProConList>
        )}
      </Stack>
      <Stack flat gap="4" sx={{ mt: 7 }}>
        <ProConList
          observation={observation}
          type={ObservationType.CONS}
        ></ProConList>
        {isDifferent && (
          <ProConList
            observation={observationB}
            type={ObservationType.CONS}
          ></ProConList>
        )}
      </Stack>
    </Box>
  );
};
