import { useTimezone } from "@compono/ui";
import { format } from "date-fns";
import { Campaign, Status } from "../types";

// date
const getCompleteFormatedDate = (date: string | Date) =>
  format(new Date(date), "dd/MM/yyyy hh:mm:ss");

/** @description returns date in dd/MM/yyyy format */
const getDateInDisplayFormat = (date: string | Date) =>
  format(date ? new Date(date) : new Date(), "dd/MM/yyyy");

const getDatePickerFormat = (date: string) =>
  format(new Date(date), "yyyy-MM-dd");

export const getMilestoneDateFormat = (date: string) =>
  format(new Date(date), "do LLL Y");

const sliceIntoChunks = (arr: any[], chunkSize: number) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

const toCapitalCase = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const isEven = (n: number) => n % 2 == 0;

const getTimezone = (value: string | undefined) =>
  useTimezone().find((timezone) => timezone.value === value);

export const getCompletionRate = (_campaign: Campaign, fixed = 2) => {
  const completionRate = _campaign?.completionRate ?? "0";
  return +Number.parseFloat(completionRate).toFixed(fixed);
};

export const getObservationKey = (key: string) => {
  switch (key) {
    case "riskTolerance":
      return "innovative|cautious";
    case "driver":
      return "process|outcome";
    case "focus":
      return "task centred|employee centred";
    case "teamWork":
      return "collective|individual";
    case "control":
      return "centralised control|delegated control";
    case "structure":
      return "hierarchical structure|horizontal structure";
    case "responsivePlanned":
      return "ad-hoc|planned";
    case "conformity":
      return "conformity|individuality";
    case "accepting":
      return "accepting|questioning";
    case "cooperation":
      return "cooperation|competition";
    case "internalExternal":
      return "external|internal";
    case "formal":
      return "formalised|informal";
    default:
      return null;
  }
};

export const hasEnoughResponses = (c: Campaign): boolean => {
  if (!c.responses || !c.respondents) return false;
  return +c.responses / c.respondents.length >= 0.4;
};

export const isNotValidClosedCampaign = (c?: Campaign) => {
  if (!c) return false;
  return c.status === Status.Closed && !hasEnoughResponses(c);
};

export const isVowel = (c: string) =>
  ["a", "e", "i", "o", "u"].indexOf(c.toLowerCase()) !== -1;

export const getInteger = (num: number, base = 4) =>
  Number((100 - ((num - 1) / base) * 100).toFixed(0));

export {
  getCompleteFormatedDate,
  getDateInDisplayFormat,
  getDatePickerFormat,
  sliceIntoChunks,
  toCapitalCase,
  isEven,
  getTimezone,
};
