import React, { useState } from "react";
import { Button, Box } from "@compono/ui";
import { QuestionMarkCircleOutlineIcon } from "@compono/ui-icons";
import { ViolinPlotExplainerStyle } from "./styles";
import { ViolinPlotExplainerDialog } from "./index";

const ViolinPlotExplainer = () => {
  // state
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ViolinPlotExplainerDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <Box sx={ViolinPlotExplainerStyle}>
        <Button
          sx={{ padding: "0" }}
          data-testid="violinPlotExplainerButton"
          look="naked"
          iconLeft={QuestionMarkCircleOutlineIcon}
          onClick={() => setIsOpen(!isOpen)}
        >
          How to read these graphs
        </Button>
      </Box>
    </>
  );
};

export default ViolinPlotExplainer;
