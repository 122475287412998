import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AiOutlineFileSearch } from "react-icons/ai";
import { useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Divider,
  LinkButton,
  SelectableOptionT,
  Stack,
  Text,
} from "@compono/ui";
import {
  ChevronLeftOutlineIcon,
  ClockCircleOutlineIcon,
  CloseSolidIcon,
} from "@compono/ui-icons";

import {
  container,
  contentStyle,
  errorMessageContainerStyle,
  nothingToShowFooterText,
  nothingToShowIcon,
  nothingToShowMessage,
  nothingToShowText,
} from "./styles";
import { CultureAssessments } from "./queries";
import { AssessmentType, Campaign } from "../../../types";
import {
  ExplorerFilter,
  InsightsCampaignType,
  InsightsFilterType,
  Response,
} from "./types";
import { useInsights } from "../../../hooks/index";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import {
  hideOnSmallDeviceStyle,
  showOnSmallDeviceStyle,
} from "../../../shared/styles";
import { ViolinPlotExplainer } from "../../../components/ViolinPlotExplainer";
import { ExplorerCard } from "../../../components/ExplorerCard";
import { cloneDeep, find } from "lodash";
import SuperChargeInfo from "../Report/SuperChargeInfo";
import { CampaignSuperchargeSeen } from "../../Campaigns/queries";
import SuperChargeConfirm from "../Report/SuperChargeConfirm";
import { differenceInDays } from "date-fns";
import ExplorerGraph from "./ExplorerGraph";

export const InsightsPage = () => {
  //TODO to be removed once we want to release
  const hideRefreshFeature = true;

  const sessionItem = JSON.parse(
    sessionStorage.getItem("insightRecord") ?? "{}"
  );

  // state
  const [selectedCampaignA, setSelectedCampaignA] = useState<
    Campaign | undefined
  >(sessionItem.selectedCampaignA ?? undefined);
  const [selectedCampaignB, setSelectedCampaignB] = useState<
    Campaign | undefined
  >(sessionItem.selectedCampaignB ?? undefined);
  const [selectedCampaignRef, setSelectedCampaignRef] = useState<
    Campaign | undefined
  >(sessionItem.selectedCampaignRef ?? undefined);
  const [selectedAssessmentTypeA, setSelectedAssessmentTypeA] = useState(
    sessionItem.selectedAssessmentTypeA ?? AssessmentType.Current
  );
  const [selectedAssessmentTypeB, setSelectedAssessmentTypeB] = useState(
    sessionItem.selectedAssessmentTypeB ?? AssessmentType.Current
  );
  const [selectedAssessmentTypeRef, setSelectedAssessmentTypeRef] = useState(
    sessionItem.selectedAssessmentTypeRef ?? AssessmentType.Current
  );

  const [superChargeInfoOpen, setSuperChargeInfoOpen] = useState(false);
  const [superChargeConfirmOpen, setSuperChargeConfirmOpen] = useState(false);
  const [isNotSuperCharged, setIsNotSuperCharged] = useState(false);
  const [canRefresh, setCanRefresh] = useState(false);

  const [explorerFiltersA, setExplorerFiltersA] = useState<ExplorerFilter[]>(
    sessionItem.explorerFiltersA ?? []
  );
  const [explorerFiltersB, setExplorerFiltersB] = useState<ExplorerFilter[]>(
    sessionItem.explorerFiltersB ?? []
  );
  const [explorerFiltersRef, setExplorerFiltersRef] = useState<
    ExplorerFilter[]
  >(sessionItem.explorerFiltersRef ?? []);

  const [showResultsA, setShowResultsA] = useState(
    sessionItem.showResultsA ?? false
  );
  const [showResultsB, setShowResultsB] = useState(
    sessionItem.showResultsB ?? false
  );
  const [showResultsRef, setShowResultsRef] = useState(
    sessionItem.showResultsRef ?? false
  );

  // hooks
  const history = useHistory();
  const { translateFilters } = useInsights();

  //data
  const [fetchSeriesA, { data: seriesA }] =
    useLazyQuery<Response>(CultureAssessments);
  const [fetchSeriesB, { data: seriesB }] =
    useLazyQuery<Response>(CultureAssessments);
  const [fetchReference, { data: reference }] =
    useLazyQuery<Response>(CultureAssessments);

  const [campaignSuperchargeSeen] = useMutation(CampaignSuperchargeSeen);

  // effects
  useEffect(() => {
    if (selectedCampaignA) {
      if (seriesA?.cultureAssessmentResult?.totalResults) {
        setShowResultsA(true);
      } else {
        setShowResultsA(false);
      }
      const assessmentA = selectedCampaignA?.assessments?.find(
        ({ type }: { type: string }) => type === selectedAssessmentTypeA
      );
      fetchSeriesA({
        variables: {
          assessmentSk: assessmentA?.sk,
          filters: translateFilters(explorerFiltersA),
        },
      });
      const now = new Date();
      const closedDate = Date.parse(selectedCampaignA.closedDate);
      const diff = differenceInDays(now, closedDate);
      setIsNotSuperCharged(
        Boolean(
          diff < 28 &&
            selectedCampaignA.hasPlaceholders &&
            !selectedCampaignA.superCharged
        )
      );
      setCanRefresh(
        Boolean(
          diff < 28 &&
            selectedCampaignA.hasPlaceholders &&
            selectedCampaignA.superCharged
        )
      );
      return;
    }
    setShowResultsA(false);
  }, [selectedCampaignA, selectedAssessmentTypeA, explorerFiltersA, seriesA]);

  useEffect(() => {
    if (selectedCampaignB) {
      if (seriesB?.cultureAssessmentResult?.totalResults) {
        setShowResultsB(true);
      } else {
        setShowResultsB(false);
      }
      const assessmentB = selectedCampaignB?.assessments?.find(
        ({ type }: { type: string }) => type === selectedAssessmentTypeB
      );
      fetchSeriesB({
        variables: {
          assessmentSk: assessmentB?.sk,
          filters: translateFilters(explorerFiltersB),
        },
      });
      return;
    }
    setShowResultsB(false);
  }, [selectedCampaignB, selectedAssessmentTypeB, explorerFiltersB, seriesB]);

  useEffect(() => {
    if (selectedCampaignRef) {
      if (reference?.cultureAssessmentResult?.totalResults) {
        setShowResultsRef(true);
      } else {
        setShowResultsRef(false);
      }
      const assessmentRef = selectedCampaignRef?.assessments?.find(
        ({ type }: { type: string }) => type === selectedAssessmentTypeRef
      );
      fetchReference({
        variables: {
          assessmentSk: assessmentRef?.sk,
          filters: translateFilters(explorerFiltersRef),
        },
      });
      return;
    }
    setShowResultsRef(false);
  }, [
    selectedCampaignRef,
    selectedAssessmentTypeRef,
    explorerFiltersRef,
    reference,
  ]);

  useEffect(() => {
    sessionStorage.setItem(
      "insightRecord",
      JSON.stringify({
        selectedCampaignA,
        selectedCampaignB,
        selectedCampaignRef,
        selectedAssessmentTypeA,
        selectedAssessmentTypeB,
        selectedAssessmentTypeRef,
        explorerFiltersA,
        explorerFiltersB,
        explorerFiltersRef,
        showResultsA,
        showResultsB,
        showResultsRef,
      })
    );
  }, [
    selectedCampaignA,
    selectedCampaignB,
    selectedCampaignRef,
    selectedAssessmentTypeA,
    selectedAssessmentTypeB,
    selectedAssessmentTypeRef,
    explorerFiltersA,
    explorerFiltersB,
    explorerFiltersRef,
    showResultsA,
    showResultsB,
    showResultsRef,
    isNotSuperCharged,
  ]);

  // methods
  const handleSetCampaigns = (
    type: InsightsCampaignType,
    campaign: Campaign
  ) => {
    switch (type) {
      case InsightsCampaignType.A: {
        setSelectedCampaignA(campaign);
        return;
      }
      case InsightsCampaignType.B: {
        setSelectedCampaignB(campaign);
        return;
      }
      case InsightsCampaignType.Ref: {
        setSelectedCampaignRef(campaign);
        return;
      }
      default:
    }
  };

  const handleResetCampaigns = (type: InsightsCampaignType) => {
    switch (type) {
      case InsightsCampaignType.A: {
        setSelectedCampaignA(undefined);
        setSelectedAssessmentTypeA(AssessmentType.Current);
        setExplorerFiltersA([]);
        setIsNotSuperCharged(false);
        setCanRefresh(false);
        return;
      }
      case InsightsCampaignType.B: {
        setSelectedCampaignB(undefined);
        setSelectedAssessmentTypeB(AssessmentType.Current);
        setExplorerFiltersB([]);
        return;
      }
      case InsightsCampaignType.Ref: {
        setSelectedCampaignRef(undefined);
        setSelectedAssessmentTypeRef(AssessmentType.Current);
        setExplorerFiltersRef([]);
        return;
      }
    }
  };

  const handleResetFilters = () => {
    setExplorerFiltersA([]);
    setExplorerFiltersB([]);
    setExplorerFiltersRef([]);
  };

  const handleSetExplorerFilters = (
    type: InsightsCampaignType,
    filters: any
  ) => {
    switch (type) {
      case InsightsCampaignType.A:
        setExplorerFiltersA(filters);
        return;
      case InsightsCampaignType.B:
        setExplorerFiltersB(filters);
        return;
      case InsightsCampaignType.Ref:
        setExplorerFiltersRef(filters);
        return;
      default:
    }
  };

  const handleSetSelectedAssessmentType = (
    type: InsightsCampaignType,
    assessmentType: AssessmentType
  ) => {
    switch (type) {
      case InsightsCampaignType.A:
        setSelectedAssessmentTypeA(assessmentType);
        return;
      case InsightsCampaignType.B:
        setSelectedAssessmentTypeB(assessmentType);
        return;
      case InsightsCampaignType.Ref:
        setSelectedAssessmentTypeRef(assessmentType);
        return;
      default:
    }
  };

  const getSelectedExplorerFilters = (type: InsightsCampaignType) => {
    switch (type) {
      case InsightsCampaignType.A:
        return explorerFiltersA;
      case InsightsCampaignType.B:
        return explorerFiltersB;
      case InsightsCampaignType.Ref:
        return explorerFiltersRef;
      default:
    }
  };
  const updateExplorerFilter = (type: InsightsCampaignType, filters: any) => {
    switch (type) {
      case InsightsCampaignType.A:
        setExplorerFiltersA(filters);
        return;
      case InsightsCampaignType.B:
        setExplorerFiltersB(filters);
        return;
      case InsightsCampaignType.Ref:
        setExplorerFiltersRef(filters);
        return;
      default:
    }
  };

  const handleFilters = (
    type: InsightsCampaignType,
    option: SelectableOptionT,
    level: SelectableOptionT,
    demographic: string
  ) => {
    const selectedExplorerFilters = getSelectedExplorerFilters(type) || [];
    let updatedExplorerFilters = cloneDeep(selectedExplorerFilters);

    const filterType =
      demographic === "Divisions" ? InsightsFilterType.DIVISIONS : level.value;

    const existingFilter = find(selectedExplorerFilters, (o) => {
      return o.demographic === demographic;
    });
    const filterLabel = option ? option.label : level.label;
    const filterOption = (option ? option.path : level.path) as string;
    if (existingFilter) {
      updatedExplorerFilters = updatedExplorerFilters?.map((obj) => {
        if (
          obj.demographic === demographic &&
          !find(obj.filters, { label: filterLabel, option: filterOption })
        ) {
          return {
            demographic,
            filterType,
            filters: [
              ...obj.filters,
              { label: filterLabel, option: filterOption },
            ],
          };
        }
        return obj;
      });
    } else {
      updatedExplorerFilters = [
        ...updatedExplorerFilters,
        {
          demographic,
          filterType,
          filters: [{ label: filterLabel, option: filterOption }],
        },
      ];
    }
    updateExplorerFilter(type, updatedExplorerFilters);
  };

  const noResultsOnFilters = () => {
    // Early return in case there are no filters applied or there are no results to show
    if (
      (explorerFiltersA.length === 0 &&
        explorerFiltersB.length === 0 &&
        explorerFiltersRef.length === 0) ||
      showResultsA ||
      showResultsB ||
      showResultsRef
    ) {
      return false;
    }
    let showNoResultsOnFiltersMessage = false;
    if (explorerFiltersA.length > 0) {
      showNoResultsOnFiltersMessage =
        !showResultsA &&
        Number(seriesA?.cultureAssessmentResult?.totalResults) === 0;
    }
    if (explorerFiltersB.length > 0) {
      showNoResultsOnFiltersMessage =
        !showResultsB &&
        Number(seriesB?.cultureAssessmentResult?.totalResults) === 0;
    }
    if (explorerFiltersRef.length > 0) {
      showNoResultsOnFiltersMessage =
        !showResultsRef &&
        Number(reference?.cultureAssessmentResult?.totalResults) === 0;
    }
    return showNoResultsOnFiltersMessage;
  };

  const noResponsesOnCampaign = () => {
    let showNoResponsesMessage = false;
    if (selectedCampaignA) {
      showNoResponsesMessage = Number(selectedCampaignA?.responses) === 0;
      if (!showNoResponsesMessage) return false;
    }
    if (selectedCampaignB) {
      showNoResponsesMessage = Number(selectedCampaignB?.responses) === 0;
      if (!showNoResponsesMessage) return false;
    }
    if (selectedCampaignRef) {
      showNoResponsesMessage = Number(selectedCampaignRef?.responses) === 0;
      if (!showNoResponsesMessage) return false;
    }
    return showNoResponsesMessage;
  };

  const updateCampaignSuperchargeInfo = () => {
    campaignSuperchargeSeen({
      variables: {
        sk: selectedCampaignA?.sk,
      },
    }).then(() => {
      history.push("/users");
    });
  };

  return (
    <Box sx={container}>
      <Box sx={{ margin: "20px" }}>
        <LinkButton
          sx={{ position: "absolute" }}
          look="link"
          iconLeft={ChevronLeftOutlineIcon}
          href="/culture?type=report"
        >
          Back
        </LinkButton>
        <Stack align="center">
          <Text>
            <b>Culture explorer</b>
          </Text>
        </Stack>
      </Box>
      <Box>
        <Divider orientation="horizontal" />
      </Box>
      <Box sx={contentStyle}>
        <Box sx={showOnSmallDeviceStyle}>
          <ErrorMessage
            title="Not supported on mobile"
            description="Sorry, this feature needs a larger screen or window to work properly.
            Please enlarge your browser window, turn your device to landscape, or use a larger device."
            linkText="Go back to dashboard"
          />
        </Box>
        <Box sx={hideOnSmallDeviceStyle}>
          {!hideRefreshFeature && (
            <Box sx={{ mb: 5 }}>
              {isNotSuperCharged && (
                <SuperChargeInfo
                  superChargeInfoOpen={superChargeInfoOpen}
                  updateCampaignSuperchargeInfo={updateCampaignSuperchargeInfo}
                  setSuperChargeInfoOpen={setSuperChargeInfoOpen}
                />
              )}
              {canRefresh && (
                <SuperChargeConfirm
                  superChargeConfirmOpen={superChargeConfirmOpen}
                  setSuperChargeConfirmOpen={setSuperChargeConfirmOpen}
                  refreshCampaign={() => {
                    //TODO @Nick call your mutation here
                  }}
                  campaignName={selectedCampaignA?.name || ""}
                />
              )}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              gap: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: "0 0 350px",
              }}
            >
              <Box sx={{ mb: 5 }}>
                <ViolinPlotExplainer />
              </Box>
              <ExplorerCard
                campaign={selectedCampaignA}
                campaignType={InsightsCampaignType.A}
                handleResetCampaigns={handleResetCampaigns}
                selectedAssessmentType={selectedAssessmentTypeA}
                handleSetSelectedAssessmentType={
                  handleSetSelectedAssessmentType
                }
                explorerFilters={explorerFiltersA}
                handleSetExplorerFilters={handleSetExplorerFilters}
                handleFilters={handleFilters}
                series={seriesA}
                handleSetCampaigns={handleSetCampaigns}
              />
              <ExplorerCard
                campaign={selectedCampaignB}
                campaignType={InsightsCampaignType.B}
                handleResetCampaigns={handleResetCampaigns}
                selectedAssessmentType={selectedAssessmentTypeB}
                handleSetSelectedAssessmentType={
                  handleSetSelectedAssessmentType
                }
                explorerFilters={explorerFiltersB}
                handleSetExplorerFilters={handleSetExplorerFilters}
                handleFilters={handleFilters}
                series={seriesB}
                handleSetCampaigns={handleSetCampaigns}
              />
              <ExplorerCard
                campaign={selectedCampaignRef}
                campaignType={InsightsCampaignType.Ref}
                handleResetCampaigns={handleResetCampaigns}
                selectedAssessmentType={selectedAssessmentTypeRef}
                handleSetSelectedAssessmentType={
                  handleSetSelectedAssessmentType
                }
                explorerFilters={explorerFiltersRef}
                handleSetExplorerFilters={handleSetExplorerFilters}
                handleFilters={handleFilters}
                series={reference}
                handleSetCampaigns={handleSetCampaigns}
              />
            </Box>
            {noResultsOnFilters() && (
              <Box sx={nothingToShowMessage}>
                <Text sx={nothingToShowIcon}>
                  <AiOutlineFileSearch fontSize="105px" />
                </Text>
                <Text sx={nothingToShowText}>
                  Nothing matches these filters
                </Text>
                <Text sx={nothingToShowFooterText}>
                  Try broadening your filters, or clear your current filters and
                  start again.
                </Text>
                <Button
                  look="naked"
                  onClick={handleResetFilters}
                  sx={{ my: 5 }}
                  iconLeft={CloseSolidIcon}
                >
                  Clear filters
                </Button>
              </Box>
            )}
            {noResponsesOnCampaign() && (
              <Box sx={errorMessageContainerStyle}>
                <ErrorMessage
                  icon={<ClockCircleOutlineIcon fontSize="105px" />}
                  title="Nothing to show yet"
                  description="We’re still waiting for people to respond to the survey."
                />
              </Box>
            )}
            {!noResultsOnFilters() && !noResponsesOnCampaign() && (
              <Box sx={{ flex: 1 }}>
                <ExplorerGraph
                  seriesA={
                    selectedCampaignA
                      ? seriesA?.cultureAssessmentResult
                      : undefined
                  }
                  seriesB={
                    selectedCampaignB
                      ? seriesB?.cultureAssessmentResult
                      : undefined
                  }
                  reference={
                    selectedCampaignRef
                      ? reference?.cultureAssessmentResult
                      : undefined
                  }
                ></ExplorerGraph>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InsightsPage;
