import { CULTURE_FIT_QUESTIONS } from "@compono/assessments-kit";
import { Inline, Box } from "@compono/ui";
import { InformationCircleOutlineIcon } from "@compono/ui-icons";
import React from "react";
import { getSliceColorByValue } from "~/components/PersonalityWheel/colors";

export const GreyBox = ({
  children,
  sx = {},
  shade = "3",
}: {
  children?: any;
  sx?: ThemeUIStyleObject | undefined;
  shade?: string;
}) => {
  const color = `var(--colors-black-${shade})`;
  return (
    <Box
      sx={{
        "background-color": color,
        pt: 4,
        pl: 2,
        m: "1px",
        "border-radius": "2px",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
export const BlueBox = ({
  sx = {},
  value = { percentage: 0, colorValue: 0 },
  onClick,
  showValue = false,
}: {
  sx?: ThemeUIStyleObject | undefined;
  value: { percentage: number; colorValue: numnber };
  onClick: any;
  showValue: boolean;
}) => {
  const color = getSliceColorByValue(value.colorValue);
  return (
    <Box
      sx={{
        "background-color": color,
        height: "50px",
        width: "50px",
        m: "1px",
        pl: "4px",
        "border-radius": "2px",
        color: value.percentage > 20 ? "white" : "black",
        ...sx,
      }}
      onClick={() => {
        onClick();
      }}
    >
      {showValue ? value.percentage : ""}
    </Box>
  );
};
export const BoxRow = ({ colors, onClick, showValues = false }) => {
  return (
    <>
      {colors.map((color, index) => {
        return (
          <BlueBox
            value={color}
            onClick={() => {
              onClick(index);
            }}
            showValue={showValues}
          ></BlueBox>
        );
      })}
    </>
  );
};

export const verticalStyle = {
  "writing-mode": "vertical-lr",
  transform: "rotate(-180deg)",
  height: "360px",
  width: "50px",
  pl: 4,
  pt: 2,
};

export const VerticalHeading = ({ type, onClick }) => {
  const [left, right] = CULTURE_FIT_QUESTIONS[type];
  const [leftHeading] = left;
  const [rightHeading] = right;
  return (
    <GreyBox sx={verticalStyle} shade="10">
      <Inline gap="2">
        <Box>
          {leftHeading} vs {rightHeading}
        </Box>
        <Box sx={{ transform: "rotate(90deg)", cursor: "Pointer" }}>
          <InformationCircleOutlineIcon onClick={() => onClick(type)} />
        </Box>
      </Inline>
    </GreyBox>
  );
};
