import React from "react";
import _ from "lodash";
import { scaleLinear } from "@visx/scale";
import { Group } from "@visx/group";
import { ViolinPlot } from "@visx/stats";
import { Axis, Orientation } from "@visx/axis";
import { Glyph, GlyphCircle } from "@visx/glyph";
import { Polygon, Line } from "@visx/shape";
import { PlotGraphProps } from "./types";

const MIN = 1;
const MAX = 5;
const TICK_LENGTH = 6;
const px = 40;
const py = 8;
export const VIOLIN_PLOT_PRIMARY_COLOR = "#3E1F98";
export const VIOLIN_PLOT_SECONDARY_COLOR = "#808080";
export const AXIS_COLOR = "#AAAAAA";
export const COMP_STROKE_COLOR = "#555555";
export const PRIMARY_COLOR = "#741994";
export const SECONDARY_COLOR = "#484769";
export const WHITE_COLOR = "#FFFFFF";
export const REFERENCE_ICON_COLOR = "#AAAAAA";

// handle colors
const getCircleColor = (color: string) => {
  if (color === "primary") {
    return PRIMARY_COLOR;
  } else if (color === "secondary") {
    return WHITE_COLOR;
  } else if (color.charAt(0) === "#") {
    return color;
  }
  return "primary";
};
const getStrokeColor = (color: string) => {
  if (color === "secondary") {
    return COMP_STROKE_COLOR;
  } else {
    return getCircleColor(color);
  }
};

const getViolinPlotColor = (color: string) => {
  if (color === "primary") {
    return VIOLIN_PLOT_PRIMARY_COLOR;
  } else if (color === "secondary") {
    return VIOLIN_PLOT_SECONDARY_COLOR;
  } else if (color.charAt(0) === "#") {
    return color;
  }
  return VIOLIN_PLOT_PRIMARY_COLOR;
};

export const mean = (values: any, valueof: any) => {
  let count = 0;
  let sum = 0;
  if (valueof === undefined) {
    for (let value of values) {
      if (value != null && (value = +value) >= value) {
        ++count, (sum += value);
      }
    }
  } else {
    let index = -1;
    for (let value of values) {
      if (
        (value = valueof(value, ++index, values)) != null &&
        (value = +value) >= value
      ) {
        ++count, (sum += value);
      }
    }
  }
  if (count) return sum / count;
};

const ReferenceIcon = ({
  size = 10,
  transform,
}: {
  size?: number;
  transform?: string;
}) => (
  <Group left={size / 2} transform={transform}>
    <Polygon sides={3} size={7} rotate={270} fill={REFERENCE_ICON_COLOR} />
    <Line
      strokeDasharray={1.5}
      from={{ x: 0, y: 0 }}
      to={{ x: 0, y: size * 1.3 }}
      strokeWidth={2}
      stroke={REFERENCE_ICON_COLOR}
    />
  </Group>
);
const Reference = ({
  left,
  top,
  size,
}: {
  left: number;
  top: number;
  size: number;
}) => (
  <Glyph left={left} top={top}>
    <ReferenceIcon size={size} transform="translate(0)" />
  </Glyph>
);

const PlotGraph = ({
  average,
  results,
  compAverage,
  compResults,
  reference,
  showViolinPlot = true,
  showAxis = true,
  showCircle = true,
  width = 500,
  height = 34,
  circleFillColor = "primary",
  circleStrokeColor = "primary",
  violinPlotColor = "primary",
  circleFillOpacity = 1,
  circleStrokeOpacity = 1,
  circleStrokeSize = 2,
  circleSize = 24,
  color = "primary",
  dualView = false,
  showSecondaryDot = false,
}: PlotGraphProps) => {
  const isSameResults = average === compAverage;
  // bounds
  const xMax = width - px;
  // scales
  const xScale = scaleLinear<number>({
    range: [0, xMax],
    round: true,
    domain: [MIN, MAX],
  });

  // mapping
  const mapResultsToBinData = (nums: number[]) => {
    const mappedDataset = nums.map((num) => (num - 1) / 4);
    const epanechnikov =
      (bandwidth = 7) =>
      (x: number) =>
        Math.abs(x / bandwidth) <= 1 ? (0.75 * (1 - x * x)) / bandwidth : 0;
    const getThresholds = (divisions = 20) =>
      Array.from({ length: divisions + 1 }, (_, i) =>
        Number.parseFloat((i * (1 / divisions)).toFixed(2))
      );
    const thresholds = getThresholds(30);
    const kde = (kernel: any, data: number[]) =>
      thresholds.map((t) => [t, mean(data, (d: any) => kernel(t - d))]);
    const density = kde(epanechnikov(0.15), mappedDataset);
    return density.map(([x, y]) => ({
      value: x! * 4 + 1,
      count: Math.round(1000 * y!),
    }));
  };

  const getHeight = (): string | number | undefined => {
    return dualView && compAverage ? height * 2 : height;
  };

  return (
    <svg width={width} height={getHeight()}>
      <Group left={px / 2}>
        <Group>
          {showViolinPlot && results && (
            <ViolinPlot
              horizontal={true}
              data={mapResultsToBinData(results)}
              left={0}
              top={py / 2}
              width={height - py}
              valueScale={xScale}
              fill={getViolinPlotColor(violinPlotColor)}
              fillOpacity={0.25}
            />
          )}
          {showAxis && (
            <Axis
              orientation={Orientation.bottom}
              top={height / 2}
              scale={xScale}
              stroke={AXIS_COLOR}
              tickStroke={AXIS_COLOR}
              tickLength={TICK_LENGTH}
              tickTransform={`translate(0 -${TICK_LENGTH / 2})`}
              tickFormat={() => ""}
              tickValues={[1, 2, 3, 4, 5]}
            />
          )}
          {compAverage && !dualView && (
            <>
              <Line
                from={{ x: xScale(average), y: height / 2 }}
                to={{ x: xScale(compAverage), y: height / 2 }}
                stroke={COMP_STROKE_COLOR}
                strokeWidth={2}
              />
              <Group>
                <clipPath id="overlap2">
                  <rect
                    width={circleSize / 2}
                    height={circleSize}
                    transform={`translate(0, -${circleSize / 2})`}
                  ></rect>
                </clipPath>
                <GlyphCircle
                  left={xScale(compAverage)}
                  top={height / 2}
                  size={circleSize * 10}
                  fill={WHITE_COLOR}
                  stroke={SECONDARY_COLOR}
                  strokeWidth={2}
                  clipPath={isSameResults ? "url(#overlap2)" : undefined}
                />
              </Group>
            </>
          )}
          {showCircle && (
            <Group>
              <clipPath id="overlap1">
                <rect
                  width={circleSize / 2}
                  height={circleSize}
                  transform={`translate(-${circleSize / 2}, -${
                    circleSize / 2
                  })`}
                ></rect>
              </clipPath>
              {showSecondaryDot ? (
                <GlyphCircle
                  left={xScale(average)}
                  top={height / 2}
                  size={circleSize * 10}
                  fill={WHITE_COLOR}
                  stroke={SECONDARY_COLOR}
                  strokeWidth={2}
                  clipPath={isSameResults ? "url(#overlap2)" : undefined}
                />
              ) : (
                <GlyphCircle
                  left={xScale(average)}
                  top={height / 2}
                  size={circleSize * 10}
                  clipPath={
                    !dualView && isSameResults ? "url(#overlap1)" : undefined
                  }
                  fill={getCircleColor(circleFillColor)}
                  stroke={getStrokeColor(circleStrokeColor)}
                  strokeWidth={circleStrokeSize}
                  fillOpacity={circleFillOpacity}
                  strokeOpacity={circleStrokeOpacity}
                />
              )}
            </Group>
          )}
        </Group>
        {compAverage && dualView && (
          <Group top={height}>
            {showViolinPlot && compResults && (
              <ViolinPlot
                horizontal={true}
                data={mapResultsToBinData(compResults)}
                left={0}
                top={py / 2}
                width={height - py}
                valueScale={xScale}
                fill={VIOLIN_PLOT_SECONDARY_COLOR}
                fillOpacity={0.1}
              />
            )}
            <Axis
              orientation={Orientation.bottom}
              top={height / 2}
              scale={xScale}
              stroke={AXIS_COLOR}
              tickStroke={AXIS_COLOR}
              tickLength={TICK_LENGTH}
              tickTransform={`translate(0 -${TICK_LENGTH / 2})`}
              tickFormat={() => ""}
              tickValues={[1, 2, 3, 4, 5]}
            />
            <GlyphCircle
              left={xScale(compAverage)}
              top={height / 2}
              size={240}
              fill={color === "secondary" ? PRIMARY_COLOR : WHITE_COLOR}
              stroke={color === "secondary" ? PRIMARY_COLOR : SECONDARY_COLOR}
              strokeWidth={2}
            />
          </Group>
        )}
        {reference && (
          <Reference
            left={xScale(reference)}
            top={0}
            size={dualView ? 56 : 14}
          />
        )}
      </Group>
    </svg>
  );
};

export default React.memo(PlotGraph);
