import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Inline,
  Text,
  Stack,
  Button,
  IconBox,
  Divider,
} from "@compono/ui";
import { getMilestoneDateFormat } from "../../../shared/helpers";
import { Milestone } from "../../../types";
import {
  DiamondSolidIcon,
  ChevronCircleDownSolidIcon,
  ChevronCircleUpSolidIcon,
} from "@compono/ui-icons";

const EventsList = ({ milestones }: { milestones: Milestone[] }) => {
  const initialMilestonesToShow =
    milestones?.length > 5 ? milestones.slice(0, 5) : milestones;
  const [milestonesToShow, setMilestonesToShow] = useState<Milestone[]>(
    initialMilestonesToShow
  );
  const [showMore, setShowMore] = useState<boolean>(false);

  const readMoreClicked = (show: boolean) => {
    setShowMore(show);
    setMilestonesToShow(show ? milestones : initialMilestonesToShow);
  };

  useEffect(() => {
    setMilestonesToShow(showMore ? milestones : initialMilestonesToShow);
  }, [initialMilestonesToShow]);

  return (
    <Box>
      <Flex
        sx={{
          height: "40px",
          backgroundColor: "black.10",
          alignItems: "center",
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          justifyContent: "center",
          border: "1px solid var(--colors-black-20)",
          borderBottom: 0,
        }}
      >
        <Text>
          <b>
            {milestones?.length} important{" "}
            {milestones?.length > 1 ? "events" : "event"} leading up to this
            assessment
          </b>
        </Text>
      </Flex>
      <Box
        sx={{
          padding: "8px 20px",
          border: "1px solid var(--colors-black-20)",
          borderTop: 0,
        }}
      >
        {milestonesToShow.map((milestone) => (
          <Inline>
            <Stack sx={{ width: "280px" }}>
              <Inline gap="3">
                <Text>{getMilestoneDateFormat(milestone.startDate)}</Text>
                {milestone.endDate && <Text>-</Text>}
                {milestone.endDate && (
                  <Text>{getMilestoneDateFormat(milestone.endDate)}</Text>
                )}
              </Inline>
            </Stack>
            <Inline gap="2">
              <DiamondSolidIcon color="var(--colors-success-60)" />
              <Text>{milestone.description}</Text>
            </Inline>
          </Inline>
        ))}
        {milestones?.length > 5 && (
          <>
            <Box sx={{ margin: "10px -20px" }}>
              <Divider orientation="horizontal" />
            </Box>
            <Flex sx={{ justifyContent: "center" }}>
              {!showMore && (
                <Button look="link" onClick={() => readMoreClicked(true)}>
                  Show more{" "}
                  <IconBox
                    sx={{ marginTop: "-1px" }}
                    icon={ChevronCircleDownSolidIcon}
                  />
                </Button>
              )}
              {showMore && (
                <Button look="link" onClick={() => readMoreClicked(false)}>
                  Show less{" "}
                  <IconBox
                    sx={{ marginTop: "-1px" }}
                    icon={ChevronCircleUpSolidIcon}
                  />
                </Button>
              )}
            </Flex>
          </>
        )}
      </Box>
    </Box>
  );
};

export default EventsList;
