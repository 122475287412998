import { ThemeUIStyleObject } from "@compono/ui-theme";

export const containerStyle: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 6,
};

export const iconStyle = {
  color: "black.20",
  my: 6,
};

export const titleStyle = {
  mb: 4,
  font: "title",
};
export const descriptionStyle: ThemeUIStyleObject = {
  textAlign: "center",
};
