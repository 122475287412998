import { Box } from "@compono/ui";
import styled from "@emotion/styled";
import { ThemeUIStyleObject } from "@compono/ui-theme";

/** @todo get rid of all the styled components */
export const CircleA = styled.div`
  display: flex;
  justify-content: center;
  background: var(--colors-brandPurple-80);
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const CircleB = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: var(--colors-brandDark-80) 4px solid;
`;

export const CampaignDataCard = styled(Box as any)`
  padding: 8px 0px;
  margin-top: 8px;
  max-width: 290px;
  border-radius: 6px;
  border: 1px solid transparent;
  background: var(--colors-black-3);
`;

export const VerticalDivisionLine = styled.div`
  width: 1px;
  height: 104px;
  background-color: var(--colors-black-10);
  margin: 20px 19px;
`;

export const insightCardHeader: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  py: 5,
  px: 6,
};

export const insightCardHeaderRef = {
  ...insightCardHeader,
  justifyContent: "flex-start",
};

export const circleTextA: ThemeUIStyleObject = {
  fontSize: "md",
  color: "white",
  alignSelf: "center",
};

export const circleTextB: ThemeUIStyleObject = {
  fontSize: "md",
  color: "black.60",
  alignSelf: "center",
};

export const referenceIcon = {
  color: "black.60",
  mr: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 32,
  width: 32,
  fontSize: "xl",
};

export const referentText = {
  fontSize: "sm",
  color: "black.100",
  fontWeight: 500,
};
export const selectDropdown = {
  width: "450px",
  backgroundColor: "white",
  "&[data-direction=down] [data-dropdown]": {
    borderRadius: 3,
    borderTop: "1px solid",
    borderColor: "black.20",
  },
  "&[data-direction=up] [data-dropdown]": {
    borderRadius: 3,
    borderBottom: "1px solid",
    borderColor: "black.20",
  },
};

export const selectedCampaignData = {
  ml: "292px",
  mt: "310px",
  backgroundColor: "white",
  borderRadius: 3,
};

export const headerText = {
  bg: "white",
  justifyContent: "center",
  alignItems: "center",
  height: "53px",
};

export const headerTextCustom = {
  ...headerText,
  display: "flex",
  bg: "white",
  justifyContent: "space-between",
  border: "2px solid red",
};

export const campaignsSelection = {
  display: "flex",
  height: "77px",
  alignItems: "center",
  p: 6,
};

export const completedText = {
  fontWeight: "bold",
  color: "black.100",
  alignSelf: "center",
  pl: 4,
};

export const pillStyle = {
  fontSize: "xs",
  color: "black.80",
  mr: 3,
};

export const pillText = {
  alignSelf: "center",
  fontSize: "sm",
};

export const assessmentDesiredOption = {
  alignSelf: "center",
  color: "black.80",
};

export const assessmentCurrentOption = {
  ...assessmentDesiredOption,
  pr: 5,
};

export const radioButton = {
  alignSelf: "center",
  pr: 3,
};

export const addFilterButton = {
  my: 5,
  py: 3,
  px: 5,
};

export const selectThisDivision = {
  cursor: "pointer",
  py: 5,
  px: 5,
  backgroundColor: "white",
};

export const selectOptions = {
  height: "100%",
  overflow: "auto",
};

export const selectOption = {
  "[data-icon]": { color: "black.60" },
};

export const container: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  maxWidth: "1375px",
  backgroundColor: "black.3",
};

export const contentStyle: ThemeUIStyleObject = {
  backgroundColor: "white",
  padding: "20px",
  margin: "20px",
};

export const errorMessageContainerStyle: ThemeUIStyleObject = {
  backgroundColor: "white",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "black.10",
  width: "636px",
  borderRadius: 3,
};

export const nothingToShowMessage: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  backgroundColor: "white",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "black.10",
  width: "636px",
  borderRadius: 3,
};

export const nothingToShowIcon = {
  color: "black.20",
  my: 7,
};

export const nothingToShowText = {
  mb: 4,
  font: "title",
};

export const nothingToShowFooterText: ThemeUIStyleObject = {
  textAlign: "center",
  maxWidth: "70%",
};

export const graphGrid = {
  display: "grid",
  gridTemplateColumns: "19% 62% 19%",
  p: 1,
};

export const leftElement: ThemeUIStyleObject = {
  p: 5,
  display: "flex",
  justifyContent: "left",
  whiteSpace: "normal",
  textAlign: "left",
  fontWeight: "normal",
};

export const rightElement: ThemeUIStyleObject = {
  ...leftElement,
  justifyContent: "right",
  textAlign: "right",
};

export const scalesStyle = {
  borderRadius: 5,
  backgroundColor: "white",
};

export const cultureScale = {
  mt: 4,
  alignSelf: "center",
};

export const observeContainerStyle = {
  px: 5,
  mb: 6,
};
export const observeHeaderContainerStyle = {
  display: "flex",
  alignItems: "center",
};
export const observeIconStyle = {
  display: "inline-flex",
  color: "brandPurple.80",
  mr: 3,
};
export const bulletPointStyle = {
  display: "flex",
  alignItems: "flex-start",
  gap: 2,
};
export const graphContainerStyle: ThemeUIStyleObject = {
  mt: 3,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
};

export const closeButtonStyle: ThemeUIStyleObject = {
  pointerEvents: "auto",
  ml: "auto",
  mb: 4,
};
