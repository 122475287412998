import { CULTURE_FIT_QUESTIONS } from "@compono/assessments-kit";
import {
  Heading,
  Button,
  Dialog,
  DialogHeading,
  DialogBody,
  DialogPrimaryActions,
  DialogCloseButton,
  Card,
  Inline,
} from "@compono/ui";
import React from "react";
import { GreyBox } from "./SpareParts";

export const DimensionExplainerDialog = ({
  dimensionOpen,
  setDimensionOpen,
  type,
}) => {
  const [left, right] = CULTURE_FIT_QUESTIONS[type];
  const [leftHeading, leftText] = left;
  const [rightHeading, rightText] = right;
  return (
    <Dialog
      isOpen={dimensionOpen}
      aria-describedby="dialog-description"
      aria-labelledby="dialog-title"
    >
      <DialogHeading>What's this?</DialogHeading>
      <DialogBody>
        <Card
          sx={{ "background-color": `var(--colors-black-10)`, p: 20, mb: 6 }}
        >
          <Inline alignX="center">
            <b>{leftHeading + " vs " + rightHeading}</b>
          </Inline>
        </Card>
        <Heading level="6">{leftHeading}</Heading>
        <p>{leftText}</p>
        <Heading level="6">{rightHeading}</Heading>
        <p>{rightText}</p>
      </DialogBody>
      <DialogPrimaryActions>
        <Button
          onClick={() => {
            setDimensionOpen(false);
          }}
        >
          OK
        </Button>
      </DialogPrimaryActions>
      <DialogCloseButton
        onClick={() => {
          setDimensionOpen(false);
        }}
      />
    </Dialog>
  );
};
