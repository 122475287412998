import {
  Dialog,
  DialogHeading,
  DialogBody,
  DialogCloseButton,
  Box,
} from "@compono/ui";
import React from "react";
import { parseKeyToJson } from "../Explorer/InsightsGraph";
import { getChangeSentence, getHeading } from "./utils";
import GraphCard from "./GraphCard";
import Tips from "./Tips";

export const InsightsDialog = ({
  dialogOpen,
  setDialogOpen,
  dialogHeader,
  currentResponses,
  desiredResponses,
  dimension,
  observations,
}) => {
  return (
    <Dialog
      isOpen={dialogOpen}
      aria-describedby="dialog-description"
      aria-labelledby="dialog-title"
      size="lg"
    >
      <DialogHeading id="dialog-title">{dialogHeader}</DialogHeading>
      <DialogBody id="dialog-description">
        {/* {JSON.stringify(currentResponses)} */}
        {currentResponses && desiredResponses && (
          <>
            <Box sx={{ mb: 5 }}>
              {getChangeSentence(
                desiredResponses.average[dimension],
                currentResponses.average[dimension],
                getHeading(dimension).left,
                getHeading(dimension).right
              )}
            </Box>
            <GraphCard
              dimension={dimension}
              average={currentResponses.average[dimension]}
              results={currentResponses.results[dimension]}
              totalResults={currentResponses.totalResults}
              current={true}
            ></GraphCard>
            <Box sx={{ height: 6 }}></Box>
            <GraphCard
              dimension={dimension}
              average={desiredResponses.average[dimension]}
              results={desiredResponses.results[dimension]}
              totalResults={desiredResponses.totalResults}
              current={false}
            ></GraphCard>
            <Tips
              observation={parseKeyToJson(
                observations,
                dimension,
                currentResponses.average[dimension]
              )}
            ></Tips>
          </>
        )}
      </DialogBody>
      <DialogCloseButton
        onClick={() => {
          setDialogOpen(false);
        }}
      />
    </Dialog>
  );
};
