export const differenceSentenceStyle = {
  pl: 8,
  fontSize: "sm",
  mt: 4,
};
export const strongAlignmentStyle = {
  ...differenceSentenceStyle,
  color: "var(--colors-black-100)",
};
export const primaryHeadingStyle = {
  color: "var(--colors-black-100)",
  fontWeight: 700,
};
