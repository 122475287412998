import { SelectableOptionT } from "@compono/ui";
import { ThemeUIStyleObject } from "@compono/ui-theme";
import { Campaign } from "../../../types";

export type ProviderProps = {
  children?: any;
};

export interface InsightsCardProps {
  header?: any;
  content?: any;
  shadow?: boolean;
  height?: string;
  divisionLine?: boolean;
  onSelectCampaign?: (campaign: Campaign) => void;
}

export interface CampaignSelectProps {
  onSelectCampaign: (campaign: Campaign) => void;
}

export type TreeItem = {
  label?: string;
  value?: string;
  hasChildOptions?: boolean;
  children?: [any];
};

export type CultureResult = {
  results: {
    riskTolerance: number[];
    driver: number[];
    focus: number[];
    teamWork: number[];
    control: number[];
    structure: number[];
    responsivePlanned: number[];
    conformity: number[];
    accepting: number[];
    cooperation: number[];
    internalExternal: number[];
    formal: number[];
  };
  totalResults: number;
  average: {
    riskTolerance: number;
    driver: number;
    focus: number;
    teamWork: number;
    control: number;
    structure: number;
    responsivePlanned: number;
    conformity: number;
    accepting: number;
    cooperation: number;
    internalExternal: number;
    formal: number;
  };
};

export type Response = {
  cultureAssessmentResult: CultureResult;
};

export type Option = SelectableOptionT & {
  hasChildOptions?: boolean;
  children: Option[];
};

export type Opts = { children: Option[] };

export interface FiltersSelectProps {
  selectedCampaign: Campaign;
  onAddFilter?: (
    option: SelectableOptionT | undefined,
    level?: string | SelectableOptionT,
    demographic?: string
  ) => void;
  buttonDisabled: boolean;
  showIcon?: boolean;
}

export interface ExplorerFilter {
  demographic: string;
  filterType: InsightsFilterType;
  filters: Array<{ label: string; option: string }>;
}

export interface InsightsFilter {
  level: SelectableOptionT | string;
  options: string[];
}

export enum InsightsFilterType {
  JOB_TITLE = "jobTitle",
  JOB_LEVEL = "jobLevel",
  TEAM = "team",
  DIVISIONS = "divisions",
  TENURE = "tenure",
  OFFICE_LOCATION = "officeLocation",
  GROUPS = "groups",
}

export interface InsightsFilterInput {
  filterType: InsightsFilterType;
  options: string[];
}

export interface InsightsDivision {
  label: string;
  value: string;
  options: string[];
}

export interface FilterOption {
  path: string | undefined;
  value: string;
}

export interface InsightsGraphProps {
  seriesA?: CultureResult;
  seriesB?: CultureResult;
  reference?: CultureResult;
  showObservation?: boolean;
  selectedCampaignDueDate?: string;
  selectedRefCampaignDueDate?: string;
  dualView?: boolean;
  graphSx?: ThemeUIStyleObject;
  isReportGraph?: boolean;
}

export enum InsightsCampaignType {
  A = "A",
  B = "B",
  Ref = "Reference",
}
