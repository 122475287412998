import {
  Box,
  Card,
  IconButton,
  Inline,
  Stack,
  ToggleTip,
  Direction,
  Text,
} from "@compono/ui";
import ViolinPlot from "../../../components/PlotGraph/Violinplot";
import React from "react";
import { ThemeUIStyleObject } from "@compono/ui-theme";
import { QuestionMarkCircleOutlineIcon } from "@compono/ui-icons";
import { CULTURE_FIT_QUESTIONS } from "@compono/assessments-kit";
const leftElement: ThemeUIStyleObject = {
  p: 1,
  display: "flex",
  justifyContent: "left",
  whiteSpace: "normal",
  textAlign: "left",
  fontWeight: "normal",
};

const rightElement: ThemeUIStyleObject = {
  ...leftElement,
  justifyContent: "right",
  textAlign: "right",
  pr: 4,
};
const ViolinCard = ({
  dimension,
  markers,
  markersB,
  violinData,
  violinBData,
  toggleTipDirection = "up",
}) => {
  const [left, right] = CULTURE_FIT_QUESTIONS[dimension];
  const [leftHeading, leftText] = left;
  const [rightHeading, rightText] = right;
  return (
    <Card key={dimension} border elevation={"2"} sx={{ mb: 5 }}>
      <Box
        sx={{
          m: 0,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "23% 55% 23%",
            p: 1,
          }}
        >
          <Box sx={leftElement}>
            <Inline alignY="center">
              <Box>
                <ToggleTip
                  label="InformationCircleOutlineIcon"
                  icon={<QuestionMarkCircleOutlineIcon />}
                  direction={toggleTipDirection}
                >
                  {leftText}
                </ToggleTip>
              </Box>
              {leftHeading}
            </Inline>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Stack>
              <ViolinPlot
                markers={markers}
                violinData={
                  violinData && violinData.length >= 5 ? violinData : undefined
                }
              />
              {markersB && (
                <ViolinPlot
                  markers={markersB}
                  violinData={
                    violinBData && violinBData.length >= 5
                      ? violinBData
                      : undefined
                  }
                />
              )}
            </Stack>
          </Box>
          <Box sx={rightElement}>
            <Inline alignY="center">
              {rightHeading}
              <Box>
                <ToggleTip
                  label="InformationCircleOutlineIcon"
                  icon={<QuestionMarkCircleOutlineIcon />}
                  direction={toggleTipDirection}
                >
                  {rightText}
                </ToggleTip>
              </Box>
            </Inline>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
export default ViolinCard;
