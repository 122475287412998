import { Box, Inline, ToggleTip } from "@compono/ui";
import React, { useRef, useState } from "react";
import LineMarker from "../PlotGraph/markers/LineMarker";
import { calculateMarkerPosition, calculateWidth } from "./utils";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
export const color1 = "#FF2E2E";
export const color2 = "#F97316";
export const color3 = "#FFE29B";
export const color4 = "#93E0D2";
export const color5 = "#14B8A6";

export type BarItem = {
  value: number;
  color: string;
  content?: ReactElement<IconProps>;
};

type BarStackChartProps = {
  width?: number;
  height?: number;
  items: BarItem[];
  cardBottomView?: boolean;
  markerValue?: number;
};

const HoverItem = ({ width, sx, tooltipContent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  return (
    <Box
      sx={{
        ...sx,
        width,
      }}
      onMouseOver={() => {
        setIsOpen(true);
      }}
      onMouseOut={() => {
        setIsOpen(false);
      }}
    >
      <ToggleTip
        label="Default application"
        isOpen={isOpen}
        onExpanded={() => {
          setIsOpen(true);
        }}
        icon={<></>}
        buttonRef={buttonRef}
      >
        {tooltipContent}
      </ToggleTip>
    </Box>
  );
};

const BarStackChart = ({
  height = 20,
  items,
  cardBottomView = false,
  markerValue = 0,
}: BarStackChartProps) => {
  const sx = {
    height: `${height}px`,
    "text-align": "center",
  };
  const leftSx = cardBottomView
    ? { ...sx, "border-radius": "0 0 0 6px" }
    : { ...sx, "border-radius": "5px 0 0 5px" };
  const rightSx = cardBottomView
    ? { ...sx, "border-radius": "0 0 6px 0" }
    : { ...sx, "border-radius": "0 5px 5px 0" };
  const singleSx = cardBottomView
    ? { ...sx, "border-radius": "0 0 6px 6px" }
    : { ...sx, "border-radius": "5px 5px 5px 5px" };

  const { total, minIndex, maxIndex } = items.reduce(
    (accumulator, currentValue, currentIndex) => {
      return {
        total: accumulator.total + currentValue.value,
        minIndex:
          accumulator.minIndex === undefined && currentValue.value > 0
            ? currentIndex
            : accumulator.minIndex,
        maxIndex: currentValue.value > 0 ? currentIndex : accumulator.maxIndex,
      };
    },
    { total: 0, minIndex: undefined, maxIndex: 0 }
  );

  return (
    <ParentSize ignoreDimensions={["height"]}>
      {({ width: w }) => {
        const markerPosition = calculateMarkerPosition(markerValue, w);
        const hasDecimal = markerValue - Math.floor(markerValue) !== 0;
        return (
          <Box>
            <Box sx={{ pt: 3, position: "absolute", zIndex: 2 }}>
              <Inline>
                {items.map((item: BarItem, index) => {
                  let itemSx = sx;
                  if (index === minIndex) itemSx = leftSx;
                  if (index === maxIndex) itemSx = rightSx;
                  if (index === minIndex && maxIndex === minIndex)
                    itemSx = singleSx;

                  return (
                    <HoverItem
                      width={calculateWidth(item.value, total, w)}
                      sx={itemSx}
                      tooltipContent={item.content}
                    ></HoverItem>
                  );
                })}
              </Inline>
            </Box>
            {markerValue ? (
              <Box sx={{ position: "absolute" }}>
                <svg width={w} height={height + 10}>
                  <LineMarker position={markerPosition}></LineMarker>
                </svg>
              </Box>
            ) : (
              <></>
            )}
            <Box sx={{ pt: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  "flex-direction": "row",
                  "justify-content": "flex-start",
                }}
              >
                {items.map((item: BarItem, index) => {
                  let itemSx = sx;
                  if (index === minIndex) itemSx = leftSx;
                  if (index === maxIndex) itemSx = rightSx;
                  if (index === minIndex && maxIndex === minIndex)
                    itemSx = singleSx;

                  return (
                    <Box
                      sx={{
                        ...itemSx,
                        "background-color": item.color,
                        width: calculateWidth(item.value, total, w),
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box sx={{ ml: markerPosition - (hasDecimal ? 10 : 4) + "px" }}>
              {markerValue}
            </Box>
          </Box>
        );
      }}
    </ParentSize>
  );
};

export default BarStackChart;
