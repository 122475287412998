export const calculatePercentage = (value: number, total: number) => {
  return (100 / total) * value;
};

export const calculateWidth = (
  value: number,
  total: number,
  totalWidth: number
) => {
  const percentage = calculatePercentage(value, total);
  const width = (totalWidth / 100) * percentage + "px";
  return width;
};
export const calculateMarkerPosition = (value: number, totalWidth: number) => {
  const percentage = calculatePercentage(value - 1, 4);
  const width = (totalWidth / 100) * percentage;
  if (width >= totalWidth - 5) return totalWidth - 5;
  if (width <= 5) return 5;
  return width;
};
