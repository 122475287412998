import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  Heading,
  Inline,
  Loader,
  Select,
  Stack,
} from "@compono/ui";
import { AssessmentType } from "../../../types";
import { QuestionMarkCircleOutlineIcon } from "@compono/ui-icons";
import { HowToDialog } from "./HowToDialog";
import { getFilterLabel } from "./utils";
import FilterList from "./FilterList";
import DimensionGraphs from "./DimensionGraphs";
import { Filter } from "./CultureBreakdown";

// constants
export const dimensions = [
  "innovative|cautious",
  "process|outcome",
  "task centred|employee centred",
  "collective|individual",
  "centralised control|delegated control",
  "hierarchical structure|horizontal structure",
  "ad-hoc|planned",
  "conformity|individuality",
  "accepting|questioning",
  "cooperation|competition",
  "external|internal",
  "formalised|informal",
];

export const BreakdownContent = ({
  loading = false,
  filterType,
  setFilterType,
  setAssessmentType,
  filters,
  setFilters,
}) => {
  const [howToOpen, setHowToOpen] = useState(false);
  const [markers, setMarkers] = useState<any[]>([]);

  useEffect(() => {
    const updatedMarkers = filters
      .filter((filter: Filter) => filter.selected)
      .map((filter: Filter) => {
        return {
          filterName: filter.name,
          color: filter.color,
          values: filter.values,
        };
      });
    setMarkers(updatedMarkers);
  }, [filters]);
  return (
    <Box
      sx={{
        backgroundColor: "black.3",
      }}
    >
      <Box
        sx={{
          marginLeft: "10px",
          maxWidth: "1440px",
        }}
      >
        <Inline>
          <Select
            sx={{ m: 3, width: "200px" }}
            onChange={(option) => {
              setFilterType(option.target.value);
            }}
          >
            <option value="divisions">Division</option>
            <option value="officeLocation">Location</option>
            <option value="jobLevel">Job Level</option>
          </Select>
          <ButtonGroup
            rounded
            defaultSelected="default-button"
            name="rounded-group"
            size="sm"
          >
            <Button
              id="default-button"
              onClick={() => {
                setAssessmentType(AssessmentType.Current);
              }}
            >
              Current culture
            </Button>
            <Button
              onClick={() => {
                setAssessmentType(AssessmentType.Desired);
              }}
            >
              Desired culture
            </Button>
          </ButtonGroup>
        </Inline>
        {loading ? (
          <Card sx={{ p: 0 }}>
            <Inline alignX="center">
              <Loader label={"Loading"} size="lg"></Loader>
            </Inline>
          </Card>
        ) : (
          <Card border sx={{ m: 3, p: 0, maxWidth: "1440px" }}>
            <Box sx={{ m: 5 }}>
              <Inline alignX="apart">
                <Heading level="5">Overview</Heading>
                <Button
                  look="naked"
                  iconLeft={QuestionMarkCircleOutlineIcon}
                  onClick={() => {
                    setHowToOpen(true);
                  }}
                >
                  How does this work?
                </Button>
              </Inline>
            </Box>
            <Divider orientation={"horizontal"} />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 0.4fr",
              }}
            >
              <Box sx={{ p: 2 }}>
                <DimensionGraphs dimensions={dimensions} markers={markers} />
              </Box>
              <Stack
                sx={{
                  "border-left": "1px solid var(--colors-black-10)",
                  "max-width": "1000px",
                }}
              >
                <FilterList
                  filters={filters}
                  heading={getFilterLabel(filterType)}
                  setFilters={setFilters}
                ></FilterList>
              </Stack>
            </Box>
          </Card>
        )}

        <HowToDialog
          howToOpen={howToOpen}
          setHowToOpen={setHowToOpen}
        ></HowToDialog>
      </Box>
    </Box>
  );
};
