import {
  Heading,
  Box,
  Button,
  Dialog,
  DialogHeading,
  DialogBody,
  DialogPrimaryActions,
  DialogCloseButton,
  Card,
  Inline,
} from "@compono/ui";
import ViolinPlot from "../../../components/PlotGraph/Violinplot";
import React from "react";
import { QuestionMarkCircleOutlineIcon } from "@compono/ui-icons";
const leftElement: ThemeUIStyleObject = {
  p: 2,
  display: "flex",
  justifyContent: "left",
  whiteSpace: "normal",
  textAlign: "left",
  fontWeight: "normal",
};

const rightElement: ThemeUIStyleObject = {
  ...leftElement,
  justifyContent: "right",
  textAlign: "right",
};

export const HowToDialog = ({ howToOpen, setHowToOpen }) => (
  <Dialog
    isOpen={howToOpen}
    aria-describedby="dialog-description"
    aria-labelledby="dialog-title"
  >
    <DialogHeading>How does this work?</DialogHeading>
    <DialogBody>
      <Heading level="6">Subcultures</Heading>
      <p>
        The culture breakdowns provided helps you discover subcultures within
        your organisation. For each of the 12 dimensions of culture you can see
        how culture varies across different divisions, locations and job levels.
      </p>
      <p>
        Within each dimension, a marker is plotted to represent the culture of
        the demographic in question. In the example below, the red marker
        represents the current culture of the product division whereas the blue
        marker represents the culture of the finance division.
      </p>

      <Card border elevation="0" sx={{ mb: 5 }}>
        <Box
          sx={{
            m: 0,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "24% 52% 24%",
              p: 1,
            }}
          >
            <Box sx={leftElement}>
              <Box sx={{ mt: "3px", mr: 2 }}>
                <QuestionMarkCircleOutlineIcon></QuestionMarkCircleOutlineIcon>
              </Box>
              Innovative
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ViolinPlot
                markers={[
                  {
                    color: "red",
                    type: "line",
                    position: 1.2,
                  },
                  {
                    color: "blue",
                    type: "line",
                    position: 3.6,
                  },
                ]}
              />
            </Box>
            <Box sx={rightElement}>
              Risk averse
              <Box sx={{ mt: "3px", ml: 2 }}>
                <QuestionMarkCircleOutlineIcon></QuestionMarkCircleOutlineIcon>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>

      <Card sx={{ "background-color": `var(--colors-black-3)`, p: 20, mb: 6 }}>
        <Inline alignX="center">
          <Box
            sx={{
              display: "flex",
              width: "12px",
              height: "12px",
              "background-color": "red",
              "border-radius": "50%",
              mr: 2,
            }}
          ></Box>
          <b>Product</b>
          <Box
            sx={{
              display: "flex",
              width: "12px",
              height: "12px",
              "background-color": "blue",
              "border-radius": "50%",
              ml: 5,
              mr: 2,
            }}
          ></Box>
          <b>Engineering</b>
        </Inline>
      </Card>
      <p>
        The further apart the markers are, the bigger the difference in culture
        for that dimension. The closer the markers are, the more similar the
        cultures are.
      </p>
      <p>
        Use the options provided on the screen to change the view from division
        to location or job level, or toggle from current to desired culture.
      </p>
    </DialogBody>
    <DialogPrimaryActions>
      <Button
        onClick={() => {
          setHowToOpen(false);
        }}
      >
        OK
      </Button>
    </DialogPrimaryActions>
    <DialogCloseButton
      onClick={() => {
        setHowToOpen(false);
      }}
    />
  </Dialog>
);
