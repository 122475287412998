import React from "react";
import { Group } from "@visx/group";
import { GlyphCircle } from "@visx/glyph";
import { PRIMARY_COLOR } from "../Violinplot";

const circleSize = 24;
const height = 40;

const PrimaryMarker = ({ color = PRIMARY_COLOR, position }) => {
  return (
    <Group>
      <clipPath id="overlap1">
        <rect
          width={circleSize / 2}
          height={circleSize}
          transform={`translate(-${circleSize / 2}, -${circleSize / 2})`}
        ></rect>
      </clipPath>
      <GlyphCircle
        left={position}
        top={height / 2}
        size={circleSize * 10}
        fill={color}
        stroke={color}
        strokeWidth={2}
        fillOpacity={1}
        strokeOpacity={1}
      />
    </Group>
  );
};
export default PrimaryMarker;
