import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import formatDuration from "date-fns/formatDuration";
import differenceInDays from "date-fns/differenceInDays";
import intervalToDuration from "date-fns/intervalToDuration";
import { formatDistanceStrict } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const titleCase = (text: string): string =>
  text
    .split(" ")
    .filter(Boolean)
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(" ");

type Location = {
  city?: string;
  state?: string;
  country: string;
};

export const formatLocation = ({ city, state, country }: Location) =>
  city && state
    ? `${city}, ${state}`
    : [city, state, country].filter(Boolean as any).join(", ");

type Name = {
  firstName: string;
  lastName: string;
};

export const formatName = ({ firstName, lastName }: Name) =>
  titleCase(`${firstName} ${lastName}`);

export const formatDay = (createdAt?: string) =>
  createdAt ? format(parseISO(createdAt), "EEE, d MMM yyyy") : null;

export const formatDate = (createdAt?: string) =>
  createdAt ? format(parseISO(createdAt), "d MMM yyyy") : null;

export const formatDateTime = (createdAt?: string) =>
  createdAt ? format(parseISO(createdAt), "d MMM yyyy, h:mmaaaaa'm'") : null;

export const formatTime = (createdAt?: string) =>
  createdAt ? format(parseISO(createdAt), "h:mmaaaaa'm'") : null;

export const formatDateTimeFromDate = (createdAt?: Date) =>
  createdAt ? format(createdAt, "EEE d MMM yyyy, h:mmaaaaa'm'") : null;

export const formatDateFromDate = (createdAt?: Date) =>
  createdAt ? format(createdAt, "d MMM yyyy") : null;

export const formatDateFromDateWithTime = (createdAt?: Date) =>
  createdAt ? format(createdAt, "d MMM yyyy hh:mm a") : null;

export const formatTimeFromDate = (createdAt?: Date) =>
  createdAt ? format(createdAt, "h:mmaaaaa'm'") : null;

export const formatDateWithTime = (createdAt?: Date) =>
  createdAt ? format(new Date(createdAt), "dd/MM/yyyy hh:mm:ss a") : null;

export const formatDateWithTimeNoSecs = (createdAt?: Date) =>
  createdAt ? format(new Date(createdAt), "dd/MM/yyyy hh:mm a") : null;

export const formatDifferenceDate = (createdAt: string, now = new Date()) => {
  if (!createdAt) return "";
  const date = parseISO(createdAt);
  return differenceInDays(date, now) > -7
    ? formatDistanceStrict(date, now, {
        addSuffix: true,
        roundingMethod: "round",
      })
    : formatDate(createdAt);
};

export const getFormattedDate = (
  createdAt: string | Date | null,
  timezone?: string
) => {
  if (!createdAt) return "";
  return timezone
    ? format(utcToZonedTime(createdAt, timezone), "dd/MM/yyyy")
    : format(createdAt ? new Date(createdAt) : new Date(), "dd/MM/yyyy");
};

export const formatMonth = (date?: string) =>
  format(date ? parseISO(date) : new Date(), "MMMM yyyy");

export const formatMinutes = (minutes = 0) =>
  formatDuration(intervalToDuration({ start: 0, end: minutes * 60 * 1000 }));
