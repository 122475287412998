import { ThemeUIStyleObject } from "@compono/ui-theme";

export const containerStyle = {
  borderRadius: 3,
  border: "1px solid var(--colors-black-10)",
  marginBottom: 5,
  backgroundColor: "white",
};
export const insightCardHeader: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  py: 5,
  px: 6,
};

export const campaignTitleText = {
  fontSize: "sm",
  color: "black.60",
  mb: 4,
};

export const cultureTitleText = {
  ...campaignTitleText,
  mt: 5,
};

export const responsesTitleText = {
  ...campaignTitleText,
  fontSize: "xs",
  pt: 5,
  mb: 0,
};

export const campaignNamePillText = {
  fontSize: "md",
  backgroundColor: "primary.5",
  color: "black.80",
  py: 5,
  m: 1,
  borderRadius: 3,
  overflow: "hidden",
};

export const filterLevelText = {
  ...campaignNamePillText,
  overflow: "visible",
  backgroundColor: "transparent",
  m: 0,
  my: 1,
  p: 1,
};

export const campaignDataCard = {
  display: "flex",
  justifyContent: "space-between",
  mx: 3,
  alignItems: "center",
};

export const campaignDataCardInner: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  color: "black.60",
  width: "86px",
  height: "36px",
  alignItems: "center",
};

export const campaignDataCardText = {
  fontSize: "xs",
  pb: 2,
};

export const campaignDataCardTextValue = {
  fontSize: "xs",
};

export const campaignDataCardTextLowValue = {
  ...campaignDataCardTextValue,
  color: "warning.60",
};

export const discardCampaignButton = {
  width: "14px",
  pt: 1,
  pr: 5,
  "&:hover": {
    cursor: "Pointer",
  },
};
