import { defaultTheme } from "@shortlyster/ui-kit";

/**
 * Extend the default UI-Kit theme with some deprecated
 * styles required for Forms-Kit
 */

const fontSize = 16;

export const themeOverrides = {
  colors: {
    accent1: defaultTheme.global.colors["accent-1"],
    light4: defaultTheme.global.colors["light-4"],
  },
  slider: {
    handleHeight: 2.5,
    handleWidth: 3.5,
    fontSize: 16 / fontSize,
    sliderHeight: 60 / fontSize,
    trackHeight: 14 / fontSize,
    tickHeight: 8 / fontSize,
    fillerWidth: 26 / fontSize,
    handleArrowSize: 0.3,
  },
};
