import { gql } from "@apollo/client";

export const GetCampaigns = gql`
  query Campaigns($status: String) {
    campaigns(status: $status) {
      sk
      name
      dueDate
      createdDate
      status
      completionRate
      lastSaved
      responses
      respondents
      purpose
      closedDate
      hasPlaceholders
      superCharged
      milestones {
        description
        startDate
        endDate
      }
      assessments {
        type
        sk
      }
      publishDate
      filters {
        tenure
        jobTitle
        jobLevel
        officeLocation
        divisions
        groups
      }
    }
  }
`;

export const CultureAssessments = gql`
  query GetCultureAssessmentResult(
    $assessmentSk: String!
    $filters: [InsightsFilterInput]
  ) {
    cultureAssessmentResult(assessmentSk: $assessmentSk, filters: $filters) {
      results {
        riskTolerance
        driver
        focus
        teamWork
        control
        structure
        responsivePlanned
        conformity
        accepting
        cooperation
        internalExternal
        formal
      }
      average {
        riskTolerance
        driver
        focus
        teamWork
        control
        structure
        responsivePlanned
        conformity
        accepting
        cooperation
        internalExternal
        formal
      }
      totalResults
    }
  }
`;

export const MESSAGES_FRAGMENT = gql`
  fragment Messages on ObservationInfo {
    heading
    text
    summaryPros
    summaryCons
    prosTitle
    consTitle
    learnMoreTitle
    learnMoreLink
    fullPros
    fullCons
    tips
    tipsTitle
  }
`;

export const GetObservations = gql`
  query observations {
    observations {
      pk
      sk
      messages {
        left {
          heading
          text
          summaryPros
          summaryCons
          prosTitle
          consTitle
          learnMoreTitle
          learnMoreLink
          fullPros
          fullCons
          tips
          tipsTitle
        }
        slightLeft {
          heading
          text
          summaryPros
          summaryCons
          prosTitle
          consTitle
          learnMoreTitle
          learnMoreLink
          fullPros
          fullCons
          tips
          tipsTitle
        }
        middle {
          heading
          text
          summaryPros
          summaryCons
          prosTitle
          consTitle
          learnMoreTitle
          learnMoreLink
          fullPros
          fullCons
          tips
          tipsTitle
        }
        slightRight {
          heading
          text
          summaryPros
          summaryCons
          prosTitle
          consTitle
          learnMoreTitle
          learnMoreLink
          fullPros
          fullCons
          tips
          tipsTitle
        }
        right {
          heading
          text
          summaryPros
          summaryCons
          prosTitle
          consTitle
          learnMoreTitle
          learnMoreLink
          fullPros
          fullCons
          tips
          tipsTitle
        }
      }
    }
  }
`;
