type EmptyObject = {
  [index: string]: any;
};

export const calculatePercentage = (value: number, outOf: number): number =>
  Math.round((value / (outOf || 1)) * 100);

export const addApostrophy = (
  word: string // eslint-disable-next-line no-nested-ternary
) => (word ? (word.slice(-1) === "s" ? `${word}'` : `${word}'s`) : "");

/**
 * Returns a copy of an object including only the specified keys
 * e.g. pick({ a: 1, b: 2, c: 3 }, ['c']) => { c: 3 }
 */
export const pick = (obj: EmptyObject, keys: string[]) => {
  const sourceKeys = Object.keys(obj);

  return keys.reduce((result: EmptyObject, key: string) => {
    if (sourceKeys.includes(key)) result[key] = obj[key];
    return result;
  }, {});
};

/* Iterate a fixed number of times (see lodash.times) */
export const times = (count: number, fn: (i: number) => any) =>
  Array(...Array(count)).map((_, i) => fn(i));

/** camelCaseWords
 * remove separator and capitalise subsequent words
 */
export const camelCaseWords = (string = "", separator = " "): string =>
  string
    .split(separator)
    .map((word, i) => (i ? word[0].toUpperCase() + word.slice(1) : word))
    .join("");

export const ORG_ID_REGEXP = new RegExp(
  `^/organisation/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}`
);

export const prependOrgIdToPath = (orgId: string | null, path = "") => {
  if (!orgId) return path;

  const pathWithOrgId =
    path && ORG_ID_REGEXP.test(path) ? path : `/organisation/${orgId}${path}`;
  return pathWithOrgId.replace(/\/$/, "");
};

/**
 * asc
 * sorts array by field in ascending value order
 *
 * eg:
 * [{stat: 5}, {stat: 2}, {stat: 1}].sort(asc('stat'))
 */
export const asc =
  (field: string) => (a: { [key: string]: any }, b: { [key: string]: any }) =>
    a[field] < b[field] ? -1 : 1;

/**
 * desc
 * sorts array by field in descending value order
 *
 * eg:
 * [{stat: 1}, {stat: 5}, {stat: 3}].sort(desc('stat'))
 */
export const desc =
  (field: string) => (a: { [key: string]: any }, b: { [key: string]: any }) =>
    a[field] < b[field] ? 1 : -1;

export const listify = (listItems: string[]) => {
  const list = [...listItems];
  if (list.length > 2) {
    const last = list.pop();
    return `${list.join(", ")}, & ${last}`;
  }
  return list.join(" & ");
};
