import React from "react";

interface PermissionsContextInterface {
  permissions: string[] | null;
  orgId: string | null;
}

const PermissionsContext = React.createContext([
  {
    permissions: null,
    orgId: null,
  } as PermissionsContextInterface,
  () => {
    // Initialises empty function
  },
]);
export default PermissionsContext;
