import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogBody,
  DialogCloseButton,
  DialogHeading,
  DialogPrimaryActions,
  Text,
} from "@compono/ui";
import PlotGraph, { VIOLIN_PLOT_PRIMARY_COLOR } from "../PlotGraph/PlotGraph";
import { ViolinPlotExplainerDialogProps } from "../types";
import { exampleTextStyle } from "./styles";

const ViolinPlotExplainerDialog = ({
  isOpen,
  onClose,
  ...props
}: ViolinPlotExplainerDialogProps) => {
  return (
    <>
      <Dialog
        isOpen={isOpen}
        onDismiss={onClose}
        aria-describedby="dialog-description"
        aria-labelledby="dialog-title"
        data-testid="violinPlotExplainerDialog"
        {...props}
      >
        <DialogHeading id="dialog-title">What is a violin plot?</DialogHeading>
        <DialogBody id="dialog-description">
          <Text sx={{ mt: 4 }}>
            A violin plot shows you the average (mean) for the group you are
            looking at, but it also shows you the distribution (spread) so you
            can see more of the story your data is telling.
          </Text>
          <Box sx={{ mt: 6 }}>
            <PlotGraph average={2.07} showAxis={false} />
            <Text font="sm" sx={exampleTextStyle}>
              The point shows the average (mean) of the data.
            </Text>
          </Box>
          <Box sx={{ mt: 6 }}>
            <PlotGraph
              average={2.07}
              showAxis={false}
              showCircle={false}
              results={[1.5, 2.07, 2.07, 2.07, 2.07, 2.07, 2.07, 2.07, 2.5]}
            />
            <Text font="sm" sx={exampleTextStyle}>
              The curve shows you the distribution of the data. The taller the
              curve, the more people gave that response.
            </Text>
          </Box>
          <Box sx={{ my: 6 }}>
            <PlotGraph
              average={2.07}
              showViolinPlot={false}
              showCircle={true}
              results={[1.5, 2.07, 2.07, 2.07, 2.07, 2.07, 2.07, 2.07, 2.5]}
              circleFillColor={VIOLIN_PLOT_PRIMARY_COLOR}
              circleStrokeColor={VIOLIN_PLOT_PRIMARY_COLOR}
              circleFillOpacity={0.1}
              circleStrokeOpacity={0.1}
              circleStrokeSize={2}
            />
            <Text font="sm" sx={exampleTextStyle}>
              The scale helps you see where the responses landed, from 1 (fully
              left) to 5 (fully right).
            </Text>
          </Box>
          <Text font="h6">Example plots</Text>
          <Box sx={{ mt: 6 }}>
            <PlotGraph
              average={2.07}
              results={[1.5, 2.07, 2.07, 2.07, 2.07, 2.07, 2.07, 2.07, 2.5]}
            />
            <Text font="sm" sx={exampleTextStyle}>
              The average is around 2. A tight distribution curve means most
              people agreed with the average.
            </Text>
          </Box>
          <Box sx={{ mt: 6 }}>
            <PlotGraph average={3.5} results={[3, 3, 3, 4, 4, 4]} />
            <Text font="sm" sx={exampleTextStyle}>
              A broader distribution curve means the responses were more widely
              spread.
            </Text>
          </Box>
          <Box sx={{ mt: 6 }}>
            <PlotGraph average={3.5} results={[3, 3, 3, 3, 3, 4.5, 4.5]} />
            <Text font="sm" sx={exampleTextStyle}>
              A second smaller “bump” shows that a group of people disagreed
              with the larger group, and this group has pulled the average
              slightly to the right.
            </Text>
          </Box>
        </DialogBody>
        <DialogPrimaryActions>
          <Button
            look="primary"
            onClick={onClose}
            data-testid="violinPlotExplainerDialogButton"
          >
            OK
          </Button>
        </DialogPrimaryActions>
        <DialogCloseButton onClick={onClose} />
      </Dialog>
    </>
  );
};

export default ViolinPlotExplainerDialog;
