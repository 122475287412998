import React, { useContext, useRef, useState } from "react";
import {
  Stack,
  Text,
  Box,
  Button,
  IconBox,
  IconButton,
  Divider,
  Disclosure,
  DisclosureContent,
  Inline,
  DisclosureRenderProp,
  Card,
} from "@compono/ui";
import {
  ChevronCircleDownSolidIcon,
  ChevronCircleUpSolidIcon,
  CloseSolidIcon,
  DotSolidIcon,
  LightbulbSolidIcon,
} from "@compono/ui-icons";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import uuid from "uuid";

import { GetObservations } from "./queries";
import { CultureResult, InsightsGraphProps } from "./types";
import {
  bulletPointStyle,
  closeButtonStyle,
  graphContainerStyle,
  graphGrid,
  leftElement,
  observeContainerStyle,
  observeHeaderContainerStyle,
  rightElement,
} from "./styles";
import ObservationLearnMoreDialog from "../../../components/ObservationLearnMoreDialog/ObservationLearnMoreDialog";
import { getObservationKey } from "../../../shared/helpers";
import PlotGraph from "../../../components/PlotGraph";
import WithVisxTooltip from "../../../components/WithVisxTooltip";
import CultureInfoTipContainer from "../../../components/CultureInfoTipContainer";
import { CultureInfoTipContainerProps } from "../../../components/CultureInfoTipContainer/types";
import { VisxTooltipContext } from "../../../contexts";
import WithComponentResize from "../../../components/WithComponentResize";
import { tipIconContainerSx } from "../../../components/ObservationLearnMoreDialog/styles";
import { DisclosureObservations } from "./DisclosureObservations";
import {
  ShiftSentence,
  PercentSentence,
  getEndOfSentence,
  getPrimaryHeadingDiffInPercentage,
  getSecondaryHeadingDiffInPercentage,
} from "../Report/ReportGraph/utils";
import { primaryHeadingStyle } from "../../../components/CultureReportInfoTip/styles";
import { sentenceTextStyle } from "../../../components/CultureInfoTip/styles";
import { formatDateFromDate } from "../../../lib/formattingHelpers";
import {
  desiredCultureIconStyle,
  legendElementStyle,
} from "../Report/ReportGraph/styles";

// constants
export const headings: CultureInfoTipContainerProps["characteristic"][] = [
  { left: "Innovative", right: "Risk Averse", key: "riskTolerance" },
  { left: "Process driven", right: "Outcome driven", key: "driver" },
  { left: "Task centered", right: "People centered", key: "focus" },
  { left: "Collectivist", right: "Individualist", key: "teamWork" },
  { left: "Centralised control", right: "Delegated control", key: "control" },
  { left: "Hierarchical structure", right: "Flat structure", key: "structure" },
  { left: "Responsive", right: "Planned", key: "responsivePlanned" },
  { left: "Conformity", right: "Individuality", key: "conformity" },
  { left: "Accepting", right: "Questioning", key: "accepting" },
  { left: "Cooperation", right: "Competition", key: "cooperation" },
  {
    left: "Customer focused",
    right: "Internal effectiveness",
    key: "internalExternal",
  },
  { left: "Formal", right: "Informal", key: "formal" },
];
const border = "1px solid var(--colors-black-10)";

export const parseKeyToJson = (tmp: any, key: string, position: number) => {
  const observations = _.find(tmp, (obs) => {
    return obs.sk.includes(getObservationKey(key));
  })?.messages;

  if (+position >= 1 && +position <= 1.8) {
    return observations.left;
  } else if (+position >= 1.81 && +position <= 2.6) {
    return observations.slightLeft;
  } else if (+position >= 2.61 && +position <= 3.4) {
    return observations.middle;
  } else if (+position >= 3.41 && +position <= 4.2) {
    return observations.slightRight;
  } else if (+position >= 4.21 && +position <= 5) {
    return observations.right;
  } else {
    return null;
  }
};
export const InsightsGraph = ({
  showObservation = false,
  seriesA,
  seriesB,
  reference,
  selectedCampaignDueDate = new Date().toISOString(),
  selectedRefCampaignDueDate = new Date().toISOString(),
  dualView,
  graphSx,
  isReportGraph,
}: InsightsGraphProps) => {
  const scales = headings.map((element, index) => {
    const [showLearnMoreDialog, setShowLearnMoreDialog] = useState(false);
    const { data } = useQuery(GetObservations);
    const { observations } = data || {};

    const tooltipId = useRef(uuid()).current;
    const { isOpen, toggleTooltip } = useContext(VisxTooltipContext);

    const key = element.key as keyof CultureResult["results"];
    let observation;
    if (seriesA && observations)
      observation = parseKeyToJson(observations, key, seriesA.average[key]);
    let observationB;
    if (seriesB && observations)
      observationB = parseKeyToJson(observations, key, seriesB.average[key]);

    const CultureScaleComponent = WithComponentResize(
      ({ width }: { width?: number }) => (
        <>
          {seriesA && (
            <WithVisxTooltip
              isOpen={!isReportGraph && isOpen(tooltipId)}
              tooltip={
                <Box sx={{ p: 5 }}>
                  <Box>
                    <IconButton
                      label="Naked"
                      look="naked"
                      tone="inherit"
                      size="sm"
                      onClick={() => toggleTooltip(tooltipId)}
                      sx={closeButtonStyle}
                    >
                      <CloseSolidIcon />
                    </IconButton>
                  </Box>
                  <CultureInfoTipContainer
                    characteristic={element}
                    characteristicKey={key}
                    seriesA={seriesA}
                    seriesB={seriesB}
                    reference={reference}
                    showObservation={showObservation}
                    selectedCampaignDueDate={selectedCampaignDueDate}
                    selectedRefCampaignDueDate={selectedRefCampaignDueDate}
                    isReportGraph={isReportGraph}
                  />
                </Box>
              }
            >
              <Box
                onClick={() => toggleTooltip(tooltipId)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 15,
                }}
                data-seriesa={`${key}_score${seriesA.average[key]}`}
                data-seriesb={`${key}_score${seriesB?.average[key]}`}
                data-seriesref={`${key}_score${reference?.average[key]}`}
              >
                <PlotGraph
                  width={width}
                  average={seriesA.average[key]}
                  results={seriesA.results[key]}
                  compAverage={seriesB?.average[key]}
                  compResults={seriesB?.results[key]}
                  reference={reference?.average[key]}
                  showViolinPlot={seriesA.totalResults >= 5}
                  dualView={dualView}
                />
              </Box>
            </WithVisxTooltip>
          )}
        </>
      )
    );
    let difference;
    let heading;
    let primaryDiffARef;
    let secondaryDiffARef;
    if (seriesA && seriesB) {
      const primaryHeadingDiffInPercentage = getPrimaryHeadingDiffInPercentage(
        seriesA.average[key],
        seriesB.average[key]
      );
      const secondaryHeadingDiffInPercentage =
        getSecondaryHeadingDiffInPercentage(
          seriesA.average[key],
          seriesB.average[key]
        );
      if (reference) {
        primaryDiffARef = getPrimaryHeadingDiffInPercentage(
          seriesA.average[key],
          reference.average[key]
        );
        secondaryDiffARef = getSecondaryHeadingDiffInPercentage(
          seriesA.average[key],
          reference.average[key]
        );
      }

      difference =
        primaryHeadingDiffInPercentage > 0
          ? primaryHeadingDiffInPercentage
          : secondaryHeadingDiffInPercentage;
      heading =
        primaryHeadingDiffInPercentage > 0 ? element.right : element.left;
    }
    const reportCardStyle = isReportGraph
      ? { mb: 7, p: 0, borderColor: "black.20" }
      : { mb: 5 };

    let elevation = "1";
    return (
      <Disclosure>
        <DisclosureRenderProp>
          {({ isOpen, toggleIsOpen, id }) => (
            <Card
              key={key}
              border
              elevation={!isReportGraph || isOpen ? "2" : "0"}
              sx={reportCardStyle}
            >
              <Box
                sx={{
                  backgroundColor: isReportGraph ? "black.5" : "white",
                  m: 0,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
              >
                <Box sx={{ ...graphGrid, ...graphSx }}>
                  <Box sx={leftElement}>{element.left}</Box>
                  <Box sx={graphContainerStyle}>
                    <CultureScaleComponent />
                  </Box>
                  <Box sx={rightElement}>{element.right}</Box>
                </Box>
              </Box>
              {observation && showObservation && seriesA && seriesB && (
                <CultureInsights
                  key={key}
                  observation={observation}
                  element={element}
                  seriesA={seriesA}
                  observationB={observationB}
                  seriesB={seriesB}
                  difference={difference}
                  heading={heading}
                  reference={reference}
                  selectedRefCampaignDueDate={selectedRefCampaignDueDate}
                  primaryDiffARef={primaryDiffARef}
                  secondaryDiffARef={secondaryDiffARef}
                  toggleIsOpen={toggleIsOpen}
                  isOpen={isOpen}
                ></CultureInsights>
              )}
            </Card>
          )}
        </DisclosureRenderProp>
      </Disclosure>
    );
  });
  // return <Stack sx={{ ...scalesStyle, border }}>{scales}</Stack>;
  return <>{scales}</>;
};

export default InsightsGraph;

const CultureInsights = ({
  observation,
  element,
  seriesA,
  key,
  observationB,
  seriesB,
  difference,
  heading,
  reference,
  selectedRefCampaignDueDate,
  primaryDiffARef,
  secondaryDiffARef,
  toggleIsOpen,
  isOpen,
}: {
  observation: any;
  element: {
    left: string;
    right: string;
    key:
      | "riskTolerance"
      | "driver"
      | "focus"
      | "teamWork"
      | "control"
      | "structure"
      | "responsivePlanned"
      | "conformity"
      | "accepting"
      | "cooperation"
      | "internalExternal"
      | "formal";
  };
  seriesA: CultureResult;
  key: string;
  observationB: any;
  seriesB: CultureResult;
  difference: any;
  heading: any;
  reference: CultureResult | undefined;
  selectedRefCampaignDueDate: string;
  primaryDiffARef: any;
  secondaryDiffARef: any;
  toggleIsOpen: () => void;
  isOpen: boolean;
}): React.ReactNode => {
  return (
    <>
      <Divider orientation={"horizontal"} />
      <Box sx={{ m: 5 }}>
        <Stack flat gap="4">
          <Box sx={observeContainerStyle}>
            <Box sx={{ ...legendElementStyle, ml: -3 }}>
              <DotSolidIcon color="var(--colors-brandPurple-80)" />
              <Text>Current culture</Text>
            </Box>
            <Text font="title" sx={observeHeaderContainerStyle}>
              {observation?.heading}
            </Text>
            <PercentSentence
              primaryHeading={element.left}
              secondaryHeading={element.right}
              cultureResult={seriesA.average[key]}
            />
          </Box>
          <Box sx={observeContainerStyle}>
            <Box sx={{ ...legendElementStyle, ml: -3 }}>
              <Box sx={desiredCultureIconStyle} />
              <Text>Desired culture</Text>
            </Box>
            <Text font="title" sx={observeHeaderContainerStyle}>
              {observationB?.heading}
            </Text>

            <PercentSentence
              primaryHeading={element.left}
              secondaryHeading={element.right}
              cultureResult={seriesB.average[key]}
            />
          </Box>
        </Stack>
        <Divider orientation={"horizontal"} />
        <Text as="p" sx={{ m: 5 }}>
          {difference == 0 ? (
            <span>
              There is strong alignment between current and desired culture for
              this dimension.
            </span>
          ) : (
            <>
              <span>Employees desire a </span>
              <span style={primaryHeadingStyle}>
                {getEndOfSentence(difference, heading, "no-ref")}
              </span>
              <span> culture.</span>
            </>
          )}
          {reference && (
            <>
              <span style={sentenceTextStyle}>
                {" "}
                The reference culture at{" "}
                {formatDateFromDate(new Date(selectedRefCampaignDueDate))} is
                <PercentSentence
                  primaryHeading={element.left}
                  secondaryHeading={element.right}
                  cultureResult={reference.average[key]}
                />
              </span>

              <ShiftSentence
                primaryHeadingDiffInPercentage={primaryDiffARef}
                secondaryHeadingDiffInPercentage={secondaryDiffARef}
                primaryHeading={element.left}
                secondaryHeading={element.right}
                sentence=" In this period there has been a"
                type="ref"
              />
            </>
          )}
        </Text>{" "}
        <DisclosureContent>
          <Divider orientation={"horizontal"} />
          <Box sx={{ ml: 5, mr: 5, mb: 5 }}>
            <Stack flat gap="4">
              <DisclosureObservations
                observation={observation}
                observationB={observationB}
              />
            </Stack>
            <Box sx={{ mt: 7 }}>
              <Text font="title" sx={{ mb: 5 }}>
                {observation.tipsTitle}
              </Text>
              <Stack gap={4}>
                {observation?.tips?.map((point: string, index: number) => (
                  <Box sx={bulletPointStyle} key={index}>
                    <Box sx={tipIconContainerSx}>
                      <LightbulbSolidIcon />
                    </Box>
                    <Text sx={{ flex: 1, alignSelf: "center" }}>{point}</Text>
                  </Box>
                ))}
              </Stack>
            </Box>
          </Box>
        </DisclosureContent>
        <Inline alignX="center">
          <span onClick={toggleIsOpen}>
            {isOpen ? (
              <Button look="link">
                Read less{" "}
                <IconBox
                  sx={{ marginTop: "-1px" }}
                  icon={ChevronCircleUpSolidIcon}
                />
              </Button>
            ) : (
              <Button look="link">
                Read more{" "}
                <IconBox
                  sx={{ marginTop: "-1px" }}
                  icon={ChevronCircleDownSolidIcon}
                />
              </Button>
            )}
          </span>
        </Inline>
      </Box>
    </>
  );
};
