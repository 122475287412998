import { LightbulbSolidIcon } from "@compono/ui-icons";
import { tipIconContainerSx } from "../../../components/ObservationLearnMoreDialog/styles";
import React from "react";
import { bulletPointStyle } from "../Explorer/styles";
import { Stack, Text, Box } from "@compono/ui";

const Tips = ({ observation }) => {
  return (
    <Box sx={{ mt: 7 }}>
      {observation && (
        <>
          <Text font="title" sx={{ mb: 5 }}>
            {observation.tipsTitle}
          </Text>
          <Stack gap={4}>
            {observation?.tips?.map((point: string, index: number) => (
              <Box sx={bulletPointStyle} key={index}>
                <Box sx={tipIconContainerSx}>
                  <LightbulbSolidIcon />
                </Box>
                <Text sx={{ flex: 1, alignSelf: "center" }}>{point}</Text>
              </Box>
            ))}
          </Stack>
        </>
      )}
    </Box>
  );
};
export default Tips;
