import {
  Assessment,
  AssessmentResponseValues,
  SectionsEnum,
  StepsStatusEnum,
} from "../types";

export const useValidator = () => {
  const validateLength = (
    input: string | undefined,
    length: number
  ): boolean => {
    return input !== null && input !== undefined && input.length >= length;
  };
  const validateMaxLength = (
    input: string | undefined,
    max: number
  ): boolean => {
    return input !== null && input !== undefined && input.length <= max;
  };

  const hasCurrentAssessment = (assessments: Assessment[]): boolean => {
    return assessments.find(
      (assessment: Assessment) => assessment.type === "current"
    )
      ? true
      : false;
  };

  const hasDesiredAssessment = (assessments: Assessment[]): boolean => {
    return assessments.find(
      (assessment: Assessment) => assessment.type === "desired"
    )
      ? true
      : false;
  };

  const validateAssessments = (
    assessments: Assessment[],
    currentCultureError: string,
    desiredCultureError: string
  ) => {
    const oneAssessmentSelected =
      hasCurrentAssessment(assessments) || hasDesiredAssessment(assessments);
    if (oneAssessmentSelected) {
      let invalidAssessment = false;
      if (hasCurrentAssessment(assessments)) {
        // check for errors in currentCulture
        invalidAssessment = currentCultureError !== "";
      }
      if (!invalidAssessment) {
        if (hasDesiredAssessment(assessments)) {
          // check for errors in desiredCulture
          invalidAssessment = desiredCultureError !== "";
        }
      }
      return !invalidAssessment;
    }
    return false;
  };

  const validEditCampaignActiveSection = (section: string) =>
    Object.values(SectionsEnum).some((v) => v === section);

  const validCampaignActiveSection = (section: string) =>
    section === "start" ||
    section === "current" ||
    section === "desired" ||
    section === "finish";

  const validateValues = (values: AssessmentResponseValues) => {
    let changesMade = false;
    Object.values(values).map((value) => {
      if (value !== 3) {
        changesMade = true;
      }
    });
    return changesMade;
  };

  const validateAssessmentChanges = (
    steps: any,
    type: string,
    values: AssessmentResponseValues
  ) => {
    if (steps[`${type}`] === StepsStatusEnum.Done) {
      return true;
    }
    if (type === "current" && steps.current !== StepsStatusEnum.Done) {
      return validateValues(values);
    }
    if (steps.desired !== StepsStatusEnum.Done) {
      return validateValues(values);
    }
  };

  const assessmentValues = (assessmentResponse: any, type: string) =>
    assessmentResponse.find((response: any) => response.type === type)?.values;

  return {
    validateLength,
    validateMaxLength,
    hasCurrentAssessment,
    hasDesiredAssessment,
    validateAssessments,
    validCampaignActiveSection,
    validEditCampaignActiveSection,
    validateAssessmentChanges,
    assessmentValues,
  };
};
