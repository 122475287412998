import React from "react";

/*
  The type for React.createContext requires a default value to be passed into it.
  If our context will contain functions then these will likely need to be mocked.
  To get test coverage for these functions we would need to test our context without a provider.
  This would simply prove that our context will silently fail if used without a provider.
  *Most* of the time this will not be what we want.

  One option is to type the context as optionally `undefined` but then we will need to check
  for existence every time we use it. And again, *most* of the time this will not be what we want.

  The custom `createContext` provided here allows us to create a context with no default and
  handles the undefined check for us, throwing an error if there is no provider for the context
  wrapping our use of it.

  ref: https://www.carlrippon.com/react-context-with-typescript-p4/
*/

type Args = { hookName: string; providerName: string };

export function createContext<ContextType>({ hookName, providerName }: Args) {
  const context = React.createContext<ContextType | undefined>(undefined);
  function useContext() {
    const c = React.useContext(context);
    if (!c)
      throw new Error(
        `${hookName} must be used in a child of an ${providerName}`
      );
    return c;
  }
  return [useContext, context.Provider] as const;
}
