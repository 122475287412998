export const legendElementStyle = {
  display: "flex",
  fontSize: "1.8rem",
  alignItems: "center",
};

export const desiredCultureIconStyle = {
  width: "0.8rem",
  height: "0.8rem",
  borderRadius: "6px",
  backgroundColor: "white",
  borderWidth: "1px",
  borderColor: "black.70",
  borderStyle: "solid",
  m: 3,
};
