import React from "react";
import { Button, Box, Link, Text } from "@compono/ui";
import { ThemeUIStyleObject } from "@compono/ui-theme";
import { CloseCircleOutlineIcon } from "@compono/ui-icons";

import {
  iconStyle,
  containerStyle,
  titleStyle,
  descriptionStyle,
} from "./style";
import { usePath } from "../../hooks";

const ErrorMessage = ({
  icon = <CloseCircleOutlineIcon fontSize="105px" />,
  title,
  description,
  linkText,
  linkUrl,
  linkOnClick,
  sx,
}: {
  icon?: JSX.Element;
  title?: string;
  description?: string;
  linkText?: string;
  linkUrl?: string;
  linkOnClick?: () => void;
  sx?: ThemeUIStyleObject;
}) => {
  const { prefixedPath } = usePath();

  return (
    <Box sx={{ ...containerStyle, ...sx }}>
      {icon && <Text sx={iconStyle}>{icon}</Text>}
      {title && <Text sx={titleStyle}>{title}</Text>}
      {description && <Text sx={descriptionStyle}>{description}</Text>}
      {linkText && (
        <>
          {!linkOnClick && (
            <Link
              to={linkUrl ?? prefixedPath("/dashboard/current")}
              sx={{ mt: 5, fontWeight: "bold" }}
            >
              {linkText}
            </Link>
          )}
          {linkOnClick && (
            <Button look="link" onClick={linkOnClick}>
              {linkText}
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default ErrorMessage;
