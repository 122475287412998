import React, { useState, useEffect } from "react";
import {
  Inline,
  MultiLevelSelectLegacyDropdown,
  MultiLevelSelectLegacyBackButton,
  Box,
  SelectableOptionT,
  Text,
  MultiLevelSelectLegacy,
  MultiLevelSelectLegacyButton,
  MultiLevelSelectLegacyOptions,
} from "@compono/ui";
import { ContentFilterSolidIcon } from "@compono/ui-icons";
import {
  addFilterButton,
  selectDropdown,
  selectOptions,
  selectThisDivision,
} from "./styles";
import { FiltersSelectProps, Option } from "./types";
import { useInsights } from "../../../hooks/index";
import { setAutoFreeze } from "immer";
/**
 * @fix is to stop Cannot assign to read only property 'current' of object '#<Object>'
 * @ref https://compono-team.slack.com/archives/C2R51H90U/p1610950711034500 */
setAutoFreeze(false);

const FiltersSelect = (props: FiltersSelectProps) => {
  // props
  const { selectedCampaign, onAddFilter, buttonDisabled, showIcon } = props;

  // hooks
  const { filtersToOptsObjects } = useInsights();
  // constants
  let filters = filtersToOptsObjects(selectedCampaign.filters!);

  // state
  const [options, setOptions] = useState<Option[] | any>(filters.children);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [parentHistory, setParentHistory] = useState<string[]>([]);
  const [currentSection, setCurrentSection] = useState<
    SelectableOptionT | undefined | string
  >();
  const [demographic, setDemographic] = useState<string>();

  useEffect(() => {
    if (props?.selectedCampaign?.filters?.divisions) {
      filters = filtersToOptsObjects(selectedCampaign.filters);
      setOptions(filters.children);
    }
  }, [props]);

  // methods

  // Swap out list using currentLevel
  const updateSelectList = (option: Option, level: number) => {
    setCurrentLevel(currentLevel + level);
    setOptions(option.children);
  };

  // onchange to handle reloading options or selecting one.
  const handleSelectOnchange = (option: any) => {
    if (currentLevel == 0 && !option?.hasChildOptions) {
      return false;
    }
    if (currentLevel == 0) {
      setDemographic(option.label);
    }
    handleOnClickOption(option);
    if (!Array.isArray(option) && option?.hasChildOptions) {
      // load the new options.
      updateSelectList(option, 1);
      //save parent history
      setParentHistory([...parentHistory, option.value.toString()]);
    } else {
      // do something with option
    }
  };

  // this will determine what the previous options are based on stored history.
  const getParentOption = React.useCallback(() => {
    return parentHistory.reduce(
      (options, item: string, i) => {
        const found = options.find((option) => option.value === item);
        if (!found) return options;
        if (currentLevel === i + 1) return options;
        return found.children;
      },
      [...filters.children]
    );
  }, [parentHistory, filters]);

  const getCurrentSection = () => {
    return options.find((option: Option) => option.level)?.level ?? "";
  };

  // Link click handler.
  const handleBackLinkOnclick = () => {
    // remove last history item
    const options = getParentOption();
    const _history = [...parentHistory];
    _history.pop();
    setParentHistory(_history);
    setCurrentLevel(currentLevel === 0 ? 0 : currentLevel - 1);
    setOptions(options);
    setCurrentSection(getCurrentSection());
  };

  const handleResetFilters = () => {
    setCurrentLevel(0);
    setOptions(filters.children);
    setParentHistory([]);
    setCurrentSection(undefined);
  };

  const SelectThisDivision = (levelName: any) => {
    // const { setIsOpen } = useDropdownContext();
    return (
      <Text
        sx={selectThisDivision}
        onClick={() => {
          // setIsOpen(false);
          onAddFilter?.(undefined, currentSection, demographic);
          handleResetFilters();
        }}
      >
        {`Select ${levelName.levelName}`}
      </Text>
    );
  };

  const handleOnClickOption = (option: SelectableOptionT) => {
    if (!option?.hasChildOptions) {
      onAddFilter?.(option, currentSection, demographic);
      handleResetFilters();
      return;
    }
    setCurrentSection(option);
  };

  return (
    <Box sx={{ zIndex: 1 }}>
      <MultiLevelSelectLegacy
        placeholder="Choose an item"
        options={options}
        onChange={handleSelectOnchange}
      >
        <Inline>
          <MultiLevelSelectLegacyButton
            data-testid="addFilterButton"
            look={showIcon ? "secondary" : "tertiary"}
            iconLeft={showIcon && ContentFilterSolidIcon}
            sx={addFilterButton}
            size="small"
            disabled={buttonDisabled}
          >
            Add filter
          </MultiLevelSelectLegacyButton>
        </Inline>

        <MultiLevelSelectLegacyDropdown sx={selectDropdown}>
          {currentLevel > 0 && (
            <>
              <MultiLevelSelectLegacyBackButton
                data-testid={`campaignFilterBackButton${currentLevel}`}
                onClick={handleBackLinkOnclick}
                sx={{ color: "black.60" }}
              >
                <Text sx={{ font: "title", justifyContent: "center" }}>
                  {options[0]?.levelName}
                </Text>
              </MultiLevelSelectLegacyBackButton>
            </>
          )}
          {currentLevel > 1 && (
            <SelectThisDivision levelName={options[0].levelName} />
          )}
          <MultiLevelSelectLegacyOptions sx={selectOptions} />
        </MultiLevelSelectLegacyDropdown>
      </MultiLevelSelectLegacy>
    </Box>
  );
};

export default FiltersSelect;
