import React, { Dispatch, useEffect, useState } from "react";
import {
  Text,
  CoachTip,
  Button,
  Dialog,
  DialogBody,
  DialogCloseButton,
  DialogHeading,
  DialogPrimaryActions,
  Heading,
  LinkButton,
} from "@compono/ui";
import { infoCardStyle } from "./styles";
import { ChevronRightSolidIcon } from "@compono/ui-icons";

const SuperChargeConfirm = ({
  superChargeConfirmOpen,
  setSuperChargeConfirmOpen,
  refreshCampaign,
  campaignName,
}: {
  superChargeConfirmOpen: boolean;
  setSuperChargeConfirmOpen: Dispatch<SetStateAction<boolean>>;
  refreshCampaign: () => void;
  campaignName: string;
}) => {
  const paddedText = { pt: 5, pb: 5 };
  return (
    <>
      <Dialog
        aria-describedby="dialog-description"
        aria-labelledby="dialog-title"
        isOpen={superChargeConfirmOpen}
      >
        <DialogHeading id="dialog-title">Are you sure?</DialogHeading>
        <DialogBody id="dialog-description">
          <Heading level="5">What's changing</Heading>
          <Text sx={{ pt: 5 }}>
            We’re going to refresh the data for the closed campaign
          </Text>
          <Text sx={{ fontWeight: 500 }}>{campaignName}</Text>
          <Text sx={paddedText}>
            When a campaign closes, Engage creates a “snapshot” of your
            organisation as it exists at the end of the campaign.
          </Text>
          <Text sx={paddedText}>
            If you have added demographic information after the campaign closed,
            you can “refresh” the snapshot to include this.
          </Text>
          <Heading level="5">What does that mean</Heading>
          <Text sx={paddedText}>
            After you refresh the snapshot for this campaign, you will be able
            to add more filters in the Culture explorer. Filters let you dig
            deeper than the organisation average, which can sometimes be
            misleading.
          </Text>
          <ul>
            <li>Division</li>
            <li>Job level</li>
            <li>Location</li>
            <li>Tenure</li>
          </ul>

          <Text>In Compono Hire, you will be able to</Text>

          <ul>
            <li>
              Match candidates against a division’s culture (not just the whole
              organisation)
            </li>
          </ul>

          <Text sx={paddedText}>
            Candidate rankings for open jobs in Hire will not change unless you
            choose to use the updated culture data.
          </Text>
          <Heading level="5">Is there any reason not to do this?</Heading>
          <Text sx={paddedText}>
            You shouldn’t refresh the snapshot if there have been a lot of
            changes to the divisions, job levels etc, since your campaign
            closed; because then your results won’t reflect how your
            organisation was structured at the time.
          </Text>
        </DialogBody>
        <DialogPrimaryActions>
          <Button
            look="tertiary"
            onClick={() => setSuperChargeConfirmOpen(false)}
          >
            Not now
          </Button>
          <Button
            onClick={() => {
              refreshCampaign();
              setSuperChargeConfirmOpen(false);
            }}
          >
            Let's do it
          </Button>
        </DialogPrimaryActions>
        <DialogCloseButton onClick={() => setSuperChargeConfirmOpen(false)} />
      </Dialog>
      <CoachTip
        title="Finished loading your demographics?"
        look="warning"
        sx={infoCardStyle}
        data-testid="refreshInfo"
      >
        <Text>
          Refresh the report with the updated demographic information to unlock
          more powerful reporting and better job matching in Compono Hire.
        </Text>
        <Button
          iconRight={<ChevronRightSolidIcon />}
          look="link"
          onClick={() => setSuperChargeConfirmOpen(true)}
        >
          Refresh this report
        </Button>
      </CoachTip>
    </>
  );
};

export default SuperChargeConfirm;
