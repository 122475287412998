import { Checkbox, Inline, Box, Heading, Button } from "@compono/ui";
import React from "react";
import { TreeLines } from "../components/TreeLines";
import { GreyBox } from "../Hotspots/SpareParts";
import { Filter } from "./CultureBreakdown";
import { getName } from "./utils";

const FilterList = ({
  filters,
  heading,
  setFilters,
}: {
  filters: Filter[];
  heading: string;
  setFilters: ([]) => {};
}) => {
  const onCheckBoxChange = (filter: Filter, selected: boolean) => {
    const index = filters.findIndex((item) => {
      return item.name === filter.name && item.color === filter.color;
    });
    const updatedFilters = [...filters];
    updatedFilters[index] = { ...filters[index], selected };
    setFilters([...updatedFilters]);
  };

  const changeAll = (selected: boolean) => {
    const selectedFilters = filters.map((filter) => {
      return { ...filter, selected: selected };
    });
    setFilters(selectedFilters);
  };
  return (
    <Box sx={{ ml: 5 }}>
      <Box sx={{ ml: 3 }}>
        <Inline gap="5">
          <Heading level="6">{heading}</Heading>
          <Inline>
            <Button
              look="naked"
              onClick={() => {
                changeAll(true);
              }}
              sx={{ pr: 1 }}
              data-testid="selectAllButton"
            >
              Select all
            </Button>
            <Box>|</Box>
            <Button
              look="naked"
              onClick={() => {
                changeAll(false);
              }}
              sx={{ pl: 1 }}
              data-testid="deselectAllButton"
            >
              Deselect all
            </Button>
          </Inline>
        </Inline>
      </Box>
      {filters.map((filter, index) => {
        return (
          <Box sx={{ ml: filter.indented ? 7 : 0 }} key={filter.filterString}>
            <Inline>
              {filter.indented && (
                <TreeLines
                  height={36}
                  isLast={
                    index == filters.length - 1 || !filters[index + 1]?.indented
                  }
                ></TreeLines>
              )}

              <GreyBox
                sx={{
                  width: filter.indented ? "249px" : "280px",
                  pt: 2,
                  pr: 2,
                  pb: 2,
                }}
                shade={filter.indented ? "5" : "10"}
              >
                <Inline alignX="apart">
                  <Checkbox
                    label={getName(filter) + " (" + filter.count + ")"}
                    size="sm"
                    onChange={(event) => {
                      onCheckBoxChange(filter, event.target.checked);
                    }}
                    checked={filter.selected}
                    data-testid={"checkBox-" + filter.name}
                  ></Checkbox>
                  <Box
                    sx={{
                      display: "flex",
                      width: "15px",
                      height: "15px",
                      "background-color": filter.color,
                      border: "1px solid white",
                      "border-radius": "50%",
                      mr: filter.indented ? "1px" : "0",
                    }}
                  ></Box>
                </Inline>
              </GreyBox>
            </Inline>
          </Box>
        );
      })}
    </Box>
  );
};
export default FilterList;
