import { matchPath, match } from "react-router-dom";
import { MainNavMenuAsButton, MainNavMenuLink } from "@compono/ui";
import { ShowIf } from "~/auth-layer";
import { CandidatesOutlineIcon } from "../icons/CandidatesOutlineIcon";

export const EngageNav = () => {
  return (
    <MainNavMenuAsButton
      icon={CandidatesOutlineIcon}
      label="Engage"
      data-menu-button="true"
    >
      <MainNavMenuLink href="/dashboard" label="Surveys" />
      <ShowIf allowedTo="campaigns.view">
        <MainNavMenuLink
          href="/campaigns"
          label="Campaigns"
          data-is-active={(match: match, location: Location) =>
            !!match ||
            !!matchPath(location.pathname, {
              path: "/organisation/:orgId/editCampaign/:campaignId",
              exact: false,
            })
          }
        />
      </ShowIf>
      <ShowIf allowedTo="insights.view">
        <MainNavMenuLink href="/culture" label="Culture" />
      </ShowIf>
      <ShowIf allowedTo="personality.view">
        <MainNavMenuLink href="/workpersonality" label="Work personality" />
      </ShowIf>
      <ShowIf allowedTo="insights.share">
        <MainNavMenuLink href={`/sharing`} label="Sharing" id="sharing" />
      </ShowIf>
      <MainNavMenuLink href="/timezone" label="Timezone" />
    </MainNavMenuAsButton>
  );
};
