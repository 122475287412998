import React from "react";
import ViolinCard from "./ViolinCard";

const DimensionGraphs = ({
  dimensions,
  markers,
}: {
  dimensions: string[];
  markers: { filterName: string; values: number[]; color: string }[];
}) => {
  const getMarkers = (index) => {
    return markers.reduce((current: any, marker: any) => {
      return [
        ...current,
        { position: marker.values[index], color: marker.color, type: "line" },
      ];
    }, []);
  };
  return (
    <>
      {dimensions.map((dimension, index) => {
        return (
          <ViolinCard
            dimension={dimension}
            markers={getMarkers(index)}
          ></ViolinCard>
        );
      })}
    </>
  );
};

export default DimensionGraphs;
