import { prependOrgIdToPath } from "../lib/utils";
import { usePermissions } from "./usePermissions";

const useEnsureOrgIdPath = (path?: string) => {
  const { permissions } = usePermissions();

  if (path && !path.startsWith("/")) {
    return "";
  }
  return prependOrgIdToPath(permissions.orgId, path);
};

export default useEnsureOrgIdPath;
