import React from "react";
import { Box, Card, Stack, Text } from "@compono/ui";
import BarStackChart, {
  color1,
  color2,
  color3,
  color4,
  color5,
} from "../../../components/BarStackChart/BarStackChart";
import { buildHoverContent, buildItems } from "./utils";

const EngagementItem = ({
  title,
  description,
  distribution,
  average,
}: {
  title: string;
  description: string;
  distribution: [];
  average: number;
}) => {
  const { items, total } = distribution.reduce(
    (accumulator, currentValue) => {
      accumulator.items = buildItems(accumulator.items, currentValue);
      accumulator.total = accumulator.total + 1;
      return accumulator;
    },
    {
      items: [
        {
          value: 0,
          color: color1,
          label: "Strongly disagree",
        },
        {
          value: 0,
          color: color2,
          label: "Disagree",
        },
        {
          value: 0,
          color: color3,
          label: "Neutral",
        },
        {
          value: 0,
          color: color4,
          label: "Agree",
        },
        {
          value: 0,
          color: color5,
          label: "Strongly agree",
        },
      ],
      total: 0,
    }
  );
  const barItems = items.map((item) => {
    const content = buildHoverContent(item, total);
    return {
      ...item,
      content,
    };
  });

  return (
    <Card>
      <Text>
        <b>{title}</b>
      </Text>
      <Text>{description}</Text>
      <Box sx={{ mt: 5 }}>
        <BarStackChart items={barItems} markerValue={average}></BarStackChart>
      </Box>
    </Card>
  );
};
export default EngagementItem;
