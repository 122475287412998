import {
  ExplorerFilter,
  InsightsFilterInput,
  InsightsFilterType,
} from "../features/Culture/Explorer/types";
import { toCapitalCase } from "../shared/helpers";
import { Filters } from "../types";

import { map, isEmpty, camelCase } from "lodash";

export const useInsights = () => {
  // methods

  const getLabelName = (key: string) => {
    switch (key) {
      case InsightsFilterType.JOB_TITLE:
        return "Job title";
      case InsightsFilterType.JOB_LEVEL:
        return "Job level";
      case InsightsFilterType.TEAM:
        return "Team";
      case InsightsFilterType.OFFICE_LOCATION:
        return "Office location";
      case InsightsFilterType.TENURE:
        return "Tenure";
      case InsightsFilterType.GROUPS:
        return "Groups";
      default:
        "";
    }
  };

  const isDivisionLevel = (key = "") => {
    switch (key) {
      case InsightsFilterType.JOB_TITLE:
        return false;
      case InsightsFilterType.JOB_LEVEL:
        return false;
      case InsightsFilterType.TEAM:
        return false;
      case InsightsFilterType.OFFICE_LOCATION:
        return false;
      case InsightsFilterType.TENURE:
        return false;
      case InsightsFilterType.GROUPS:
        return false;
      default:
        return true;
    }
  };
  const getNestedObjects: any = (
    item: any = {},
    levelName: string,
    path = ""
  ) => {
    return Object.entries(item).map(([key, value]: any) => {
      // checks for value !== {}
      if (!isEmpty(value.children)) {
        // it needs to continue iterating since it has children
        return {
          levelName: levelName,
          path: path + "$" + value.label,
          label: value.label,
          value: key,
          hasChildOptions: true,
          children: getNestedObjects(
            value.children,
            value.label,
            path + "$" + value.label
          ),
        };
      }
      // this option has no children
      return {
        levelName: levelName,
        path: path + "$" + value.label,
        label: value.label,
        value: key,
        hasChildOptions: false,
        children: [],
      };
    });
  };

  // coverts campaign filters to be used as options in <Select />
  const filtersToOptsObjects = (filters: Filters) => {
    if (filters) {
      const filtersObj = Object.entries(filters).map(([key, value]: any) => {
        if (key === InsightsFilterType.DIVISIONS) {
          return {
            levelName: toCapitalCase(InsightsFilterType.DIVISIONS),
            label: toCapitalCase(InsightsFilterType.DIVISIONS),
            value: InsightsFilterType.DIVISIONS,
            hasChildOptions: true,
            children: getNestedObjects(
              value,
              toCapitalCase(InsightsFilterType.DIVISIONS)
            ),
          };
        }

        return {
          label: getLabelName(key),
          value: key,
          hasChildOptions: value ? value.length > 0 : false,
          children: value
            ? value.map((item: string) => {
                return {
                  levelName: getLabelName(key),
                  label: item,
                  value: camelCase(item),
                  hasChildOptions: false,
                  children: [],
                };
              })
            : null,
        };
      });
      return { children: filtersObj };
    }
    return { children: [] };
  };

  const translateFilters = (
    filters: ExplorerFilter[]
  ): InsightsFilterInput[] => {
    if (filters.length > 0) {
      return map(filters, (f) => {
        const options = map(f.filters, (filter) => {
          const option = filter.option ?? filter.label;
          const leadingDollarRemoved = option.startsWith("$")
            ? option.substring(1)
            : option;
          return f.filterType === "divisions"
            ? leadingDollarRemoved + "$"
            : leadingDollarRemoved;
        });
        return { filterType: f.filterType, options };
      });
    }
    return [];
  };

  return {
    filtersToOptsObjects,
    translateFilters,
  };
};
