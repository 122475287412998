import merge from "ts-deepmerge";

import {
  EmployerPermissions,
  EngagePermissions as NativeEngagePermissions,
  TrackyrPermissions,
} from "./queries";
import type {
  Access,
  ShortlysterPermissions as ShortlysterPermissions,
} from "./types";

export type EngagePermissions = {
  insights?: {
    view?: boolean;
    edit?: boolean;
  };
  campaigns?: {
    view?: boolean;
    edit?: boolean;
  };
  shortlyster?: {
    users?: boolean;
    account?: boolean;
    settings?: boolean;
  };
};

type Simplify<T> = {
  [K in keyof T]: Simplify<T[K]> extends infer O
    ? { [K in keyof O]: O[K] }
    : never;
};

export type Permissions = Simplify<
  ShortlysterPermissions &
    TrackyrPermissions &
    EmployerPermissions &
    EngagePermissions & {
      product?: {
        engage?: boolean;
        hire?: boolean;
        develop?: boolean;
        insights?: boolean;
      };
      compono?: {
        admin?: boolean;
      };
    }
>;

const transformEngagePermissions = (perms?: NativeEngagePermissions) => {
  if (!perms) return {};

  const engagePerms: EngagePermissions = {};

  perms.permissions.forEach((p) => {
    const [feat, perm] = p.split(".");
    if (!engagePerms[feat as keyof EngagePermissions]) {
      engagePerms[feat as keyof EngagePermissions] = {};
    }
    (engagePerms[feat as keyof EngagePermissions] as any)[perm] = true;
  });
  return engagePerms;
};

export const resolvePermissions = (
  roles: Access["allAppsRoles"],
  sl: ShortlysterPermissions,
  tr: TrackyrPermissions,
  em: EmployerPermissions,
  en?: NativeEngagePermissions
): Permissions => {
  return merge(
    sl,
    tr ?? {},
    em,
    transformEngagePermissions(en),
    {
      product: {
        // the following /should/ work, but doesn't if the user has only engage access
        // engage: Boolean(roles?.find((r) => r.appCode === "retayner")),
        engage: !!en,
        hire: Boolean(roles?.find((r) => r.appCode === "shortlyster")),
        develop: Boolean(roles?.find((r) => r.appCode === "develop")),
      },
    },
    { compono: { admin: !!sl.isComponoAdmin } }
  );
};
