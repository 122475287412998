import React from "react";
import { Group } from "@visx/group";
import { Glyph } from "@visx/glyph";
import { Polygon, Line } from "@visx/shape";
import { PRIMARY_COLOR } from "../Violinplot";

const LineMarker = ({ color = PRIMARY_COLOR, position, lineOnly = false }) => {
  return (
    <Glyph left={position} top={0}>
      {/* <ReferenceIcon size={24} transform="translate(0)" color={color} /> */}
      <Group left={12} transform="translate(0)">
        {!lineOnly && <Polygon sides={3} size={7} rotate={270} fill={color} />}
        <Line
          strokeDasharray={1.5}
          from={{ x: 0, y: 0 }}
          to={{ x: 0, y: 24 * 1.3 }}
          strokeWidth={2}
          stroke={color}
        />
      </Group>
    </Glyph>
  );
};

export default LineMarker;
