import { ThemeUIStyleObject } from "@compono/ui-theme";

export const circleStyles: { [key: string]: ThemeUIStyleObject } = {
  A: {
    mx: 1,
    fontSize: "sm",
    color: "white",
    textAlign: "center",
    backgroundColor: "var(--colors-brandPurple-80)",
  },
  B: {
    mx: 1,
    fontSize: "sm",
    color: "black.60",
    textAlign: "center",
    backgroundColor: "white",
    border: "2px solid var(--colors-brandDark-80)",
  },
  Ref: {
    color: "black.60",
    textAlign: "center",
    fontSize: 3,
    flex: "0 0 12px",
    display: "inline-block",
  },
};
export const circleStylesWrapper = {
  display: "inline-block",
  verticalAlign: "middle",
};
export const sentenceTextStyle = {
  verticalAlign: "baseline",
};

export const highlightedHeadingStyle = {
  color: "var(--colors-black-100)",
  fontWeight: 700,
};

export const normalHeadingStyle = {
  color: "var(--colors-black-60)",
};
